import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TermConditionEndpoint } from '../http/term-condition.operation';
import { GenericRequestConfig } from '../model/request.config';
import { ModalService } from '../services/dialog.service';
import { dataPersonTermsAndConditions } from '../interface/dataPersonTermsAndConditions';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})

export class TermConditionOperation {
  dataPersonTermsAndConditions:dataPersonTermsAndConditions;

  constructor(
    private termCondEndpoint: TermConditionEndpoint,
    private dialog: ModalService,
    private session: SessionService
  ) {}

  termConditionAccept$(idHomologation: number, formId) {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/homologation/set-agreements-to-true-or-not-endpoint`;
    req.params = new HttpParams().set('requiredHomologationId', idHomologation);

     // Crear un objeto FormData
     const formData: FormData = new FormData();

     // Añadir el archivo al FormData
     formData.append('document', this.dataPersonTermsAndConditions.Document, this.dataPersonTermsAndConditions.Document.name);
     formData.append("typePerson", this.dataPersonTermsAndConditions.typePerson);
     formData.append("nameLegalRepresentative", this.dataPersonTermsAndConditions.nameLegalRepresentative);
     formData.append("idLanguage", this.session.user.language?.id.toString() ?? '1');
     formData.append("formId", formId);
    
    
    return this.termCondEndpoint.termConditionAccept$(req, formData).pipe(
      map((res) => {
        if (res.info.status === 'OK') {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public getTermAndConditions$() {
    const version = this.session.user.language?.id ?? 1;
    const url = `${environment.urlBaseEndpointV2}/terms-condition-content/${version}`;

    return this.termCondEndpoint.getTermAndConditions$(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
