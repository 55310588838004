<div class="welcome max-h-[95%] h-[95%] my-[2%] py-5 px-5 overflow-auto">
  <div class="text-center">
    <h1 class="text-coface-primary font-bold text-xl mb-2">
      {{ "Hola" | translate }}, {{ nameSupplier }}
    </h1>
    <p class="mb-2 text-xs">
      {{
        "COFACE es una compañía francesa con presencia física en más de 60 países, tenemos 70 años de experiencia y somos líder mundial en el Seguro de Crédito. Contamos con más de 80 millones de empresas a nivel mundial en nuestra base de datos"
          | translate
      }}.
      <br>
      {{
        "Adicionalmente, somos especializados en certificación de empresas tanto nacionales como internacionales"
          | translate
      }}.
      <!-- <br /> -->
    </p>
    <!-- <p class="mb-2 text-xs"></p> -->
    <div class="flex justify-between items-center relative">
      <hr class="absolute line" />
      <button
        class="outline-none font-bold underline text-white text-sm bg-coface-primary rounded-3xl py-1.5 px-4 mx-auto z-10 w-[275px] transition-all duration-150 shadow-lg shadow-indigo-500/40 cursor-pointer hover:bg-blue-900 hover:w-[280px]"
        (click)="downloadFile()"
      >
        {{ "Carta de Presentación de" | translate }}:
        {{ information?.legalName }}
      </button>
    </div>
  </div>

  <div class="text-center">
    <h3 class="my-2 text-lg font-bold tracking-wide">
      {{ "Sobre el proceso de Calificación de Proveedores" | translate }}:
    </h3>
    <p class="mb-2 text-xs">
      {{
        "Nuestro proceso de calificación, nos permite conocer la experiencia de trabajo, el índice de fiabilidad y el comportamiento de los proveedores con otros clientes. Además, damos a conocer la solvencia financiera, constitución legal e infraestructura que tiene la empresa en la actualidad"
          | translate
      }}.
    </p>
  </div>

  <div>
    <div class="welcome__services flex justify-between mb-4">
      <div class="blockOne text-coface-primary">
        <p class="mb-2 font-bold text-sm">
          {{
            "¿Por qué elegir Coface para su proceso de Calificación?"
              | translate
          }}
        </p>
        <ul class="font13 text-coface-primary">
          <li class="ml-5 text-xs mb-1">
            {{ "Recibir una copia del informe elaborado por" | translate }}
            <span class="font-semibold">COFACE</span>.
          </li>
          <li class="ml-5 text-xs mb-1">
            {{
              "Recibir su Carta de Calificación o Certificado con el resultado de su calificación"
                | translate
            }}.
          </li>
          <li class="ml-5 text-xs mb-1">
            {{ "Ser recomendados a los Clientes de" | translate }}
            <span class="font-semibold">COFACE</span>,
            {{
              "en el momento en que estos requieran la información de las empresas mejor calificadas"
                | translate
            }}.
          </li>
        </ul>
      </div>
      <div class="blockTwo text-coface-primary">
        <p class="mb-2 font-bold text-sm">
          {{ "Precios del" | translate }}
          {{ "servicio" | translate }}
        </p>
        <div class="text-xs text-coface-primary">
          <p class="mb-3 text-xs">
            {{ "Usted puede escoger entre dos tarifas" | translate }}:
          </p>
          <div class="mb-3">
            <div class="flex items-center">
              <label
                class="font-bold flex items-center cursor-pointer"
                for="rate"
              >
                <input
                  (change)="changeFirstRate($event)"
                  type="radio"
                  class="mr-2 cursor-pointer"
                  value="especifica"
                  name="rate"
                  id="rate"
                />
                1. {{ "Tarifa Específica" | translate }}:</label
              >
              <div class="tooltip relative inline-block">
                <img
                  class="ml-1 w-4"
                  src="assets/img/circle-question.svg"
                  alt="Tooltip image"
                  rel="preload"
                  as="image"
                />
                <span class="tooltiptext text-xs">
                  Escribir algo aquí como siempre lo recuerdo
                </span>
              </div>
            </div>
            <p>
              {{ "Beneficios" | translate }}:
              {{
                "Aquí se califica únicamente para la empresa asignada"
                  | translate
              }}.
            </p>
            <p>
              {{ "Precio" | translate }}:
              <span *ngIf="getPriceByCountry"
                >{{ getPriceByCountry.currency | translate }}
                {{ getPriceByCountry?.priceSpecify | number : "1.2-2" }}
              </span>
            </p>
            <p>
              {{ "Forma de pago" | translate }}: {{ "contado" | translate }}
            </p>
          </div>

          <div class="mb-3">
            <div class="flex items-center">
              <label
                class="font-bold flex items-center cursor-pointer"
                for="rate2"
              >
                <input
                  (change)="changeFirstRate($event)"
                  type="radio"
                  class="mr-2"
                  value="general"
                  name="rate"
                  id="rate2"
                />
                2. {{ "Tarifa General" | translate }}:</label
              >
              <div class="tooltip relative inline-block">
                <img
                  class="ml-1 w-4"
                  src="assets/img/circle-question.svg"
                  alt="Tooltip image"
                  rel="preload"
                  as="image"
                />
                <span class="tooltiptext">
                  Escribir algo aquí como siempre lo recuerdo
                </span>
              </div>
            </div>
            <p>
              {{ "Beneficios" | translate }}:
              {{
                "Aquí se puede calificar para cualquier empresa del mercado"
                  | translate
              }}.
            </p>
            <p>
              Precio:
              <span *ngIf="getPriceByCountry"
                >{{ getPriceByCountry?.currencyGeneral | translate }}
                {{ getPriceByCountry?.price | number : "1.2-2" }}</span
              >
            </p>
            <p>
              {{ "Forma de pago" | translate }}: {{ "contado" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 border-y-line py-3 flex items-center">
      <span class="font-bold text-sm text-coface-primary">
        {{ "Seleccione su tipo de empresa" | translate }}:
      </span>
      <div class="flex items-center text-xs">
        <label
          (click)="selectedPersonNatural()"
          for="personNatural"
          class="cursor-pointer flex items-center"
        >
          <input
            (click)="selectedPersonNatural()"
            type="radio"
            class="ml-5 mr-2 cursor-pointer"
            value="general"
            name="personNatural"
            id="personNatural"
            [checked]="isSelectedPersonNatural"
          />
          Persona Natural</label
        >

        <label
          (click)="selectedPersonjuridic()"
          for="personJuridic"
          class="cursor-pointer flex items-center"
        >
          <input
            (click)="selectedPersonjuridic()"
            type="radio"
            class="ml-5 mr-2 cursor-pointer"
            value="general"
            name="personJuridic"
            id="personJuridic"
            [checked]="isSelectedPersonJuridic"
          />
          Persona Jurídica</label
        >
      </div>
    </div>

    <div class="welcome__policies">
      <p class="text-xs flex items-center gap-2">
        <input
          type="checkbox"
          [formControl]="termCond"
          style="pointer-events: none"
        />
        {{ "Estoy de acuerdo con los" | translate }}
        <a
          (click)="onOpenModalTermCondition()"
          class="text-coface-primary font-bold underline cursor-pointer"
          >{{ "Términos y Condiciones del Servicio" | translate }}</a
        >
      </p>
    </div>

    <div
      class="mt-4 text-center max-w-md mx-auto flex justify-center gap-2 flex-col"
    >
      <coface-button
        text="{{ 'Acepto continuar con el proceso' | translate }}"
        [disabled]="!(termCond.valid && !authRate)"
        size="small"
        (clicked)="onAcceptAndContinueProccess()"
      >
      </coface-button>

      <coface-button
        text="{{ 'No deseo participar en el proceso' | translate }}"
        [disabled]="!(termCond.valid && !authRate)"
        color="tertiary"
        size="small"
        (clicked)="onDeniedContinueProcess()"
      >
      </coface-button>
    </div>
  </div>
</div>
