import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { IResponseSupplier } from '../interface/provide.paginator';
import { IListSupplierByRole } from '../interface/supplier-by-role.interface';
import { environment } from 'projects/ui-coface/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WalletProviderEndpoint {
  constructor(private http: HttpClient) {}

  public getWalletProvider$(request: GenericRequestConfig) {
    return this.http.get<IResponseCoface<Paginator<IResponseSupplier>>>(
      request.url,
      request
    );
  }

  public getWalletProviderNew$(request: GenericRequestConfig) {
    return this.http.get<IResponseCoface<Paginator<IListSupplierByRole>>>(
      request.url,
      request
    );
  }

  public deleteWalletProvider$(homologationId: number) {
    const headers = new HttpHeaders({ 'no-intercept': 'true' });

    return this.http.patch<IResponseCoface<any>>(
      `${environment.urlBaseEndpointV2}/delete-supplier/${homologationId}`,
      {},
      { headers }
    );
  }
}
