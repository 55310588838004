<!-- <h1 *ngIf="cbo.failedInit">Cargando...</h1> -->
<div class="toolbar w-full"></div>
<!-- {{ session.user | json }} -->
<div class="flex">
  <div class="flex w-full">
    <div class="column-menu">
      <app-menu-left></app-menu-left>
    </div>
    <div class="column-text w-full">
      <!-- {{ session.user | json }} -->
      <div class="block header">
        <app-header></app-header>
      </div>
      <div
        class="container-module-coface block"
        *ngIf="economyActivityService?.dataEconomicActivity?.length"
      >
        <router-outlet style="width: 100%; height: 100%"></router-outlet>
      </div>
      
    </div>
  </div>
</div>
