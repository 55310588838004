import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cofaceValidateSpace]',
})
export class ValidateSpaceDirective {
  // private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
  private regex: RegExp = new RegExp(/^[0-9]*$/);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Space', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.code) !== -1) {
      event.preventDefault();
      return;
    }
  }
  /**Directiva que permite pegar valores numericos en el inputBox
   * Caso que se quiera pegar un valor numerico con texto, ignora los textos y solo copia
   * los valores numericos
   */
}
