<div class="extra-time">
  <h1
    class="absolute text-coface-primary font-bold text-center w-full left-0 p-3 pt-7 text-2xl"
  >
    {{ "Extensión de tiempo" | translate }}
  </h1>
  <div class="pt-16 px-3">
    <p class="font-bold text-center text-base py-8">
      {{
        "Ahora dispones de 3 días hábiles adicionales, caso contrario se calificará con la información disponible"
          | translate
      }}.
    </p>
    <div class="w-72 mx-auto">
      <button
        class="btn__passForm text-white bg-coface-primary block text-sm width_custom"
      >
        {{ "Aceptar extensión" | translate }}
      </button>
    </div>
  </div>
</div>
