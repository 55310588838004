<div class="select-language">
  <form [formGroup]="formSelectLanguage">
    <div>
      <h2 class="text-center text-2xl mb-5 clight font-bold w-11/12 mx-auto">
        {{
          "Selecciona el idioma a utilizar para su proceso de homologación"
            | translate
        }}
      </h2>
      <div class="mb-3">
        <coface-select
          class="mb-2"
          id-select="select-office"
          id-option="option-office"
          cofaceReactiveForm
          [items]="itemsLanguage"
          [valueTitle]="title | translate"
          formControlName="language"
        ></coface-select>
      </div>
      <!-- [value]="createUserPresenter.officeId.value" -->

      <div
        class="my-5 text-sm bg-coface-secondary-light text-white p-5 rounded-xl select-language__info"
      >
        <p class="text-white font-semibold">*{{ "Importante" | translate }}</p>
        <ul>
          <li class="mb-2">
            {{
              "De acuerdo al idioma seleccionado para el proceso de Calificación, se mostrarán los cuestionarios, y se emitirán los reportes por parte de Coface"
                | translate
            }}.
          </li>
          <li class="mb-2">
            {{
              "El idioma del proceso de calificación no podrá ser modificado posteriormente"
                | translate
            }}
          </li>
        </ul>
      </div>

      <button
        (click)="confirmProcessLanguage()"
        [disabled]="disableBtn"
        [ngClass]="{ gray: disableBtn }"
        class="btn__passWayPay w-full text-white bg-coface-primary block mx-auto text-sm my-5"
      >
        {{ "Confirmar idioma" | translate }}
      </button>
    </div>
  </form>
</div>
