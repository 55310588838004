import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdawnService } from 'projects/ui-coface/src/app/core/services/dropdawn.service';

import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { AuthHttp } from 'projects/ui-coface/src/app/auth/commons/http/auth.http';
@Component({
  selector: 'app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  providers: [AuthHttp],
})
export class MenuLeftComponent implements OnInit {
  constructor(
    private _route: Router,
    private _select: DropdawnService,
    public session: SessionService,
    private auth: AuthHttp
  ) {}
  infoMenuJson: any;
  numero: number;
  fullName: string;
  letter: string;
  rol: string;
  newIdRol: string;

  ngOnInit(): void {
    const { modules } = this._select.menuModules;

    this.infoMenuJson = modules.filter((element) =>
      element.rol?.includes(this.session.user.role!.keyword)
    );

    this.rol = this.session.user.role?.name!;
    this.filterName();
    // console.log(this._select.menuModules);
  }



  filterName() {
    this.fullName = this.session.user.company?.legalName!
      ? this.session.user.company?.legalName!
      : this.session.user.fullName!;
    this.letter = this.session.user.initialNames!;
  }

  onClickModule(id: number, hasSubModule: boolean) {
    const subModules = document.querySelectorAll<HTMLDivElement>(
      '[id^=menuSubmodules]'
    );
    const subModule = document.querySelector<HTMLDivElement>(
      `[id^=menuSubmodules-${id}]`
    );
    if (hasSubModule) {
      this.expandSubModule(subModules, subModule, id);
    } else {
      // EMITIR EVENTO ROUTER NAVIGATE
      let dataClicked = this.infoMenuJson.filter((data) => data.id == id);
      this._route.navigate([`./coface${dataClicked[0].path}`]);
    }
  }

  onClickSubModule(id: number) {
    let arrNumber = String(id)
      .split('')
      .map((num) => {
        return Number(num);
      });

    let dataClicked = this.infoMenuJson
      .filter((data) => data.id == arrNumber[0])[0]
      .submodules.filter((data) => data.id == id);
    // let dataClicked = this.infoMenuJson.filter((data) => data.id == id);
    // .filter((data) => data.id == id);
    // console.log(dataClicked);
    this._route.navigate([`./coface/${dataClicked[0].path}`]);
  }

  private onSomeSubModuleIsActive(
    subModules: NodeListOf<HTMLDivElement>
  ): boolean {
    const list = new Array<HTMLDivElement>();
    subModules.forEach((el) => list.push(el));
    const containShowClass = list.some((elm) => elm.classList.contains('show'));

    return containShowClass ? true : false;
  }

  private removeAllShowClass(
    subModules: NodeListOf<HTMLDivElement>,
    subModule: HTMLDivElement | null
  ) {
    subModules.forEach((elm) => {
      elm.classList.remove('show');
      elm.classList.add('hide');
    });
    if (subModule && subModule.classList.contains('hide')) {
      subModule.classList.remove('hide');
      subModule.classList.add('show');
      //   img.classList.add('rotated');
    } else if (subModule && subModule.classList.contains('show')) {
      subModule.classList.remove('show');
      subModule.classList.add('hide');
      //   img.classList.remove('rotated');
    }
  }

  private expandSubModule(
    subModules: NodeListOf<HTMLDivElement>,
    subModule: HTMLDivElement | null,
    id
  ) {
    let img = document.getElementById(`imgarrow-${id}`) as HTMLImageElement;
    if (this.onSomeSubModuleIsActive(subModules)) {
      this.removeAllShowClass(subModules, subModule);
    }
    if (subModule && subModule.classList.contains('hide')) {
      subModule.classList.remove('hide');
      subModule.classList.add('show');
      img?.classList.add('rotated');
    } else if (subModule && subModule.classList.contains('show')) {
      subModule.classList.remove('show');
      subModule.classList.add('hide');
      img?.classList.remove('rotated');
    }
  }

  signOut() {
    this.auth.authLogout$().subscribe({
      next: (data) => {
        this.session.destroySession();
        this._route.navigate(['./acceso/login']);
      },
      error: (error) => {
        this._route.navigate(['./acceso/login']);
      },
      complete: () => {
        this.session.destroySession();
        this._route.navigate(['./acceso/login']);
      },
    });
  }
}

enum GET_MODULES {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
  STAFF = 'STAFF',
}
