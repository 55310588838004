import { IResultFile } from './../../../../commons/interface/upload.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ITableHeader } from 'projects/ui-coface/src/app/commons/interface/paginator-table.interface';
import _moment from 'moment';
@Component({
  selector: 'coface-table-bulk-upload',
  templateUrl: './table-bulk-upload.component.html',
  styleUrls: ['./table-bulk-upload.component.scss'],
  providers: [DialogService],
})
export class TableBulkUploadComponent implements OnInit {
  message: IResultFile;

  public dataHeaderCmp: ITableHeader[] = [
    { id: 'fullName', name: 'Nombre' },
    { id: 'numberOfRecords', name: 'No de Registros' },
    { id: 'dateUpload', name: 'Fecha de Carga' },
    { id: 'valid', name: 'Válidos' },
    { id: 'observed', name: 'No válidos' },
  ];

  public dataBody: IDataBodySuccessUpload[] = [
    {
      fullName: "",
      numberOfRecords: 0,
      dateUpload: "",
      valid: 0,
      observed: 0,
    }
  ];

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.message = this.config.data;
    // console.log(this.message);
    this.dataBody[0].fullName = this.message.fileName;
    this.dataBody[0].numberOfRecords = this.message.totalRecords;
    this.dataBody[0].dateUpload =  _moment(this.message.issuedAt).format('DD/MM/YYYY');
    this.dataBody[0].valid = this.message.validRecords;
    this.dataBody[0].observed = this.message.invalidRecords;
  }

  closePOPUP() {
    this.ref.close();
  }
}

interface IDataBodySuccessUpload {
  fullName: string;
  valid: number;
  observed: number;
  numberOfRecords: number;
  dateUpload: string;
}
