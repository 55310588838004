import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPipeById',
})
export class CurrencyPipe implements PipeTransform {
  // new Parameter('1', 'Sol', 'PEN'),
  // new Parameter('2', 'Real Brasileño', 'BRL'),
  // new Parameter('3', 'Peso colombiano', 'COP'),
  // new Parameter('4', 'Peso mexicano', 'MXN'),
  // new Parameter('5', 'Dólar Estadounidense', 'USD'),
  // new Parameter('6', 'Peso chileno', 'CLP'),
  // new Parameter('7', 'Peso Argentino', 'ARS'),
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Sol';
      case '2':
        return 'Real Brasileño';
      case '3':
        return 'Peso colombiano';
      case '4':
        return 'Peso mexicano';
      case '5':
        return 'Dólar Estadounidense';
      case '6':
        return 'Peso chileno';
      case '7':
        return 'Peso Argentino';
      default:
        return 'Ninguno';
    }
  }
}
