import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { ListPendingRequest } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/model/pending-request.model';
import { switchMap } from 'rxjs';
import { SelectBasicformsComponent } from '../select-basicforms/select-basicforms.component';

@Component({
  selector: 'coface-way-to-pay',
  templateUrl: './way-to-pay.component.html',
  styleUrls: ['./way-to-pay.component.scss'],
})
export class WayToPayComponent implements OnInit {
  nameAccountManager: string;
  emailAccountManager: string;
  phoneAccountManager: string;
  informationPayed; // LEGACY
  countryName: string; // SOLO PARA COLOMBIA SE USA UN UX DIFERENTE
  infoSelectBasicForm: number;
  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private session: SessionService,
    private homologationOperation: HomologacionOperation,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    let info = this.dialogConfig.data as ListPendingRequest;
    // console.log(info);
    this.homologationOperation
      .getNameManagerOfMyCustomer(info.HomologationId)
      .subscribe((data) => {
        this.informationPayed = this.homologationOperation.buildWayToPay(
          info.Country.name
        );
        this.homologationOperation.countrySupplier = info.Country.name;
        this.countryName = info.Country?.name;
        this.homologationOperation.informationPayed = this.informationPayed;
        this.nameAccountManager =
          data.data.firstname + ' ' + data.data.lastname;
        this.emailAccountManager = data.data.email;
        this.phoneAccountManager = data.data.phoneNumber;
      });
    this.getInfoDetail();
  }
  public getInfoDetail() {
    this.homologationOperation
      .getInfoByUserDetail(this.session.user.id!)
      .subscribe((data) => {
        this.infoSelectBasicForm = data.data.basicFormId;
      });
  }

  public onContinueProccess() {
    let info = this.dialogConfig.data as ListPendingRequest;
    if (this.infoSelectBasicForm !== null) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close();
      // this.openSelectBasicForm(info);
    }
    this.router
      .navigate(['/coface/homologacion/registro-pago'], {
        queryParams: {
          homologationId: info.HomologationId,
        },
      })
      .then((_) => this.dialogRef.close());
  }

  private openSelectBasicForm(item: ListPendingRequest) {
    const ref = this.dialogService.open(SelectBasicformsComponent, {
      showHeader: false,
      width: '35%',
      dismissableMask: false,
      transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      contentStyle: { 'border-radius': '15px' },
      baseZIndex: 10000,
      data: item,
    });
  }
}
