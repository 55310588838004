<section class="flex flex-col gap-4 items-center mt-14 mb-3 justify-between">
  <h2 class="text-coface-primary text-2xl text-center font-bold">
    Sesión iniciada en otro dispositivo
  </h2>
  <div class="px-10 flex flex-col gap-4 items-center">
    <p class="text-gray-500 text-center text-lg">
      Su cuenta ha iniciado sesión en otro dispositivo. Por seguridad, se ha
      cerrado la sesión en este dispositivo
    </p>
    <coface-button
      text="Aceptar"
      class="w-3/4 font-bold"
      (clicked)="closeModal()"
      color="secondary"
    ></coface-button>
  </div>
</section>
