import { Component, OnInit } from '@angular/core';
import { DropdawnService } from '../../../core/services/dropdawn.service';
import { InactivityService } from '../../../core/services/inactivity.service';
import { SessionService } from '../../../commons/services/session.service';
import { EconomyActivityService } from '../../../core/services/economy-activity.service';
import { TermConditionOperation } from '../../../commons/operations/term-condition.operation';
import { StoreName } from '../../../commons/enums/store-name.enum';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [InactivityService],
})
export class MainLayoutComponent implements OnInit {
  constructor(
    public economyActivityService: EconomyActivityService,
    private inactivityService: InactivityService,
    public session: SessionService,
    private termCondOperation: TermConditionOperation
  ) {}

  async ngOnInit(): Promise<void> {
    this.economyActivityService.solveEcononiActivity(this.session.Token);
    this.getTermsAndConditions();
  }

  private getTermsAndConditions() {
    this.termCondOperation.getTermAndConditions$().subscribe((resp) => {
      const stringData = JSON.stringify(resp);
      localStorage.setItem(StoreName.TERMS_AND_CONDITIONS, stringData);

      // console.log(resp);
    });
  }
}
