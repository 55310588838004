<div class="important-notes">
  <div>
    <div
      class="important-notes__title flex items-center justify-between relative pb-4"
    >
      <div class="flex items-center">
        <img
          src="assets/img/upload/poligono-green.svg"
          alt="Poligono icono"
          rel="preload"
          as="image"
          class="w-3 mr-2"
        />
        <p class="font-bold arrow-right">Mantenimiento de usuarios</p>
      </div>
      <p></p>
    </div>

    <div
      class="border-t py-4 border-gray-200 flex items-center justify-center text-center py-12"
    >
      <!-- <coface-table
        class="w-full"
        [dataHead]="dataHeaderCmp"
        [dataBody]="dataBodyCmp"
        [actions]="actions"
        [loadingContent]="false"
        [multiCheck]="true"
      ></coface-table> -->
    </div>

    <div class="flex items-center justify-between">
      <!-- <coface-paginator></coface-paginator> -->

      <div class="w-2/6 mt-10">
        <div
          class="input bg-coface text-center relative cursor-pointer"
        >
          <p class="text-white">Crear nuevo usuario</p>
          <button
            type="button"
            class="absolute bg-white rounded-3xl btn__create"
          >
            <img
              src="assets/img/upload/arrow-right.svg"
              alt="Limpiar formulario"
              rel="preload"
              as="image"
              class="w-4"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
