import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import { Country, IListUser } from '../interface/list-user.interface';
import { EUnlockModule } from 'projects/ui-coface/src/app/commons/enums/unlock-module.enum';

export class ListUserModel extends CofaceBuilder {
  private fullName: string;
  public get FullName(): string {
    return this.fullName;
  }
  public set FullName(value: string) {
    this.fullName = value;
  }
  private nationalId: string;
  public get NationalId(): string {
    return this.nationalId;
  }
  public set NationalId(value: string) {
    this.nationalId = value;
  }
  private phoneNumber: string;
  public get PhoneNumber(): string {
    return this.phoneNumber;
  }
  public set PhoneNumber(value: string) {
    this.phoneNumber = value;
  }

  private phone: string;
  public get Phone(): string {
    return this.phone;
  }
  public set Phone(value: string) {
    this.phone = value;
  }
  private email: string;
  public get Email(): string {
    return this.email;
  }
  public set Email(value: string) {
    this.email = value;
  }
  private profile: string;
  public get Profile(): string {
    return this.profile;
  }
  public set Profile(value: string) {
    this.profile = value;
  }
  private office: string;
  public get Office(): string {
    return this.office;
  }
  public set Office(value: string) {
    this.office = value;
  }

  private id: string;
  public get Id(): string {
    return this.id;
  }
  public set Id(value: string) {
    this.id = value;
  }

  static override backBuild(data: IListUser) {
    const formateando = new ListUserModel();
    // formateando.FullName =
    // data.firstname + ' ' + data.lastname ??
    // '-' + ' ' + data.surname2 ??
    // '-'; //change
    // console.log(data);

    if (data) {
      // console.log(data);

      formateando.FullName = data.firstname + ' ' + data.lastname ?? '-'; //change
      // formateando.NationalId = data.
      // ? data.companies.map((res) => res.nationalId).join('|')
      // : '-';
      formateando.Office = data.office?.name ?? '-';
      formateando.PhoneNumber = data.phone ?? '-';
      (formateando.Email = data.email ?? '-'),
        (formateando.profile = data.role.keyword);
      // (formateando.profile = data.role.map((res) => res.name).join(' | '));

      formateando['blockedState'] =
        data.status === 'ACTIVE' ? 'Activo' : 'Bloqueado';
      formateando['blockedStatebtn'] = 'BLOCKED_STATE';

      formateando['unlockModule'] = EUnlockModule.ADMINISTRATOR_COWORKERS;
      formateando['nameToUnlock'] = formateando.FullName;
      formateando.Id = data.id.toString();
    }
    return formateando;
  }
}
