import { Injectable } from '@angular/core';
import { RoleType } from '../enums/role.enum';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard {
  constructor(private session: SessionService) {}

  canActivate() {
    if (
      this.session.user.role?.keyword === RoleType.SUPERUSER ||
      this.session.user.role?.keyword === RoleType.ADMINISTRATOR
    ) {
      return true;
    } else {
      return false;
    }
  }
}
