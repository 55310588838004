<div class="wrap-confirm py-7">
  <h2 class="text-gray-500 text-center">
    Una vez que envíe la información, no podrá agregarlos o modificarlos
    posteriormente. Por favor, asegúrese de incluir todos los archivos
    necesarios antes de finalizar el envío
  </h2>
  <div class="flex mt-5 gap-4 items-center flex-col px-14">
    <coface-button
      text="CANCELAR"
      color="tertiary"
      (clicked)="onCancel()"
      [loading]="false"
    ></coface-button>
    <coface-button
      text="ACEPTAR"
      color="primary"
      (clicked)="onAccept()"
      [loading]="false"
    ></coface-button>
  </div>
</div>
