import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import {
  Observable,
  Subject,
  filter,
  fromEvent,
  merge,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { TimeoutComponent } from '../../shared/components/dialogs/timeout/timeout.component';
import { SessionService } from '../../commons/services/session.service';
import { Router } from '@angular/router';

@Injectable()
export class InactivityService implements OnDestroy {
  private readonly timeoutDuration = 15 * 60 * 1000; //15 MINUTES
  public isOpenModal = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialog: DialogService,
    private ngZone: NgZone,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.setupInactivityListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupInactivityListener(): void {
    // console.log('Inactivity service started'); // Para depuración

    const activityEvents$: Observable<Event> = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'click')
    );

    activityEvents$
      .pipe(
        tap(() => this.resetTimer()),
        switchMap(() => timer(this.timeoutDuration)),
        filter(() => !this.isOpenModal),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.showTimeoutModal());
  }

  private resetTimer(): void {
    // console.log('Timer reset'); // Para depuración
  }

  private showTimeoutModal(): void {
    // console.log('showmodal timeout'); // Para depuración

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(TimeoutComponent, {});
      this.isOpenModal = true;
      dialogRef.onClose.subscribe((result) => {
        this.isOpenModal = false;
        if (result === 'LOGOUT') {
          this.resetTimer();
          this.signOut();
        }
      });
    });
  }
  private signOut() {
    this.sessionService.destroySession();
    this.router.navigate(['./acceso/login']);
  }
}
