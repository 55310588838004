<div class="select-form">
  <div>
    <h1 class="text-center text-2xl mb-3 clight font-bold">
      {{ "¡Ya casi terminamos!" | translate }}
    </h1>
    <div class="flex justify-between items-center relative my-4 text-sm">
      <p>
        {{
          "Solo unas preguntas más para terminar de configurar tu solicitud"
            | translate
        }}
      </p>
      <!-- <div
        class="font-bold text-white bglight rounded-3xl py-1.5 px-4 z-10 text-sm"
      >
        Instrucciones para el proveedor
      </div> -->
      <!-- <hr class="absolute line" /> -->
    </div>

    <div class="select-form__answers border-t border-gray-200 border-b pt-4">
      <div class="text-sm mb-4">
        <p class="mb-2">
          {{ "¿Su empresa es persona natural o persona jurídica?" | translate }}
        </p>
        <div class="flex justify-between">
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              (change)="changeFirstRadio($event)"
              type="radio"
              name="person"
              value="natural"
              id=""
            />
            {{'Persona Natural' | translate}}</span
          >
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              (change)="changeFirstRadio($event)"
              type="radio"
              name="person"
              value="juridica"
              id=""
            />
            {{'Persona Jurídica' | translate}}</span
          >
        </div>
      </div>

      <div *ngIf="management" class="text-sm mb-4">
        <p class="mb-2">
          {{ "¿Su empresa está obligada a llevar contabilidad?" | translate }}
        </p>
        <div class="flex justify-between">
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              name="management"
              (change)="changeTwoRadio($event)"
              value="yes"
              type="radio"
              id=""
            />
            {{'Si' | translate}}</span
          >
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              name="management"
              (change)="changeTwoRadio($event)"
              type="radio"
              value="no"
              id=""
            />
            {{'No' | translate}}</span
          >
        </div>
      </div>

      <div *ngIf="services" class="text-sm mb-4">
        <p class="mb-2">
          {{ "¿Su empresa ofrecerá productos o servicios a" | translate }}
          {{ info?.nameClient }}?
        </p>
        <div class="flex justify-between">
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              (change)="changeThreeRadio($event)"
              name="services"
              value="productos"
              type="radio"
              id=""
            />
            {{'Productos' | translate}}</span
          >
          <span class="w-1/2 flex"
            ><input
              class="mr-1"
              (change)="changeThreeRadio($event)"
              name="services"
              value="servicios"
              type="radio"
              id=""
            />
            {{'Servicios' | translate}}</span
          >
        </div>
      </div>
    </div>

    <!-- <div class="flex justify-between items-center relative my-4">
      <hr class="absolute line" />
      <div
        class="font-bold text-white bglight rounded-3xl py-1.5 px-4 z-10 text-sm"
      >
        Medio de contacto de con Coface
      </div>
    </div> -->

    <button
      (click)="saveToProcess()"
      class="btn__passWayPay w-full text-white bg-coface-primary block mx-auto text-sm my-5"
      [disabled]="disabledBtn"
      [ngClass]="{ gray: disabledBtn }"
    >
      {{ "Continuar con el proceso" | translate }}
    </button>
    <p class="text-sm text-center">
      {{
        "En caso tengas alguna duda o consulta puedes contactar con tu Account Manager"
          | translate
      }}.
    </p>
  </div>
</div>
