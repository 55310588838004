import { CofaceCommonsModule } from './../commons/coface-commons.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HeaderComponent } from './layouts/main-layout/header/header.component';
import { MenuLeftComponent } from './layouts/main-layout/menu-left/menu-left.component';
import { RouterModule } from '@angular/router';
import { SvgCircleComponent } from './components/svg-circle/svg-circle.component';
import { TableComponent } from './components/table/table.component';
import { SelectComponent } from './components/select/select.component';
import { AddCustomerComponent } from './components/dialogs/add-customer/add-customer.component';
import { RangeComponent } from './components/range/range.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ErrorComponent } from './components/dialogs/error/error.component';
import { CreateUserComponent } from './components/dialogs/create-user/create-user.component';
import { EditProviderComponent } from './components/dialogs/edit-provider/edit-provider.component';
import { EditCustomerComponent } from './components/dialogs/edit-customer/edit-customer.component';
import { PrimeNgModule } from '../../primeng.module';
import { WelcomeProviderComponent } from './components/dialogs/welcome-provider/welcome-provider.component';
import { TermsAndConditionsComponent } from './components/dialogs/terms-and-conditions/terms-and-conditions.component';
import { WayToPayComponent } from './components/dialogs/way-to-pay/way-to-pay.component';
import { EndProcessComponent } from './components/dialogs/end-process/end-process.component';
import { SuccessfulComponent } from './components/dialogs/successful/successful.component';
import { CircleProgressbarComponent } from './components/circle-progressbar/circle-progressbar.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { ImportantNotesComponent } from './components/important-notes/important-notes.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { CollapseDetailTableComponent } from './components/collapse-detail-table/collapse-detail-table.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { SelectV2Component } from './components/select-v2/select-v2.component';
import { RequalificationRequestComponent } from './components/dialogs/requalification-request/requalification-request.component';
import { TableBulkUploadComponent } from './components/dialogs/table-bulk-upload/table-bulk-upload.component';
import { SelectBasicformsComponent } from './components/dialogs/select-basicforms/select-basicforms.component';
import { TermsAndConditionsEcuadorComponent } from './components/dialogs/terms-and-conditions-by-country/terms-and-conditions-ecuador/terms-and-conditions-ecuador.component';
import { TermsAndConditionsColombiaComponent } from './components/dialogs/terms-and-conditions-by-country/terms-and-conditions-colombia/terms-and-conditions-colombia.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectLanguageComponent } from './components/dialogs/select-language/select-language.component';
import { SelectQuizLetterComponent } from './components/dialogs/select-quiz-letter/select-quiz-letter.component';
import { TermsAndConditionsBrazilComponent } from './components/dialogs/terms-and-conditions-by-country/terms-and-conditions-brazil/terms-and-conditions-brazil.component';
import { ExtraTimeComponent } from './components/dialogs/extra-time/extra-time.component';
import { EditInformationSupplierComponent } from './components/dialogs/edit-information-supplier/edit-information-supplier.component';
import { CancelRatingComponent } from './components/dialogs/cancel-rating/cancel-rating.component';
import { BlockStateComponent } from './components/dialogs/block-state/block-state/block-state.component';
import { ConfirmDocumentDialogComponent } from './components/dialogs/confirm-document-dialog/confirm-document-dialog.component';
import { AuthCommonModule } from '../auth/commons/auth.common.module';
import { ButtonComponent } from './components/button/button.component';
import { TermsAndConditionsPersonNaturalComponent } from './components/dialogs/terms-and-conditions-by-country/terms-and-conditions-person-natural/terms-and-conditions-person-natural.component';
import { TermsAndConditionsPersonLegalComponent } from './components/dialogs/terms-and-conditions-by-country/terms-and-conditions-person-legal/terms-and-conditions-person-legal.component';
import { ModalTermsAndConditionsDocumentUploadComponent } from './components/modal-terms-and-conditions-document-upload/modal-terms-and-conditions-document-upload.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { SessionExpiredComponent } from './components/dialogs/session-expired/session-expired.component';
import { CleanButtonComponent } from './components/clean-button/clean-button.component';
import { TitleOnlineFormComponent } from './components/title-online-form/title-online-form.component';
import { ButtonCircleComponent } from './components/button-circle/button-circle.component';
import { InputFileOnlineFormComponent } from './components/input-file-online-form/input-file-online-form.component';
import { ConfirmOnlineFormComponent } from './components/confirm-online-form/confirm-online-form.component';
import { InvalidFileComponent } from './components/invalid-file/invalid-file.component';
import { AutocompleteControlComponent } from './components/autocomplete-control/autocomplete-control.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ConfirmUserComponent } from './components/dialogs/confirm-user/confirm-user.component';
import { SuccessfulDynamicComponent } from './components/dialogs/successful-dynamic/successful-dynamic.component';
import { AssignAnalystComponent } from './components/dialogs/assign-analyst/assign-analyst.component';
import { ConfirmSupplierDialogComponent } from './components/dialogs/confirm-supplier-dialog/confirm-supplier-dialog.component';
import { ConfirmTypeReportComponent } from './components/dialogs/confirm-type-report/confirm-type-report.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    MenuLeftComponent,
    SvgCircleComponent,
    TableComponent,
    SelectComponent,
    AddCustomerComponent,
    RangeComponent,
    PaginatorComponent,
    ErrorComponent,
    CreateUserComponent,
    EditProviderComponent,
    EditCustomerComponent,
    WelcomeProviderComponent,
    TermsAndConditionsComponent,
    WayToPayComponent,
    EndProcessComponent,
    SuccessfulComponent,
    SuccessfulDynamicComponent,
    CircleProgressbarComponent,
    ConfirmDialogComponent,
    ConfirmSupplierDialogComponent,
    ConfirmDocumentDialogComponent,
    ImportantNotesComponent,
    ProgressbarComponent,
    CollapseDetailTableComponent,
    InputFileComponent,
    SelectV2Component,
    RequalificationRequestComponent,
    TableBulkUploadComponent,
    SelectBasicformsComponent,
    TermsAndConditionsEcuadorComponent,
    TermsAndConditionsColombiaComponent,
    SelectLanguageComponent,
    SelectQuizLetterComponent,
    TermsAndConditionsBrazilComponent,
    ExtraTimeComponent,
    EditInformationSupplierComponent,
    CancelRatingComponent,
    BlockStateComponent,
    ButtonComponent,
    AutocompleteComponent,
    TermsAndConditionsPersonNaturalComponent,
    TermsAndConditionsPersonLegalComponent,
    ModalTermsAndConditionsDocumentUploadComponent,
    SessionExpiredComponent,
    CleanButtonComponent,
    TitleOnlineFormComponent,
    ButtonCircleComponent,
    InputFileOnlineFormComponent,
    ConfirmOnlineFormComponent,
    InvalidFileComponent,
    AutocompleteControlComponent,
    MultiSelectComponent,
    ConfirmUserComponent,
    AssignAnalystComponent,
    ConfirmTypeReportComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CofaceCommonsModule,
    PrimeNgModule,
    TranslateModule,
    AuthCommonModule,
  ],
  exports: [
    SvgCircleComponent,
    TableComponent,
    SelectComponent,
    RangeComponent,
    CofaceCommonsModule,
    AddCustomerComponent,
    PaginatorComponent,
    CircleProgressbarComponent,
    ConfirmDialogComponent,
    ConfirmSupplierDialogComponent,
    ConfirmDocumentDialogComponent,
    ProgressbarComponent,
    CollapseDetailTableComponent,
    InputFileComponent,
    SelectV2Component,
    ButtonComponent,
    AutocompleteComponent,
    ModalTermsAndConditionsDocumentUploadComponent,
    SessionExpiredComponent,
    CleanButtonComponent,
    TitleOnlineFormComponent,
    ButtonCircleComponent,
    InputFileOnlineFormComponent,
    ConfirmOnlineFormComponent,
    AutocompleteControlComponent,
    MultiSelectComponent,
    ConfirmUserComponent,
    SuccessfulDynamicComponent,
    AssignAnalystComponent,
    ConfirmTypeReportComponent,
  ],
  providers: [],
})
export class SharedModule {}
