import { Directive, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Directive({
  selector: '[cofaceReactiveFile]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveFileDirective),
      multi: true,
    },
  ],
})
export class ReactiveFileDirective implements ControlValueAccessor {
  // tslint:disable-next-line: no-any
  file: File | null = null;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  public constructor() {}

  @HostListener('change', ['$event'])
  public _handleInputEvent(event: Event) {
    let file = (event.target as HTMLInputElement).files;
    if (file !== null) {
      let newfile = file[0] as File;
      const fileFormat = new File(
        [newfile],
        'coface-file'
          .concat(this.currentDate())
          .concat('.' + newfile.name.split('.').pop()!)
      );
      this.file = fileFormat;
      this.onChange(fileFormat);
      this.onTouched();
    }
  }

  @HostListener('resetFile', ['$event'])
  public resetFileEvent(event: Event) {
    this.file = null;
    this.onChange(this.file);
    this.onTouched();
  }

  private currentDate(): string {
    return moment().format('YYYY-MM-DD.HHmmssSSS');
  }

  // tslint:disable-next-line: no-any
  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public writeValue(value: File | null) {
   
    this.file = value;
  }

  
}
