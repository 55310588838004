import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  private store = new Map<string, any>();

  public constructor() {}

  public set<T>(code: string, data: T) {
    this.store.set(code, data);
  }
  public remove(code: string) {
    this.store.delete(code);
  }
  public get<T>(code: string): T {
    if (!this.store.get(code)) {
      return null as unknown as T;
    }
    return this.store.get(code);
  }
  public deleteAll() {
    this.store.clear();
  }
}
