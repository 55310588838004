import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chargePipeById',
})
export class ChargePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'REPRESENTATIVE':
        return 'Representante';
      case 'COMMERCIAL':
        return 'Comercial';
      case 'FINANCE':
        return 'Finanzas';
      case 'SALES':
        return 'Ventas';
      case 'CREDIT':
        return 'Credito';
      default:
        return 'Ninguno';
    }
  }
}
