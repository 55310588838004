import {
  ILanguages,
  IMonthTerms,
  IRoles,
} from './../../auth/commons/interface/auth.interface';
import { IResponseCoface } from './../../commons/interface/response-coface.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'projects/ui-coface/src/environments/environment';
import { firstValueFrom, map } from 'rxjs';
import {
  ICountries,
  IMenuCoface,
  IQuestionnaireType,
} from '../../commons/interface/menu.interface';
import { Parameter } from '../../commons/model/parameter.model';
import { CustomerList } from '../../modules/customer/commons/models/customer-list';
import { IValidityPeriodLimit } from '../../auth/commons/interface/auth.interface';
import { ICondition } from '../../commons/interface/conditions.interface';
import { IEconomicActivity } from '../../modules/home/commons/interface/economy-activity.interface';

@Injectable({
  providedIn: 'root',
})
export class DropdawnService {
  public dataCountries: ICountries[] = [];
  public dataCountriesDashboard: Array<Parameter> = [];
  // public dataPeriodOfValidity: IResponseCoface<IValidityPeriodLimit>;
  public dataPeriodOfValidity: IMonthTerms[] = [];
  public dataListLanguages: ILanguages[] = [];
  public dataListRoles: IRoles[] = [];
  public dataListCountries: ICountries[] = [];
  public dataQuestionnaireType;
  public dataAnalysts;
  public dataConditions: Array<Parameter>;
  public dataSendEdit: CustomerList;
  public failedInit = false;
  public menuModules: any;

  constructor(private _http: HttpClient) {}

  public async load() {
    try {
      this.dataCountries = await firstValueFrom(this.getCountries());
      this.dataCountriesDashboard = this.dataCountries.map((elm) => {
        const parameter = new Parameter(elm.id.toString(), elm.name);
        return parameter;
      });
      this.dataListLanguages = await firstValueFrom(this.getListLanguages());
      this.dataListRoles = await firstValueFrom(this.getListRoles());
      this.dataListCountries = await firstValueFrom(
        this.getCountriesAndOffice()
      );

      this.dataPeriodOfValidity = await firstValueFrom(
        this.getPeriodOfValidity()
      );
      this.dataQuestionnaireType = await firstValueFrom(
        this.getQuestionnaireType()
      );
      this.dataConditions = await firstValueFrom(this.getConditions());
      // this.dataAnalysts = await firstValueFrom(this.getListAnalyst());

      this.menuModules = await firstValueFrom(this.getMenuModules());
    } catch (error) {
      console.error('ERROR CARGANDO EL LOAD', error);
      this.failedInit = true;
    }
  }

  public getCountries() {
    return this._http
      .get<IResponseCoface<ICountries[]>>(
        `${environment.urlBaseEndpointV2}/platform/countries`
      )
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  public getQuestionnaireType() {
    return this._http
      .get<IResponseCoface<IQuestionnaireType[]>>(
        `${environment.urlBaseEndpointV2}/platform/basic-forms`
      )
      .pipe(
        map((res) => {
          let arrayParameter = new Array<Parameter>();
          res.data.forEach((elm) => {
            const parameter = new Parameter(
              elm.id.toString(),
              elm.name.toString()
            );
            arrayParameter.push(parameter);
          });
          return arrayParameter;
        })
      );
  }

  public getCustomerByCriteria(legalName: string) {
    return this._http
      .get<any>(
        `${environment.urlBaseEndpoint}/users/search-customers?legalName=${legalName}`
      )
      .pipe(
        map((res) => {
          return res.data.map((elm) => {
            const description = elm.id.toString().concat(' - ', elm.name);
            return new Parameter(elm.id.toString(), elm.name, description);
          });
        })
      );
    //return this.homeHttp.getCustomerByCriteria$(legalName);
  }

  // public getPeriodOfValidity() {
  // 	return this._http
  // 		.get<IPeriodOfValidity[]>(`${environment.urlBaseEndpoint}/deliveryterms`)
  // 		.pipe(
  // 			map((res) => {
  // 				let arrayParameter = new Array<Parameter>();
  // 				res.forEach((elm) => {
  // 					const parameter = new Parameter(
  // 						elm.id.toString(),
  // 						elm.months.toString() + '  meses'
  // 					);
  // 					// elm.id == 1 ? elm.months.toString() + ' mes' : ' meses';
  // 					arrayParameter.push(parameter);
  // 				});
  // 				return arrayParameter;
  // 			})
  // 		);
  // }

  public getPeriodOfValidity() {
    return this._http
      .get<IResponseCoface<IValidityPeriodLimit>>(
        `${environment.urlBaseEndpointV2}/platform/configs`
      )
      .pipe(
        map((res) => {
          let month = res.data.validityPeriodLimit;
          for (let i = 1; i <= month; i++) {
            let obj = { id: i, name: i + ' meses' };
            this.dataPeriodOfValidity.push(obj);
          }
          let objIndex = this.dataPeriodOfValidity.findIndex(
            (obj) => obj.id == 1
          );
          this.dataPeriodOfValidity[objIndex].name =
            this.dataPeriodOfValidity[objIndex].id + ' mes';
          return this.dataPeriodOfValidity;
        })
      );
  }

  public getListLanguages() {
    return this._http
      .get<IResponseCoface<ILanguages[]>>(
        `${environment.urlBaseEndpointV2}/platform/languages`
      )
      .pipe(
        map((res) => {
          return (this.dataListLanguages = res.data);
        })
      );
  }

  public getListRoles() {
    return this._http
      .get<IResponseCoface<IRoles[]>>(
        `${environment.urlBaseEndpointV2}/platform/users/roles`
      )
      .pipe(
        map((res) => {
          return (this.dataListRoles = res.data);
        })
      );
  }

  public getCountriesAndOffice() {
    const params = { office: 'true' };
    return this._http
      .get<IResponseCoface<ICountries[]>>(
        `${environment.urlBaseEndpointV2}/platform/countries`,
        { params: params }
      )
      .pipe(
        map((res) => {
          return (this.dataListCountries = res.data);
        })
      );
  }

  public getListAnalyst() {
    // return this._http
    //   .get<IAnalyst[]>(`${environment.urlBaseEndpoint}/managers`)
    //   .pipe(
    //     map((res) => {
    //       let arrayParameter = new Array<Parameter>();
    //       res.forEach((elm) => {
    //         const parameter = new Parameter(
    //           elm.id.toString(),
    //           elm.name.toString(),
    //           elm.lastnameOne?.toString()
    //         );
    //         arrayParameter.push(parameter);
    //       });
    //       return arrayParameter;
    //     })
    //   );
  }

  // public getModules(rol) {
  //   const params = new HttpParams().set('roleKeyword', rol);
  //   return this._http.get<IResponseCoface<IMenuCoface>>(
  //     `${environment.urlBaseEndpointV2}/platform/get-my-richy-modules-by-role-please`,
  //     { params: params }
  //   );
  // }

  // private getNewParameter(res) {
  //   let arrayParameter = new Array<Parameter>();
  //   res.forEach((elm) => {
  //     const parameter = new Parameter(elm.id, elm.name);
  //     arrayParameter.push(parameter);
  //   });
  //   return arrayParameter;
  // }

  private getConditions() {
    return this._http
      .get<IResponseCoface<ICondition[]>>(
        `${environment.urlBaseEndpointV2}/platform/operations/conditions`
      )
      .pipe(
        map((res) => {
          let arrayParameter = new Array<Parameter>();
          res.data.forEach((elm) => {
            const parameter = new Parameter(
              elm.id.toString(),
              elm.name.toString()
            );
            arrayParameter.push(parameter);
          });
          return arrayParameter;
        })
      );
  }

  public getMenuModules() {
    return this._http
      .get<IResponseCoface<IMenuCoface>>('assets/json/menu.json')
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }
  // public getModulesProvider() {
  //   return this._http.get(
  //     `https://635a368b-76eb-4ce6-ad78-d3ba2d54936b.mock.pstmn.io/dashboard`
  //   );
  // }
}
