import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
// import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';

@Component({
  selector: 'coface-successful-dynamic',
  templateUrl: './successful-dynamic.component.html',
  styleUrls: ['./successful-dynamic.component.scss'],
  providers: [DialogService],
})
export class SuccessfulDynamicComponent implements OnInit {
  data: {
    title: string;
    description: string;
  };
  title: string;
  description: string;
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;

    const { title, description } = this.data;

    this.title = title;
    this.description = description;
  }

  closePOPUP() {
    this.ref.close();
  }
}
