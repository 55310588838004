import { isNgTemplate } from '@angular/compiler';
import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import { IFilePaymentInfo, IViewReport } from './homologacion-interface';

export class HomologationList extends CofaceBuilder {
  private conditionId: number;
  public get ConditionId(): number {
    return this.conditionId;
  }
  public set ConditionId(value: number) {
    this.conditionId = value;
  }
  private homologationId: number;
  public get HomologationId(): number {
    return this.homologationId;
  }
  public set HomologationId(value: number) {
    this.homologationId = value;
  }
  private nationalIdCustomer: string;
  public get NationalIdCustomer(): string {
    return this.nationalIdCustomer;
  }
  public set NationalIdCustomer(value: string) {
    this.nationalIdCustomer = value;
  }
  private legalNameCustomer: string;
  public get LegalNameCustomer(): string {
    return this.legalNameCustomer;
  }
  public set LegalNameCustomer(value: string) {
    this.legalNameCustomer = value;
  }
  private dateProcessInit: null;
  public get DateProcessInit(): null {
    return this.dateProcessInit;
  }
  public set DateProcessInit(value: null) {
    this.dateProcessInit = value;
  }
  private dateCertificateInit: null;
  public get DateCertificateInit(): null {
    return this.dateCertificateInit;
  }
  public set DateCertificateInit(value: null) {
    this.dateCertificateInit = value;
  }
  private dateCertificateEnd: null;
  public get DateCertificateEnd(): null {
    return this.dateCertificateEnd;
  }
  public set DateCertificateEnd(value: null) {
    this.dateCertificateEnd = value;
  }
  private report: IFilePaymentInfo;
  public get Report(): IFilePaymentInfo {
    return this.report;
  }
  public set Report(value: IFilePaymentInfo) {
    this.report = value;
  }
  private rating: IFilePaymentInfo;
  public get Rating(): IFilePaymentInfo {
    return this.rating;
  }
  public set Rating(value: IFilePaymentInfo) {
    this.rating = value;
  }

  private isPaymentVerified: boolean;
  public get IsPaymentVerified(): boolean {
    return this.isPaymentVerified;
  }
  public set IsPaymentVerified(value: boolean) {
    this.isPaymentVerified = value;
  }

  private isPayed: number;
  public get IsPayed(): number {
    return this.isPayed;
  }
  public set IsPayed(value: number) {
    this.isPayed = value;
  }

  private tooltipMessage: string;
  public get TooltipMessage(): string {
    return this.tooltipMessage;
  }
  public set TooltipMessage(value: string) {
    this.tooltipMessage = value;
  }

  private isValidShowReport: boolean;
  public get IsValidShowReport(): boolean {
    return this.isValidShowReport;
  }
  public set IsValidShowReport(value: boolean) {
    this.isValidShowReport = value;
  }

  private isValidShowRating: boolean;
  public get IsValidShowRating(): boolean {
    return this.isValidShowRating;
  }
  public set IsValidShowRating(value: boolean) {
    this.isValidShowRating = value;
  }

  private isValidRequest: boolean;
  public get IsValidRequest(): boolean {
    return this.isValidRequest;
  }
  public set IsValidRequest(value: boolean) {
    this.isValidRequest = value;
  }

  static override backBuild(data: IViewReport) {
    const formateando = new HomologationList();
    if (data) {
      formateando.ConditionId = data.conditionId;
      formateando.DateCertificateEnd = data.dateCertificateEnd ?? '-';
      formateando.DateCertificateInit = data.dateCertificateInit;
      formateando.NationalIdCustomer = data.nationalIdCustomer;
      formateando.LegalNameCustomer = data.legalNameCustomer;
      formateando.DateProcessInit = data.dateProcessInit;
      formateando.HomologationId = data.homologationId;
      formateando.Report = data.report;
      formateando.Rating = data.raiting;
      formateando.IsPaymentVerified = data.isPaymentVerified;
      formateando.IsPayed = data.thisIsThePaymentIdItself;
      formateando.TooltipMessage = !data.thisIsThePaymentIdItself
        ? 'El reporte será visible una vez realice el pago y sea validado'
        : !data.isPaymentVerified
        ? 'El reporte se encuentra listo, y su pago se encuentra en revisión'
        : '';

      // SE PUEDE VER REPORTES DEL SEGUIMIENTO EN BASE A LAS SIGUIENTES CONDICIONES (SON DOCUMENTOS OPCIONALES)
      formateando.IsValidShowReport =
        data.report.id &&
        data.thisIsThePaymentIdItself &&
        data.isPaymentVerified &&
        data.dateCertificateEnd;

      // SE PUEDE VER RATINGS DEL SEGUIMIENTO EN BASE A LAS SIGUIENTES CONDICIONES (SON DOCUMENTOS OPCIONALES)
      formateando.IsValidShowRating =
        data.raiting.id &&
        data.thisIsThePaymentIdItself &&
        data.isPaymentVerified &&
        data.dateCertificateEnd;

      formateando.IsValidRequest =
        data.isPaymentVerified && !data.isRetrying && data.dateCertificateEnd;
    }
    return formateando;
  }
}
