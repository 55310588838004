import { Injectable } from '@angular/core';
import { Parameter } from '../../commons/model/parameter.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, map } from 'rxjs';
import { IResponseCoface } from '../../commons/interface/response-coface.interface';
import { IEconomicActivity } from '../../modules/home/commons/interface/economy-activity.interface';
import { environment } from 'projects/ui-coface/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EconomyActivityService {
  public dataEconomicActivity: Array<Parameter> = [];

  constructor(private _http: HttpClient) {}

  public async solveEcononiActivity(tokenSession: string) {
    try {
      this.dataEconomicActivity = await firstValueFrom(
        this.getEconomicActivity$(tokenSession)
      );
    } catch (error) {
      this.dataEconomicActivity = [];
    }
  }

  // public solveEcononiActivity(tokenSession: string) {
  //   this.getEconomicActivity$(tokenSession).subscribe((res) => {
  //     this.dataEconomicActivity = res;
  //   });
  // }

  public getEconomicActivity$(tokenSession: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${tokenSession}`,
    });
    return this._http
      .get<IResponseCoface<Array<IEconomicActivity>>>(
        `${environment.urlBaseEndpoint}/platform/economic_activities`,
        {
          headers,
        }
      )
      .pipe(
        map((res) => {
          return res.data.map((elm) => {
            const description = elm.id.toString().concat(' - ', elm.name);
            return new Parameter(elm.id.toString(), elm.name, description);
          });
        })
      );
  }
}
