<div id="myModal" *ngIf="showPOPUP" class="modal">
  <div class="modal__body">
    <a class="btn__close" (click)="closeModal()">
      <img class="btn__close__img" src="assets/img/cerrar-modal.svg" alt="" />
    </a>
    <div>
      <div class="modal__body__text">
        <h3 class="text-coface-primary font-bold text-xl mb-2">
          {{ "Nuevo cliente cargado con éxito" | translate }}
        </h3>
        <p class="text-sm">
          {{
            "Las credenciales del nuevo cliente han sido creadas con éxito y enviadas al correo registrado"
              | translate
          }}
        </p>
      </div>
      <hr />
      <button
        class="btn__accept w-full text-white bg-coface-primary hover:opacity-75 cursor-pointer"
        (click)="closeModal()"
      >
        {{'Aceptar' | translate}}
      </button>
    </div>
  </div>
</div>
