<!-- {{ detail? | json }} -->
<container-element [ngSwitch]="detail?.moduleType">
  <!-- MODULO CARTERA CLIENTES -->
  <some-element *ngSwitchCase="'WALLET_CUSTOMER'">
    <div class="contentExpand flex">
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Dirección" | translate }}:</p>
        <p>{{ detail?.dataDetail?.address }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Teléfono" | translate }}:</p>
        <p>{{ detail?.dataDetail?.phone }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Correo electrónico" | translate }}:</p>
        <p>{{ detail?.dataDetail?.email }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Persona de contacto" | translate }}:</p>
        <p>{{ detail?.dataDetail?.name }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Calificación" | translate }}:</p>
        <ng-container *ngFor="let item of detail?.dataDetail?.scorePolicy">
          <p>{{ item.classification }} ({{ item.from }} - {{ item.until }})</p>
        </ng-container>

        <!-- <p>
          A ({{ detail?.dataDetail?.scorePolicy.fromA }} -
          {{ detail?.dataDetail?.scorePolicy.toA }})
        </p>
        <p>
          B ({{ detail?.dataDetail?.scorePolicy.fromB }} -
          {{ detail?.dataDetail?.scorePolicy.toB }})
        </p>
        <p>
          C ({{ detail?.dataDetail?.scorePolicy.fromC }} -
          {{ detail?.dataDetail?.scorePolicy.toC }})
        </p>
        <p>
          D ({{ detail?.dataDetail?.scorePolicy.fromD }} -
          {{ detail?.dataDetail?.scorePolicy.toD }})
        </p> -->
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Validez del reporte" | translate }}:</p>
        <p>{{ detail?.dataDetail?.deliveryTerm }} {{ "meses" | translate }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Carta de presentación" | translate }}:</p>

        <p *ngIf="detail?.dataDetail?.downloadPresentationLetter">
          <button
            (click)="
              downloadCustomLetters(
                detail?.dataDetail?.downloadPresentationLetter
              )
            "
            class="underline"
          >
            {{ "Descargar documento" | translate }}
          </button>
        </p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Cuestionario específico" | translate }}:</p>
        <p *ngIf="detail?.dataDetail?.downloadCustomForms.length">
          <button
            (click)="
              downloadCustomForms(detail?.dataDetail?.downloadCustomForms)
            "
            class="underline"
          >
            {{ "Descargar documento" | translate }}
          </button>
        </p>
      </div>
    </div>
  </some-element>
  <!-- FIN MODULO CARTERA CLIENTES -->
  <!-- MODULO CARTERA PROVEEDORES -->
  <some-element *ngSwitchCase="'WALLET_PROVIDER'">
    <div class="contentExpand flex">
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Actividad Económica" | translate }}:</p>
        <p>{{ detail?.dataDetail?.activity }}</p>
        <!-- <p>----------</p> -->
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Vigencia" | translate }}:</p>
        <p>{{ detail?.dataDetail?.validity }}</p>
      </div>
      <!-- <div class="my-2 mx-5">
        <p class="font-bold">Calificación vencida:</p>
        <p>{{ detail?.dataDetail?.lateGradeQualification }}</p>
      </div> -->
      <div class="my-2 mx-5">
        <p class="font-bold">
          {{ "Fecha de última calificación" | translate }}:
        </p>
        <p>{{ detail?.dataDetail?.dateProcessEnd }}</p>
      </div>

      <div *ngIf="detail?.dataDetail?.rejectionReason" class="my-2 mx-5">
        <p class="font-bold">{{ "Motivo de rechazo" | translate }}:</p>
        <p>{{ detail?.dataDetail?.rejectionReason }}</p>
      </div>
      <div *ngIf="detail?.dataDetail?.classification" class="my-2 mx-5">
        <p class="font-bold">{{ "Clasificación" | translate }}:</p>
        <p>{{ detail?.dataDetail?.classification }}</p>
      </div>
      <div *ngIf="detail?.dataDetail?.score" class="my-2 mx-5">
        <p class="font-bold">{{ "Calificación" | translate }}:</p>
        <p>{{ detail?.dataDetail?.score }}</p>
      </div>
      <div *ngIf="detail?.dataDetail?.analystName" class="my-2 mx-5">
        <p class="font-bold">{{ "Nombre del analista" | translate }}:</p>
        <p>{{ detail?.dataDetail?.analystName }}</p>
      </div>
      <!-- {{ detail?.dataDetail | json }} -->
      @if(showAction){
      <button
        (click)="openAssignModal()"
        class="flex-shrink ml-auto disabled:opacity-50 disabled:cursor-not-allowed"
        [disabled]="!detail?.dataDetail?.validStatusHomologation"
      >
        <img src="assets/img/upload/edit.svg" alt="ASIGNAR ANALISTA" />
      </button>
      }

      <!-- <div class="my-2 mx-5">
        <p class="font-bold">Fecha de calificación:</p>
        <p>{{ detail?.dataDetail?.qualificationDate }}</p>
      </div> -->
    </div>
  </some-element>

  <some-element *ngSwitchCase="'WALLET_PROVIDER_SUPPLIER'">
    <div class="contentExpand flex">
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Actividad Económica" | translate }}:</p>
        <!-- <p>{{ detail?.dataDetail?.activity }}</p> -->
        <p>----------</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">{{ "Vigencia" | translate }}:</p>
        <p>{{ detail?.dataDetail?.dateProcessEnd }}</p>
      </div>
      <div class="my-2 mx-5">
        <p class="font-bold">
          {{ "Fecha de última calificación" | translate }}:
        </p>
        <p>{{ detail?.dataDetail?.dateProcessInit }}</p>
      </div>
      <div *ngIf="detail?.dataDetail?.rejectionReason" class="my-2 mx-5">
        <p class="font-bold">{{ "Motivo de rechazo" }}:</p>
        <p>{{ detail?.dataDetail?.rejectionReason }}</p>
      </div>

      <div *ngIf="detail?.dataDetail?.classification" class="my-2 mx-5">
        <p class="font-bold">{{ "Clasificación" }}:</p>
        <p>{{ detail?.dataDetail?.classification }}</p>
      </div>
      <div *ngIf="detail?.dataDetail?.score" class="my-2 mx-5">
        <p class="font-bold">{{ "Calificación" }}:</p>
        <p>{{ detail?.dataDetail?.score }}</p>
      </div>

      <!-- <div class="my-2 mx-5">
        <p class="font-bold">Fecha de calificación:</p>
        <p>{{ detail?.dataDetail?.qualificationDate }}</p>
      </div> -->
    </div>
  </some-element>
  <!-- MODULO CARTERA PROVEEDORES -->
  <some-element *ngSwitchDefault
    >NOT FOUND MODULE !!!!!!!!!!!!!!!!!</some-element
  >
</container-element>
