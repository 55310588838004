import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IAnalyst } from 'projects/ui-coface/src/app/commons/interface/analyst.interface';
import {
  IParameter,
  Parameter,
} from 'projects/ui-coface/src/app/commons/model/parameter.model';
import { AssignAnalystOperation } from 'projects/ui-coface/src/app/commons/operations/assign-analyst.http';

@Component({
  selector: 'coface-assign-analyst',
  templateUrl: './assign-analyst.component.html',
  styleUrl: './assign-analyst.component.scss',
})
export class AssignAnalystComponent implements OnInit {
  public dataAnalyst: Array<Parameter>;
  public selectedAnalyst: FormControl<string> = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  });
  public loading = false;

  public dataOperation: {
    supplierName: string;
    customerName: string;
    homologationId: number;
  };

  constructor(
    private readonly operation: AssignAnalystOperation,
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.dataOperation = this.config.data;
    this.operation.getAnalyst$().subscribe((res) => {
      this.dataAnalyst = res.map((elm) => {
        return new Parameter(
          elm.id.toString(),
          elm.firstName.concat(' ' + elm.lastName)
        );
      });
    });
  }

  public closeModal() {
    this.ref.close();
  }

  onAssignAnalyst() {
    this.loading = true;
    this.operation
      .assignAnalyst$({
        operationId: this.dataOperation.homologationId,
        analystId: Number(this.selectedAnalyst.value),
      })
      .subscribe({
        next: () => {
          this.ref.close('SUCCESS');
        },
        error: () => {
          this.ref.close('ERROR');
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
