import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LanguageService } from 'projects/ui-coface/src/app/core/services/language.service';

@Component({
  selector: 'coface-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit, AfterViewInit {
  title: string = 'Seleccione';
  itemsLanguage: any;
  disableBtn: boolean = true;
  formSelectLanguage: UntypedFormGroup;
  settingLanguage: any;
  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.itemsLanguage = [
      { id: 1, name: 'Español', code: 'es' },
      { id: 2, name: 'Portugués', code: 'pt' },
    ];
  }

  initForm() {
    this.formSelectLanguage = this.fb.group({
      language: ['', Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.formSelectLanguage.controls['language'].valueChanges.subscribe(
      (data) => {
        this.settingLanguage = data;
        this.disableBtn = false;
      }
    );
  }

  confirmProcessLanguage() {
    let code = this.itemsLanguage.filter(
      (elem) => elem.id === this.settingLanguage
    );

    this.languageService
      .updateProcessLanguage(code[0].code, this.dialogConfig.data)
      .subscribe(() => {
        // console.log(
        //   'IDIOMA ACTUALIZADO CON EXITO',
        //   code[0].code,
        //   this.dialogConfig.data
        // );
        this.dialogRef.close('COMPLETE');
      });
  }
}
