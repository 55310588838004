import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';

import { Observable } from 'rxjs';
import { IResponseCoface } from '../../../commons/interface/response-coface.interface';
import { IAuthRequest, IAuthResponse } from '../interface/auth.interface';

@Injectable()
export class AuthHttp {
  public constructor(private http: HttpClient) {}

  public auth$(body: IAuthRequest): Observable<IResponseCoface<IAuthResponse>> {
    const headers = new HttpHeaders({
      'no-intercept-token': 'true',
      'no-intercept': 'true',
    });
    const url = `${environment.urlBaseEndpointV2}/auth/tokens/login`;
    return this.http.post<IResponseCoface<IAuthResponse>>(url, body, {
      headers,
    });
    //authenticate
  }

  public authLogout$() {
    const body = {};
    const headers = new HttpHeaders({ 'no-intercept': 'true' }); //authenticate
    const url = `${environment.urlBaseEndpointV2}/auth/tokens/logout`; //authenticate
    return this.http.post(url, body, { headers });
  }
}
