import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomerOperation } from 'projects/ui-coface/src/app/modules/customer/commons/operations/customer.operations';
import { WalletProviderOperation } from 'projects/ui-coface/src/app/modules/provider/commons/operation/provider.operation';

@Component({
  selector: 'coface-confirm-dialog',
  templateUrl: './confirm-supplier-dialog.component.html',
  styleUrls: ['./confirm-supplier-dialog.component.scss'],
})
export class ConfirmSupplierDialogComponent implements OnInit {
  public loading: boolean;
  public supplierData: {
    legalNameSupplier: string;
    homologationId: number;
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private operation: WalletProviderOperation
  ) {}

  ngOnInit(): void {
    const data = this.config.data.row;
    const { homologationId, legalNameSupplier } = data;
    this.supplierData = { homologationId, legalNameSupplier };
  }

  public onDelete() {
    this.loading = true;
    this.operation
      .deleteWalletProvider$(this.supplierData?.homologationId)
      .subscribe((_) => {
        this.ref.close('SUCCESS');
      })
      .add(() => {
        this.loading = false;
      });
  }
  public onCancel() {
    this.ref.close('CANCEL');
  }
}
