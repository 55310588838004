import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectLanguageComponent } from '../select-language/select-language.component';

@Component({
  selector: 'coface-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  providers: [DialogService],
})
export class TermsAndConditionsComponent implements OnInit {
  authorizeOne: boolean = false;
  authorizeTwo: boolean = false;
  authorizeThree: boolean = false;
  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}
  // changeFirstRadioGroup(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorizeOne = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorizeOne = false;
  //   }
  // }
  // changeSecondRadioGroup(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorizeTwo = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorizeTwo = false;
  //   }
  // }
  // changeThirdRadioGroup(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorizeThree = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorizeThree = false;
  //   }
  // }
  onAcceptTermCond() {
    this.dialogRef.close('COMPLETE');
    // console.log('hola');
  }
}
