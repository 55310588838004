<section class="flex flex-col items-center justify-center gap-8 py-16 relative">
  <button
    class="absolute top-2 -right-4 rounded-full w-6 h-6 text-center bg-gray-300 text-white cursor-pointer font-extrabold hover:bg-gray-500"
    (click)="closeModal()"
  >
    X
  </button>
  <h5 class="text-coface-primary text-center font-semibold tracking-wide">
    Selecciona el tipo de reporte
  </h5>
  <div>
    <coface-select
      [items]="items"
      [formControl]="reportType"
      cofaceReactiveForm
    >
    </coface-select>
  </div>
  <div class="w-[250px] mx-auto">
    <coface-button
      text="Enviar"
      color="primary"
      [disabled]="reportType.invalid || isLoadingSendAnalyst"
      [loading]="isLoadingSendAnalyst"
      (clicked)="toSendAnalystWithReport()"
    ></coface-button>
  </div>
</section>
