import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[cofaceAddPlusSign]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddPlusSignDirective,
      multi: true
    }
  ]
})
export class AddPlusSignDirective implements ControlValueAccessor {
  private onChange: (value: string) => void;
  private onTouched: () => void;
  
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  
  @HostListener('input', ['$event.target.value']) onInput(value: string) {
    let numericValue = value.replace(/\D/g, ''); 

    if (numericValue && !numericValue.startsWith('+')) {
      numericValue = '+' + numericValue;
    }

   
    this.renderer.setProperty(this.el.nativeElement, 'value', numericValue);

    
    this.onChange(numericValue.replace('+', ''));
  }

  
  writeValue(value: string): void {
    if (value) {
      const formattedValue = '+' + value.replace(/\D/g, ''); 
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
