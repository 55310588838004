import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'coface-clean-button',

  templateUrl: './clean-button.component.html',
  styleUrls: ['./clean-button.component.scss'],
})
export class CleanButtonComponent {
  @Output() clicked = new EventEmitter();

  public onClick(): void {
    this.clicked.emit();
  }
}
