import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { StoreName } from 'projects/ui-coface/src/app/commons/enums/store-name.enum';
import { dataPersonTermsAndConditions } from 'projects/ui-coface/src/app/commons/interface/dataPersonTermsAndConditions';
import { TermConditionOperation } from 'projects/ui-coface/src/app/commons/operations/term-condition.operation';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { INVALID_FILE } from 'projects/ui-coface/src/app/commons/utils/util';
import { InvalidFileComponent } from '../../../invalid-file/invalid-file.component';

@Component({
  selector: 'coface-terms-and-conditions-person-legal',
  templateUrl: './terms-and-conditions-person-legal.component.html',
  styleUrls: ['./terms-and-conditions-person-legal.component.scss'],
})
export class TermsAndConditionsPersonLegalComponent implements OnInit {
  formPersonLegal!: UntypedFormGroup;
  selectedFile: File | null = null;
  nameFile: String = '';
  urlIconUpload: string = '../../../../../../assets/img/upload/uploadIcon.svg';
  dataTerms: any;

  @ViewChild('fileDocumentPerson') fileDocumentPerson: ElementRef;

  constructor(
    private dialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private serviceTermCondOperation: TermConditionOperation,
    private dialogService: ModalService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getTermsAndCondition();
  }

  initForm() {
    this.formPersonLegal = this.fb.group({
      namePerson: ['', [Validators.maxLength(40), Validators.required]],
      fileDocumentIdentity: ['', [Validators.required]],
    });
  }

  getTermsAndCondition() {
    let data: any;
    if (localStorage.getItem(StoreName.TERMS_AND_CONDITIONS)) {
      data = JSON.parse(
        localStorage.getItem(StoreName.TERMS_AND_CONDITIONS) || ''
      );
    }
    const obj = {
      data: data.contentForLegalPerson,
    };
    this.dataTerms = obj;
  }

  // Método para manejar el cambio del archivo
  onFileChange(event: any) {
    const file = event.target.files[0];

    // this.formPersonLegal.get('fileDocumentIdentity')?.markAsTouched();
    // this.formPersonLegal
    //   .get('fileDocumentIdentity')
    //   ?.updateValueAndValidity({ emitEvent: true });
    // console.log('entro', this.formPersonLegal.get('fileDocumentIdentity'));

    if (file) {
      const isInvalidFile = INVALID_FILE(file);
      if (isInvalidFile.invalidExtension || isInvalidFile.invalidSize) {
        this.dialogService.open(
          {
            invalidText: isInvalidFile.invalidText,
          },
          InvalidFileComponent,
          {
            size: 'SMALL',
            closable: '',
            disableClose: false,
          }
        );
      }

      this.selectedFile = file;
      this.nameFile = file.name;
      this.formPersonLegal.patchValue({
        file: file,
      });
    }
  }

  onclickUploadFile() {
    this.fileDocumentPerson.nativeElement.click();
    this.formPersonLegal.get('fileDocumentIdentity')?.markAsTouched();
  }

  // Método para manejar el envío del formulario
  onSubmit() {
    if (this.formPersonLegal.valid && this.selectedFile) {
      const data: dataPersonTermsAndConditions = {
        typePerson: 'Juridica',
        nameLegalRepresentative: this.formPersonLegal.get('namePerson')?.value,
        Document: this.selectedFile,
      };
      this.serviceTermCondOperation.dataPersonTermsAndConditions = data;
      // console.log('Archivo seleccionado:', this.selectedFile);
    } else {
      // console.log('Formulario no válido o archivo no seleccionado.');
    }
  }

  deleteFile() {
    this.selectedFile = null;
    this.formPersonLegal.patchValue({
      file: null,
    });
    const name = this.formPersonLegal.get('namePerson')?.value;
    this.formPersonLegal.reset();
    this.formPersonLegal.patchValue({
      namePerson: name,
    });
  }

  onAcceptTermCond() {
    this.dialogRef.close('COMPLETE');
    this.onSubmit();
  }

  buildImage() {
    let imageIcon = '';
    let threeLetters = this.selectedFile?.name.substring(
      this.selectedFile.name.length - 3
    );
    switch (threeLetters) {
      case 'ocx':
        imageIcon = TYPE_FILE.WORD;
        break;
      case 'pdf':
        imageIcon = TYPE_FILE.PDF;
        break;
      case 'lsx':
        imageIcon = TYPE_FILE.EXCEL;
        break;
      default:
        imageIcon = TYPE_FILE.OTRO;
        break;
    }
    return imageIcon;
  }
}

enum TYPE_FILE {
  PDF = 'pdf',
  WORD = 'word',
  EXCEL = 'excel',
  OTRO = 'document',
}
