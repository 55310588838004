<section class="flex flex-col gap-4 py-4 max-w-[350px] mx-auto">
  <h2 class="text-center text-2xl tracking-wide text-coface-primary font-bold">
    Eliminación de Usuario
  </h2>
  <p class="text-gray-500 text-center">
    Estás seguro de eliminar al Usuario <b> {{ fullName }} </b>
  </p>
  <div class="flex flex-col gap-4 justify-center">
    <coface-button
      text="Eliminar"
      (clicked)="onDelete()"
      [loading]="loading"
    ></coface-button>
    <coface-button
      [text]="'Cancelar'"
      (clicked)="onCancel()"
      color="tertiary"
    ></coface-button>
  </div>
</section>
