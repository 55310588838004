<section class="flex flex-col items-center justify-center gap-8 py-16 relative">
  <div
    class="absolute top-2 -right-4 rounded-full w-6 h-6 text-center bg-gray-300 text-white cursor-pointer font-extrabold hover:bg-gray-500"
    (click)="closeModal()"
  >
    X
  </div>
  <div class="w-[250px] mx-auto">
    <coface-button
      text="Ir al formulario"
      color="secondary"
      (clicked)="goOnlineForm()"
    ></coface-button>
  </div>

  <div class="w-[250px] mx-auto mt-4">
    <coface-button
      text="Ir a mis Documentos"
      color="primary"
      (clicked)="goDocuments()"
    ></coface-button>
  </div>
</section>
