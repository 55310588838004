import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coface-svg-circle',
  templateUrl: './svg-circle.component.svg',
  styleUrls: ['./svg-circle.component.scss'],
})
export class SvgCircleComponent implements OnInit {
  @Input() public width: string = '';
  @Input() public height: string = '';
  @Input() public baseColor: string = '';
  @Input() public gradientColor: string = '';
  @Input() public pathIcon: string = '';

  constructor() {}

  ngOnInit(): void {}
}
