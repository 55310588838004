import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-invalid-file',
  templateUrl: './invalid-file.component.html',
  styleUrl: './invalid-file.component.scss',
})
export class InvalidFileComponent implements OnInit {
  public invalidFileText: string;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.invalidFileText = this.dialogConfig.data?.invalidText;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
