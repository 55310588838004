import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'coface-select-v2',
  templateUrl: './select-v2.component.html',
  styleUrls: ['./select-v2.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SelectV2Component implements OnInit {
  cities = [
    { name: 'Seleccionar', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    // { name: 'Istanbul', code: 'IST' },
    // { name: 'Paris', code: 'PRS' },
  ];

	@Input() items = [];

  constructor() {}

  ngOnInit(): void {}
}
