import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import {
  IResponsePendingRequest,
  IResponsePendingRequestV2,
  IResponseTermsAnsConditionsUser,
} from '../interface/pending-request.interface';

@Injectable({
  providedIn: 'root',
})
export class PendingRequestEndpoint {
  public constructor(private http: HttpClient) {}

  public getPendinRequest$(request: GenericRequestConfig) {
    return this.http.get<IResponseCoface<IResponsePendingRequestV2[]>>(
      request.url,
      request
    );
  }

  public initHomologation$(request: GenericRequestConfig) {
    return this.http.post(request.url, request.body);
  }

  public deniedHomlogation$(request: GenericRequestConfig) {
    return this.http.post(request.url, request.body);
  }
}
