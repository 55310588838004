// export enum RoutePath {
//   // WALLET_PROVIDER = '/coface/proveedores/cartera-proveedores',
//   VIEW_REJECTION_REASON_POPUP = '/coface/proveedores/cartera-proveedores', // ACCOUNT MANAGER
//   VIEW_REJECTION_REASON_POPUP_ONLY_CUSTOMER = '/proveedores/clientes/cartera-proveedores', // ACCOUNT MANAGER
//   VIEW_DETAIL_PAYMENT_METHOD = '/',
//   VIEW_DOCUMENTS_IN_REVIEW = '/',
//   VIEW_FINAL_REPORT = '/',
// }

export const RouteAnalyze = new Map<
  string,
  Array<{ role: string; path: string }>
>([
  [
    'VIEW_REJECTION_REASON_POPUP',
    [
      {
        role: 'CUSTOMER',
        path: '/coface/proveedores/clientes/cartera-proveedores',
      },
      { role: 'MANAGER', path: '/coface/proveedores/cartera-proveedores' },
    ],
  ],
  [
    'VIEW_DETAIL_PAYMENT_METHOD',
    [{ role: 'SUPPLIER', path: '/coface/homologacion/registro-pago' }],
  ],
  [
    'VIEW_DOCUMENTS_IN_REVIEW',
    [{ role: 'SUPPLIER', path: '/coface/homologacion/carga-documentos' }],
  ],

  // ['VIEW_DOCUMENTS_IN_REVIEW', [{ role: 'PROVIDER', path: '/coface/homologacion/ver-reporte' }]],
]);
