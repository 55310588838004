import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomerOperation } from 'projects/ui-coface/src/app/modules/customer/commons/operations/customer.operations';

@Component({
  selector: 'coface-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  providers: [CustomerOperation],
})
export class ConfirmDialogComponent implements OnInit {
  public loading: boolean;
  public customerData: {
    nameCompany: string;
    id: number;
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private customerOpe: CustomerOperation
  ) {}

  ngOnInit(): void {
    const data = this.config.data.row;
    const { nameCompany, id } = data;
    this.customerData = { nameCompany, id };
  }

  public onDelete() {
    this.loading = true;
    this.customerOpe
      .deleteCustomers$(this.customerData?.id, this.customerData?.nameCompany)
      .subscribe((_) => {
        this.ref.close('SUCCESS');
      })
      .add(() => {
        this.loading = false;
      });
  }
  public onCancel() {
    this.ref.close('CANCEL');
  }
}
