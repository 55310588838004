export const INVALID_FILE = (file: File) => {
  const validSizeFile = (file: File) => {
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > 50) {
      return false;
    }
    return true;
  };

  const validateFileExtension = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'zip' || fileExtension === 'rar') {
      return false;
    }
    return true;
  };

  if (!validSizeFile(file)) {
    return {
      invalidSize: true,
      invalidExtension: false,
      invalidText: `El archivo ${file.name} excede el límite de tamaño permitido de 50 MB. Por favor, reduzca el tamaño del archivo e intente subirlo nuevamente.`,
    };
  }
  if (!validateFileExtension(file)) {
    return {
      invalidSize: false,
      invalidExtension: true,
      invalidText:
        'Formato de archivo no admitido. Los archivos comprimidos(ZIP/RAR) no están permitidos. Por favor, seleccione un formato de archivo compatible e intente nuevamente la carga.',
    };
  }

  return {
    invalidSize: false,
    invalidExtension: false,
    invalidText: '',
  };
};
