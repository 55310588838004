import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CofacePresenter } from 'projects/ui-coface/src/app/commons/utils/coface.presenter';

@Injectable()
export class CreateUserStaffPresenter extends CofacePresenter<any> {
  public firstname: UntypedFormControl; //change
  public email: UntypedFormControl;
  public password: UntypedFormControl;
  public repeatPassword: UntypedFormControl;
  public officeId: UntypedFormControl;
  public languageId: UntypedFormControl;
  public countryId: UntypedFormControl;
  public roleId: UntypedFormControl;
  public phoneNumber: UntypedFormControl;
  public mobileNumber: UntypedFormControl;
  // public surname2: FormControl; //change
  public lastname: UntypedFormControl; //change
  public supportLanguageId: UntypedFormControl; //change

  public officeCountries: FormControl;

  public constructor(private fb: UntypedFormBuilder) {
    super();
    this.createValidators();
    this.initForm();
    // this.form.valueChanges.subscribe((_) => {
    //   console.log(this.form);
    // });

    this.roleId.valueChanges.subscribe((data) => {
      this.officeCountries.reset([]);
      if (data === 8) {
        this.officeCountries.setValidators(Validators.required);
      } else {
        this.officeCountries.clearValidators();

      }
      this.officeCountries.updateValueAndValidity();
    });
  }

  private initForm() {
    this.form = this.fb.group(
      {
        firstname: this.firstname,
        email: this.email,
        password: this.password,
        repeatPassword: this.repeatPassword,
        officeId: this.officeId,
        languageId: this.languageId,
        countryId: this.countryId,
        roleId: this.roleId,
        phoneNumber: this.phoneNumber,
        mobileNumber: this.mobileNumber,
        lastname: this.lastname,
        supportLanguageId: this.supportLanguageId,
        officeCountries: this.officeCountries,
      }
      // { validator: ConfirmedValidator('password', 'repeatPassword') }
    );
  }
  private createValidators() {
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/;
    this.firstname = new UntypedFormControl('', [Validators.required]);
    // this.surname2 = new FormControl('', [Validators.required]);
    this.lastname = new UntypedFormControl('', [Validators.required]);
    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(emailPattern),
    ]);

    this.officeId = new UntypedFormControl('', [Validators.required]);
    this.languageId = new UntypedFormControl('', [Validators.required]);
    this.supportLanguageId = new UntypedFormControl('', []);
    this.roleId = new UntypedFormControl('', [Validators.required]);
    this.countryId = new UntypedFormControl('', []);
    this.phoneNumber = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(12),
    ]);
    this.mobileNumber = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(12),
    ]);

    this.officeCountries = new FormControl([], {
      nonNullable: true,
    });
  }

  resetForm() {
    Object.keys(this.form.controls).forEach((key) => {
      if (key === 'range') {
        let formGroupRange = this.form.get(key) as UntypedFormArray;
        formGroupRange.controls.forEach((group) => {
          group.get('from')?.reset();
          group.get('until')?.reset();
        });
      } else {
        this.form.get(key)?.reset();
      }
    });
  }

  invalid(): boolean {
    let invalid = false;

    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.get(key)?.status === 'INVALID') {
        invalid = true;
      }
    });

    return invalid;
  }

  hasErrorEmailControl() {
    return (
      this.form.get('email')?.invalid &&
      this.form.get('email')?.touched &&
      (this.form.get('email')?.hasError('required') ||
        this.form.get('email')?.hasError('pattern'))
    );
  }
}

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.get(controlName)!;
    const matchingControl = formGroup.get(matchingControlName)!;
    let obj = {
      confirmedValidator: true,
    };
    if (
      control.value &&
      matchingControl.value &&
      control.value === matchingControl.value
    ) {
      obj.confirmedValidator = false;
      // matchingControl.setErrors({ confirmedValidator: true });
    }
    return obj;
  };
}

export function SafePasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Implement your custom validation logic here
    const valid = isStrongPassword(control.value);
    if (Object.values(valid).every((x) => x === false)) {
      return null;
    }

    // return { [valid.type]: true };
    return { ...valid };

    // return { weakPassword: true };
  };
}

function isStrongPassword(password): Object {
  let objType = {
    LENGTH: true,
    UPPERCASE: true,
    LOWERCASE: true,
    DIGITNUMBER: true,
    SPECIALCHARACTER: true,
  };
  // Check if the password meets the length requirement (e.g., at least 8 characters)
  if (password?.length > 12) {
    objType.LENGTH = false;
  }

  // Check if the password contains at least one uppercase letter
  if (/[A-Z]/.test(password)) {
    objType.UPPERCASE = false;
  }

  // Check if the password contains at least one lowercase letter
  if (/[a-z]/.test(password)) {
    objType.LOWERCASE = false;
  }

  // Check if the password contains at least one digit (number)
  if (/\d/.test(password)) {
    objType.DIGITNUMBER = false;
  }

  // Check if the password contains at least one special character
  if (/[!@#$%^&*()_+[\]{}|;:,.<>?]/.test(password)) {
    objType.SPECIALCHARACTER = false;
  }

  // // Check if the password contains simple sequences
  // const sequenceRegex =
  //   /(012|123|234|345|456|567|678|789|890|987|876|765|654|543|432|321|210|ZYX|YXW|XWV|WVU|VUT|TSR|SRQ|RQP|QPO|PON|ONM|NML|MLK|LKJ|KJI|JIH|IHG|HGF|GFE|FED|EDC|DCB|CBA|3210)/;
  // if (sequenceRegex.test(password.toUpperCase())) {
  //   return false;
  // }

  // All checks passed; the password is strong
  return objType;
}
