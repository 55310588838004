<div class="requalification-request">
  <h1
    class="bg-coface-primary absolute text-white font-bold text-center w-full left-0 p-3 text-lg"
  >
    {{'Solicitud de recalificación' | translate}}
  </h1>
  <div class="pt-16 px-3">
    <p class="font-bold text-coface-primary text-center text-base py-4">
      {{'Coface con gusto procesará tu solicitud de recalificación. Solo debes considerar los siguientes aspectos' | translate}}:
    </p>
    <p class="text-center mb-5 text-sm">
      {{'Esta solicitud es gratuita dentro de los primeros 30 días de entregada la primera calificación. Posterior a este periodo, se considerará como una nueva calificación y deberá seguir el flujo correspondiente' | translate}}.
    </p>
    <div class="w-72 mx-auto">
      <button
        class="btn__passForm text-white bg-coface-secondary block text-sm width_custom"
        (click)="requestRequalification()"
      >
        {{'Continuar' | translate}}
      </button>
    </div>
  </div>
</div>
