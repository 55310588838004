import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  dataError: HttpErrorResponse;
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.dataError = this.config.data as HttpErrorResponse;
  }

  closeModal() {
    if (
      this.dataError.status == 401 ||
      this.dataError.error?.error?.debug ==
        'Full authentication is required to access this resource' ||
      this.dataError.error?.error == 'Unauthorized'
    ) {
      this.ref.close();
      this.route.navigate(['../../acceso/login']);
      // alert('Su sesión expiró')
    } else {
      this.ref.close();
    }
  }
}
