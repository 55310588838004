import { Injectable } from '@angular/core';
import { UnlockEndpoint } from '../http/unlock.endpoint';
import { GenericRequestConfig } from '../model/request.config';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class UnlockOperation {
  public constructor(
    private unlockEndpoint: UnlockEndpoint,
    private dialog: ModalService
  ) {}
  // solo manager peude desbloquear
  unlockCustomerByManager$(id: string) {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/backoffice/users/${id}/unlock`;
    return this.unlockEndpoint.unlockCustomerByManager$(req).pipe();
  }
  // solo manager puede desbloquear
  unlockSupplierByManager$(id: string) {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/backoffice/users/${id}/unlock`;
    return this.unlockEndpoint.unlockSupplierByManager$(req).pipe();
  }
  // administrador general
  unlockCoworkersByAdministrator$(id: string) {
    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/backoffice/users/${id}/unlock`;
    return this.unlockEndpoint.unlockCoworkersByAdministrator$(req).pipe();
  }
}
