<header>
  <div class="w-full h-full content-header flex justify-around items-center">
    <p class="">
      <span class="font-normal">{{ "Hola" | translate }}, </span>
      <span class="text-gray-700">{{ fullName }}</span>
    </p>
    <!-- <p>Mayo 30, 2021</p> -->
    <p class="text-gray-700">
      {{ today | date : "MMMM" | titlecase | translate }}
      {{ today | date : "d, y" }}
    </p>
  </div>
</header>
