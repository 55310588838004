import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { ListPendingRequest } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/model/pending-request.model';

@Component({
  selector: 'coface-select-basicforms',
  templateUrl: './select-basicforms.component.html',
  styleUrls: ['./select-basicforms.component.scss'],
})
export class SelectBasicformsComponent implements OnInit {
  management: boolean = false;
  services: boolean = false;
  nameBasicForm: string = '';
  idBasicForm: number;
  disabledBtn: boolean = true;
  info: ListPendingRequest;
  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private homologationOperation: HomologacionOperation,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.info = this.dialogConfig.data as ListPendingRequest;
  }

  changeFirstRadio(e) {
    this.disabledBtn = true;
    if (e.target.value === 'natural') {
      this.management = true;
      this.services = false;
    }
    if (e.target.value === 'juridica') {
      this.management = false;
      this.services = true;
    }
  }

  changeTwoRadio(e) {
    // this.disabledBtn = false;
    if (e.target.value === 'yes') {
      this.disabledBtn = true;
      this.services = true;
    } else {
      this.services = false;
      this.disabledBtn = false;
      this.nameBasicForm = 'Persona Natural no obligada a llevar contabilidad';
      this.idBasicForm = 2;
    }
  }

  changeThreeRadio(e) {
    if (this.management) {
      if (e.target.value === 'productos') {
        this.disabledBtn = false;
        this.nameBasicForm =
          'Persona Natural obligada a llevar contabilidad - COMERCIAL';
        this.idBasicForm = 3;
      } else {
        this.disabledBtn = false;
        this.nameBasicForm =
          'Persona Natural obligada a llevar contabilidad - SERVICIOS';
        this.idBasicForm = 5;
      }
    } else {
      if (e.target.value === 'productos') {
        this.disabledBtn = false;
        this.nameBasicForm = 'Empresa COMERCIAL';
        this.idBasicForm = 1;
      } else {
        this.disabledBtn = false;
        this.nameBasicForm = 'Empresa - SERVICIOS';
        this.idBasicForm = 4;
      }
    }
  }

  saveToProcess() {
    // console.log(this.nameBasicForm);
    // if(this.sessionService.user.office?.name === 'Brazil'){
    //   this.idBasicForm = 6
    // }
    if (this.sessionService.user.company!.country.name === 'Brazil') {
      this.idBasicForm = 6;
      this.homologationOperation
        .selectBasicFormId(this.idBasicForm)
        .subscribe((data) => {
          // this.dialogRef.close();
          this.router
            .navigate(['/coface/homologacion/registro-pago'], {
              queryParams: {
                homologationId: this.info.HomologationId,
              },
            })
            .then((_) => this.dialogRef.close());
        });
    } else {
      this.homologationOperation
        .selectBasicFormId(this.idBasicForm)
        .subscribe((data) => {
          // this.dialogRef.close();
          this.router
            .navigate(['/coface/homologacion/registro-pago'], {
              queryParams: {
                homologationId: this.info.HomologationId,
              },
            })
            .then((_) => this.dialogRef.close());
        });
    }
  }
}
