import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';

@Component({
  selector: 'coface-cancel-rating',
  templateUrl: './cancel-rating.component.html',
  styleUrls: ['./cancel-rating.component.scss'],
})
export class CancelRatingComponent implements OnInit {
  constructor(
    private dialogRef: DynamicDialogRef,
    private homologationOperation: HomologacionOperation,
    private activedRoute: ActivatedRoute,
    private _route: Router
  ) {}

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

  cancelRating() {
    this.homologationOperation
      .cancelHomologation(
        Number(this.activedRoute.snapshot.queryParamMap.get('homologationId'))
      )
      .subscribe((data) => {
        this.dialogRef.close();
        this._route.navigate(['./coface/home']);
      });
  }
}
