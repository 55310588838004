<div class="select-quizletter">
  <form [formGroup]="singleUploadPresenter.Form">
    <div>
      <h2 class="text-2xl mb-5 clight font-bold mx-auto">
        {{ "Subida de archivos" | translate }}
      </h2>
      <div>
        <p class="mb-4">{{ "Carga de Cuestionario Específico" | translate }}</p>
      </div>
      <div class="mb-3 border-b border-gray-200">
        <ng-container formArrayName="quizletters">
          <ng-container
            *ngFor="
              let newQuizLetter of singleUploadPresenter.getQuizLetters()
                .controls;
              let i = index
            "
          >
            <div [formGroupName]="i" class="relative">
              <div class="flex mb-5 gap-5 flex-wrap">
                <div class="w-60">
                  <div
                    class="mb-5 relative"
                    id="cofaceSelect-{{ i }}"
                    (click)="customZindex(i)"
                  >
                    <coface-select
                      class="mb-2"
                      id-select="select-office"
                      id-option="option-office"
                      cofaceReactiveForm
                      [items]="itemsLanguages"
                      [valueTitle]="title | translate"
                      formControlName="languageFile"
                      [value]="newQuizLetter.get('languageFile')?.value"
                    ></coface-select>
                  </div>
                </div>

                <div class="w-60">
                  <!-- <div
                    class="input flex justify-between relative cursor-pointer bg-gray-100 mb-5"
                  >
                    <input
                      class="mb-5 text-start w-full"
                      type="file"
                      required
                      id="fileUpload"
                      autocomplete="off"
                      #uploader
                      hidden
                      name="filename"
                      (change)="
                        singleUploadPresenter.fileEvent($event, newQuizLetter)
                      "
                      formControlName="quiz"
                      cofaceReactiveFile
                      accept=".xlsx"
                    />
                    <button
                      type="button"
                      name="fileUpload"
                      class="w-full"
                      style="outline: none"
                      *ngIf="!newQuizLetter.get('flag')?.value"
                      (click)="uploader?.click()"
                    >
                      <div class="flex justify-between items-center">
                        <p>{{ "Examinar" | translate }}</p>
                        <img
                          class="block absolute w-6 right-6"
                          src="assets/img/upload/upload.svg"
                          alt="Logo Cliente"
                          rel="preload"
                          as="image"
                        />
                      </div>
                    </button>

                    <div
                      *ngIf="newQuizLetter.get('flag')?.value"
                      class="flex justify-between items-center w-full"
                    >
                      <div class="text-color text-overflow">
                        {{ newQuizLetter.get("quiz")?.value.name }}
                      </div>
                      <button
                        (click)="
                          singleUploadPresenter.changeFileEvent(newQuizLetter)
                        "
                        type="button"
                        style="width: 24px"
                      >
                        <img
                          class="block w-6 right-6"
                          src="assets/img/cerrar-modal.svg"
                          alt="Eliminar archivo"
                          rel="preload"
                          as="image"
                        />
                      </button>
                    </div>
                  </div> -->
                  <coface-input-file-online-form
                    placeholder="Subir archivo"
                    formControlName="quiz"
                    (uploadedFile)="singleUploadPresenter.fileEvent($event, newQuizLetter)"
                    (deleteFile)="singleUploadPresenter.changeFileEvent(newQuizLetter)"
                    [shadow]="false"
                  ></coface-input-file-online-form>
                </div>
              </div>
              <button
                type="button"
                (click)="singleUploadPresenter.deleteQuizLetter(i)"
                class="text-white bg-coface btn__addForm flex items-center justify-center ml-2 absolute btn-close"
              >
                <span style="padding-bottom: 8px">x</span>
              </button>
            </div>

            <div></div>
          </ng-container>
        </ng-container>
      </div>

      <div
        *ngIf="singleUploadPresenter.getQuizLetters()?.value?.length < 2"
        class="my-5 text-sm p-5 rounded-xl select-language__info flex items-center justify-end"
      >
        <p class="text-gray-500">
          {{ "Añadir otra versión del documento" | translate }}
        </p>
        <button
          type="button"
          (click)="singleUploadPresenter.addQuizLetter()"
          class="text-white bg-coface btn__addForm flex items-center justify-center ml-2"
        >
          <span style="padding-bottom: 4px">+</span>
        </button>
      </div>
      <!-- [ngClass]="{'gray': formSelectQuizLetter.controls['quizletters'].hasError('repeat'),'gray2' : !(getQuizLetters.value.length == 2) }" -->
      <button
        *ngIf="singleUploadPresenter.getQuizLetters()?.value?.length >= 2"
        [disabled]="singleUploadPresenter.Form.controls['quizletters'].invalid"
        [ngClass]="{
          gray: singleUploadPresenter.Form.controls['quizletters'].invalid
        }"
        (click)="uploadDocument()"
        type="button"
        class="btn__passWayPay w-full text-white bg-coface-primary block mx-auto text-sm my-5"
      >
        {{ "Cargar Archivos" | translate }}
      </button>
    </div>
  </form>
</div>
<!-- [disabled]="(singleUploadPresenter.Form.controls['quizletters'].invalid) " -->
<!-- {{ singleUploadPresenter.getQuizLetters()?.value?.length < 2 }}
{{ singleUploadPresenter.Form.controls["quizletters"].valid }}
{{ singleUploadPresenter.Form.valid }}
{{ singleUploadPresenter.Form.controls["quizletters"].hasError("repeat") }} -->
<!-- {{singleUploadPresenter.Form.controls["quizletters"].hasError("repeat") }} -->
<!-- {{ singleUploadPresenter.Form.value | json }} -->
