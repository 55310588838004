import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';

import _moment from 'moment';
import { IResultFile } from 'projects/ui-coface/src/app/commons/interface/upload.interface';

export class BulkLoadProvider extends CofaceBuilder {
  private id: string;
  public get Id(): string {
    return this.id;
  }
  public set Id(value: string) {
    this.id = value;
  }
  private fileName: string;
  public get FileName(): string {
    return this.fileName;
  }
  public set FileName(value: string) {
    this.fileName = value;
  }
  private issuedAt: string;
  public get IssuedAt(): string {
    return this.issuedAt;
  }
  public set IssuedAt(value: string) {
    this.issuedAt = value;
  }
  private validRecords: number;
  public get ValidRecords(): number {
    return this.validRecords;
  }
  public set ValidRecords(value: number) {
    this.validRecords = value;
  }
  private invalidRecords: number;
  public get InvalidRecords(): number {
    return this.invalidRecords;
  }
  public set InvalidRecords(value: number) {
    this.invalidRecords = value;
  }
  private totalRecords: number;
  public get TotalRecords(): number {
    return this.totalRecords;
  }
  public set TotalRecords(value: number) {
    this.totalRecords = value;
  }

  static override backBuild(data: IResultFile) {
    const content = new BulkLoadProvider();
    if (data) {
      content.Id = data.id;
      content.FileName = data.fileName;
      content.IssuedAt = _moment(data.issuedAt).format('DD/MM/YYYY');
      content.ValidRecords = data.validRecords;
      content.InvalidRecords = data.invalidRecords;
      content.TotalRecords = data.totalRecords;
    }

    return content;
  }
}
