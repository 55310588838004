import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { ListPendingRequest } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/model/pending-request.model';
import { PendingRequestOperation } from 'projects/ui-coface/src/app/modules/homologacion/pending-request/commons/operation/pending-request.operation';
import { switchMap } from 'rxjs';

@Component({
  selector: 'coface-end-process',
  templateUrl: './end-process.component.html',
  styleUrls: ['./end-process.component.scss'],
})
export class EndProcessComponent implements OnInit, AfterViewInit {
  formRejectedHomologation: UntypedFormGroup;
  showTextArea: boolean = false;
  constructor(
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    public homologationOperation: HomologacionOperation,
    public pendingRequestOperation: PendingRequestOperation,
    private modal: ModalService,
    private fb: UntypedFormBuilder,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.formRejectedHomologation = this.initForm();
  }

  public initForm(): UntypedFormGroup {
    return this.fb.group({
      radio: ['', Validators.required],
      comment: [''],
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.formRejectedHomologation
      .get('radio')
      ?.valueChanges.subscribe((data) => {
        // console.log(data);
      });
  }
  changeFirstRadio(e) {
    this.showTextArea = true;
    this.formRejectedHomologation.controls['comment'].setValidators(
      Validators.required
    );
    // }
    // else {
    //   this.formRejectedHomologation.controls['comment'].clearValidators();
    //   this.formRejectedHomologation.controls[
    //     'comment'
    //   ].updateValueAndValidity();
    // }
  }

  changeSecondRadio(e) {
    this.showTextArea = false;
    this.formRejectedHomologation.controls['comment'].clearValidators();
    this.formRejectedHomologation.controls['comment'].updateValueAndValidity();
  }

  //rejected homologation
  public homologationRejected(): void {
    let radioValue: string = '';
    let info = this.dialogConfig.data as ListPendingRequest;
    // let comment = this.formRejectedHomologation.get('comment')?.value;
    let radio = this.formRejectedHomologation.get('radio')?.value;
    switch (radio) {
      case 'option1':
        radioValue =
          'No he recibido suficiente información y no me queda claro el proceso';
        break;
      case 'option2':
        radioValue =
          'Por el momento no me encuentro interesado en participar del proceso de calificación';
        break;
      case 'option3':
        radioValue = 'No estoy de acuerdo con el precio del servicio';
        break;
      case 'option4':
        radioValue = this.formRejectedHomologation.get('comment')?.value;
        break;
      default:
        break;
    }
    // console.log(info.HomologationId);

    this.pendingRequestOperation
      .deniedHomlogation$(info.HomologationId)
      .pipe(
        switchMap((_) => {
          return this.homologationOperation.commentHomologationRejected(
            radioValue,
            info.HomologationId
          );
        })
      )
      .subscribe((res) => {
        this.dialogRef.close();
        this.route.navigate(['./coface/home']);
        // if (res) {
        //   this.dialogRef.close('DENIED_HOMOLOGATION');
        // }
      });
  }
}

// function catchError(
//   arg0: (err: HttpErrorResponse) => any
// ): import('rxjs').OperatorFunction<Object, unknown> {
//   throw new Error('Function not implemented.');
// }
