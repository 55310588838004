import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { Observable, catchError, of, throwError } from 'rxjs';
import { ModalService } from '../services/dialog.service';
import { ErrorComponent } from '../../shared/components/dialogs/error/error.component';
import { ErrorOnlineFormComponent } from '../../shared/components/dialogs/error-online-form/error-online-form.component';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private router: Router,
    private dialog: ModalService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const tokenValue = this.session.Token;
    // console.log(req.headers.has('no-intercept'));
    // Obtener la urls que solo tenga /forms/ en el path

    if (req.url.includes('/forms/')) {
      console.log('FORMULARIOS', req.url);
    }

    if (tokenValue && !req.headers.has('no-intercept-token')) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${tokenValue}`),
      });
    }

    return next
      .handle(req)
      .pipe(catchError((error) => this.handlerError(error, req)));
  }

  handlerError(
    error: HttpErrorResponse,
    req: HttpRequest<any>
  ): Observable<any> {
    if (req.headers.has('no-intercept')) {
      return throwError(() => error);
    } else {
      // SI VIENE DE FORMULARIOS
      if (req.url.includes('/forms/')) {
        this.dialog.open<ErrorOnlineFormComponent>(
          error,
          ErrorOnlineFormComponent,
          {
            size: 'SMALL',
            disableClose: false,
          }
        );
      } else {
        this.dialog.open<ErrorComponent>(error, ErrorComponent, {
          size: 'SMALL',
          disableClose: false,
        });
      }
    }

    return throwError(() => error);
  }
}
