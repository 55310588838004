import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogContent } from 'primeng/dynamicdialog/dynamicdialogcontent';
import { ILanguages } from 'projects/ui-coface/src/app/auth/commons/interface/auth.interface';
import { DropdawnService } from 'projects/ui-coface/src/app/core/services/dropdawn.service';
import { valueQuizSelected } from 'projects/ui-coface/src/app/modules/customer/commons/validators/quizletter.validator';
import { SingleUploadPresenter } from 'projects/ui-coface/src/app/modules/customer/page/single-upload/single-upload.presenter';
import { SelectQuizLetterPresenter } from './select-quiz-letter.presenter';

@Component({
  selector: 'coface-select-quiz-letter',
  templateUrl: './select-quiz-letter.component.html',
  styleUrls: ['./select-quiz-letter.component.scss'],
})
export class SelectQuizLetterComponent implements OnInit {
  disabledBtn: boolean = true;
  title: string = 'Idioma';
  itemsLanguages: ILanguages[] = [];

  showFileLoaded: boolean = false;
  fileLoaded: string;

  formSelectQuizLetter: UntypedFormGroup;
  constructor(
    private _select: DropdawnService,
    private fb: UntypedFormBuilder,
    private ref: DynamicDialogRef,
    private dialogContent: DynamicDialogConfig,
    public singleUploadPresenter: SingleUploadPresenter
  ) {}

  ngOnInit(): void {
    this.itemsLanguages = this._select.dataListLanguages;    
    if (!this.singleUploadPresenter.Form.get('quizletters')?.value.length) {
      this.singleUploadPresenter.addQuizLetter(null);
      this.singleUploadPresenter.addQuizLetter(null);
    }

    // if (this.dialogContent.data.quizLetter?.length) {
      // this.dialogContent.data.quizLetter.forEach((element) => {
      //   this.singleUploadPresenter.addPatchValue(element);
      // // });
    // }
  }

  // addPatchValue(element) {
  //   let asd = this.formSelectQuizLetter.controls['quizletters'] as FormArray;
  //   asd.push(
  //     this.fb.group({
  //       language: element.language,
  //       quiz: element.quiz,
  //       flag: true,
  //     })
  //   );
  // }

  // public initForm(): void {
  //   this.formSelectQuizLetter = this.fb.group({
  //     quizletters: this.fb.array([]), //, [valueQuizSelected()]
  //   });
  // }

  // addQuizLetter(paramLanguage = null, paramQuiz: string | File = '') {
  //   const newQuizLetter = this.fb.group({
  //     language: [paramLanguage, Validators.required],
  //     quiz: [paramQuiz, Validators.required],
  //     flag: [false],
  //   });
  //   this.getQuizLetters.push(newQuizLetter);
  // }

  // deleteQuizLetter(quizLetterIndex: number) {
  //   this.getQuizLetters.removeAt(quizLetterIndex);
  // }

  customZindex(i: number) {
    let newid = Math.abs(i * 10 - 40);
    let select = document.querySelector(`#cofaceSelect-${i}`) as HTMLElement;
    let string = `z-${newid}`;
    select?.classList.add(string);
  }

  // get getQuizLetters() {
  //   return this.formSelectQuizLetter.controls['quizletters'] as FormArray;
  // }

  // public fileEvent(e: Event, quizLetter: AbstractControl) {
  //   quizLetter.get('flag')?.setValue(true);
  // }

  // public changeFileEvent(quizLetter: AbstractControl) {
  //   quizLetter.get('flag')?.setValue(false);
  //   quizLetter.get('quiz')?.reset('');
  //   console.log(this.formSelectQuizLetter?.value);
  // }

  uploadDocument() {  
    this.ref.close(this.singleUploadPresenter.Form.value.quizletters);
  }
}
