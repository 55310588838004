<div class="container-left">
  <div class="menu-left">
    <img
      class="w-32"
      src="assets/img/logo-coface-v2.png"
      alt="Logo Coface"
      rel="preload"
      as="image"
    />
  </div>
  <!-- {{ session.user | json }} -->
  <div class="text-center pt-8">
    <div class="container-left__logo bg-coface-primary">{{ letter }}</div>
    <span class="block text-xs pt-3">{{ rol | translate }}</span>
    <span class="block text-coface-primary">{{ fullName }}</span>
  </div>

  <div class="options pt-5">
    <ng-container *ngFor="let optionInfo of infoMenuJson; let i = index">
      <div
        [ngClass]="{ 'custom-arrow': optionInfo.submodules }"
        class="options__block flex cursor-pointer relative"
        (click)="
          onClickModule(optionInfo.id, optionInfo?.submodules?.length > 0)
        "
      >
        <img
          src="assets/img/menu/{{ optionInfo.image }}"
          [alt]="optionInfo.name"
          rel="preload"
          as="image"
          class="w-5"
        />
        <span class="text-coface-primary">
          {{ optionInfo.name  | translate}}</span
        >
        <img
          id="imgarrow-{{ optionInfo.id }}"
          *ngIf="optionInfo.submodules"
          src="assets/img/upload/arrow-blue.svg"
          [alt]="optionInfo.name"
          rel="preload"
          as="image"
          class="w-2 img-arrow"
        />
      </div>
      <div
        id="menuSubmodules-{{ optionInfo.id }}"
        class="bg-coface-secondary hide"
      >
        <div
          #subModule
          class="options__select text-center text-white hover:bg-emerald-700"
          [id]="options.id"
          *ngFor="let options of optionInfo.submodules"
        >
          <p (click)="onClickSubModule(options.id)" class="cursor-pointer">
            {{ options.name  | translate }}
          </p>
        </div>
      </div>
    </ng-container>
    <div
      class="options__block flex cursor-pointer relative border-0"
      (click)="signOut()"
      >
      <!-- [routerLink]="['./cliente/carga-individual']" -->
      <img
        src="assets/img/upload/sign-out.svg"
        alt="Imagen Cerrar sesión"
        rel="preload"
        as="image"
        class="w-5"
      />
      <span class="text-coface-primary"> {{ 'Cerrar sesión' | translate }}</span>
    </div>
  </div>
</div>
