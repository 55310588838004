<section class="flex flex-col gap-5 items-center justify-center py-5 relative">
  <button
    class="absolute top-2 -right-4 rounded-full w-6 h-6 text-center bg-gray-300 text-white cursor-pointer font-extrabold hover:bg-gray-400 outline-none"
    (click)="closeModal()"
  >
    X
  </button>
  <img
    class="text-center m-auto"
    src="assets/img/error.svg"
    alt="error-online-form"
  />

  <div class="flex flex-col leading-tight">
    <h2 class="text-3xl font-bold text-coface-primary">!Lo sentimos!</h2>
    <p class="text-sm text-gray-500">Ocurrió un error con el sistema.</p>
  </div>
  <span class="block w-[70%] h-[0.5px] bg-gray-200"></span>

  <span class="text-2xl text-coface-primary font-bold">Error</span>

  <span class="text-coface-primary font-bold text-lg text-center">
    {{ label }}
  </span>

  <p class="text-gray-500 text-center w-[70%] leading-tight">
    Señor usuario, por favor notifique a su gerente de cuenta este error.
    Gracias
  </p>
</section>
