import { CofaceBuilder } from 'projects/ui-coface/src/app/commons/model/coface.builder';
import {
  Homologation,
  IDetailsBandejaTrabajo,
  IResponseSupplier,
  UserEntity,
} from '../interface/provide.paginator';
import _moment from 'moment';
import { IDetail } from 'projects/ui-coface/src/app/commons/interface/paginator-table.interface';
import { MODULE_COLLAPSE } from 'projects/ui-coface/src/app/commons/enums/module-collapse.enum';
import { EUnlockModule } from 'projects/ui-coface/src/app/commons/enums/unlock-module.enum';

export class WalletProvider extends CofaceBuilder {
  private homologation: Homologation;
  public get Homologation(): Homologation {
    return this.homologation;
  }
  public set Homologation(value: Homologation) {
    this.homologation = value;
  }
  private userEntity: UserEntity;
  public get UserEntity(): UserEntity {
    return this.userEntity;
  }
  public set UserEntity(value: UserEntity) {
    this.userEntity = value;
  }

  private homologationId: number;
  public get HomologationId(): number {
    return this.homologationId;
  }
  public set HomologationId(value: number) {
    this.homologationId = value;
  }

  private legalNameSupplier: string;
  public get LegalNameSupplier(): string {
    return this.legalNameSupplier;
  }
  public set LegalNameSupplier(value: string) {
    this.legalNameSupplier = value;
  }
  private nationalIdSupplier: string;
  public get NationalIdSupplier(): string {
    return this.nationalIdSupplier;
  }
  public set NationalIdSupplier(value: string) {
    this.nationalIdSupplier = value;
  }
  private id: number;
  public get Id(): number {
    return this.id;
  }
  public set Id(value: number) {
    this.id = value;
  }

  private homologationConditionId: number;
  public get HomologationConditionId(): number {
    return this.homologationConditionId;
  }
  public set HomologationConditionId(value: number) {
    this.homologationConditionId = value;
  }

  private homologationCondition: string;
  public get HomologationCondition(): string {
    return this.homologationCondition;
  }
  public set HomologationCondition(value: string) {
    this.homologationCondition = value;
  }

  private nameCustomer: string;
  public get NameCustomer(): string {
    return this.nameCustomer;
  }
  public set NameCustomer(value: string) {
    this.nameCustomer = value;
  }

  private detail: IDetail<IDetailsBandejaTrabajo>;
  public get Detail(): IDetail<IDetailsBandejaTrabajo> {
    return this.detail;
  }
  public set Detail(value: IDetail<IDetailsBandejaTrabajo>) {
    this.detail = value;
  }

  private companyId: string;

  public get CompanyId(): string {
    return this.companyId;
  }

  public set CompanyId(value: string) {
    this.companyId = value;
  }

  private supplierId: string;

  public get SupplierId(): string {
    return this.supplierId;
  }

  public set SupplierId(value: string) {
    this.supplierId = value;
  }

  // private id: string;
  // public get Id(): string {
  //   return this.id;
  // }
  // public set Id(value: string) {
  //   this.id = value;
  // }

  static override backBuild(data: IResponseSupplier) {
    const content = new WalletProvider();
    if (data) {
      content.Id = data.id;
      content.HomologationId = data.homologation.id;
      content.LegalNameSupplier = data.homologation.company.legalName;
      content.NationalIdSupplier = data.homologation.company.nationalId;
      content.HomologationCondition = data.homologation.condition.name;
      content.HomologationConditionId = data.homologation.condition.id;
      content.NameCustomer = data.userEntity?.company?.legalName;
      // content.CreatedBy = data.createdBy;
      // content.IdCountries = data.idCountries;
      // content.IdThType = data.idThType;
      // content.IdThirds = data.idThirds;
      // content.ThDocumentsId = data.thDocumentsId;
      // content.ThEmail = data.thEmail;
      // content.ThLastName1 = data.thLastName1;
      // content.ThLastName2 = data.thLastName2;
      // content.ThName = data.thName;
      // content.ThPersonType = data.thPersonType;
      // content.ThirdsType = data.thirdsType;
      // content.UpdatedAt = data.updatedAt;
      // content.UpdatedBy = data.updatedBy;
      // content.FullNameProvider =
      //   data.thName + ' ' + data.thLastName1 + ' ' + data.thLastName2;
      content['viewMore'] = 'COLLAPSE';
      content['blockedState'] =
        data?.homologation?.supplier?.status === 'ACTIVE'
          ? 'Activo'
          : 'Bloqueado';
      content['blockedStatebtn'] = 'BLOCKED_STATE';
      content['nameToUnlock'] = data?.homologation?.supplier?.firstName;
      content['unlockModule'] = EUnlockModule.MANAGER_SUPPLIER_WALLET;
      content.CompanyId = data?.homologation?.company?.id.toString();
      content.SupplierId = data?.homologation?.supplier?.id.toString();
      content.Detail = {
        moduleType: MODULE_COLLAPSE.WALLET_PROVIDER,
        dataDetail: {
          activity: 'Código 151',
          validity: '-------',
          lastCalification: data.homologation.dateCertificateEnd ?? '-------',
          rejectionReason: data.homologation?.rejectionReason,
          dateProcessInit: data.homologation.dateCertificateInit
            ? _moment(data.homologation.dateCertificateInit).format(
                'DD/MM/YYYY'
              )
            : '------',
          dateProcessEnd: data.homologation.dateCertificateEnd
            ? _moment(data.homologation.dateCertificateEnd).format('DD/MM/YYYY')
            : '------',
          score: data.homologation.score ?? '-------',
          classification: data.homologation.classification ?? '-------',
          analystName: 'Analista Prueba MOCK' ?? '-------',

          // status: data.status ?? '-',
          // data.details.lastCalification ?? '-------',
          // economyActivitie: 'Código 151',
          // lateGradeQualification: '------------',
          // lastQualificationDate: '22/06/2021',
          // qualificationDate: '22/06/2021',
        },
      };

      // content.status = 'Sin Homologar';
    }

    return content;
  }
}
