import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	constructor(private session: SessionService, private route: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.session.validateSessionToken()) {
			return true;
		} else {
			this.route.navigate(['/acceso/login']);
			return false;
		}
	}
}
