import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-terms-and-conditions-colombia',
  templateUrl: './terms-and-conditions-colombia.component.html',
  styleUrls: ['./terms-and-conditions-colombia.component.scss'],
})
export class TermsAndConditionsColombiaComponent implements OnInit {
  authorizeOne: boolean = false;
  authorizeTwo: boolean = false;
  constructor(private dialogRef: DynamicDialogRef) {}

  ngOnInit(): void {}

  // changeFirstRadioGroup(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorizeOne = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorizeOne = false;
  //   }
  // }
  // changeSecondRadioGroup(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorizeTwo = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorizeTwo = false;
  //   }
  // }
  onAcceptTermCond() {
    this.dialogRef.close('COMPLETE');
  }
}
