import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'coface-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public text: string;
  @Input() public loading: boolean;
  @Input() public type: 'button' | 'submit' = 'button';
  @Input() public disabled: boolean;
  @Input() public showIcon: boolean;
  @Input() public iconName: string = 'SEARCH';
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';
  @Input() public color: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Output() public clicked = new EventEmitter<void>();

  constructor() {}

  public onClick(): void {
    this.clicked.emit();
  }

  public getButtonClass(): string {
    switch (this.color) {
      case 'primary':
        return 'bg-coface-primary text-white';
      case 'secondary':
        return 'bg-emerald-500 text-white';
      case 'tertiary':
        return 'bg-gray-50 text-gray-700';
    }
  }

  public getIconSVGByName(): string {
    return this.showIcon ? 'assets/icons/arrow-right.svg' : '';
  }
}
