import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coface-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss'],
})
export class CircleProgressbarComponent implements OnInit, AfterViewInit {
  // public 
  @Input() currentPercent: number = 0;

  private circleProgressBar: HTMLDivElement;
  constructor() {}

  ngOnInit(): void {}
	ngAfterViewInit(): void {
		this.circleProgressBar = document.querySelector('#circle-progressbar')!;
		// console.log(this.circleProgressBar);
	}
  public increment() {
    if (this.currentPercent < 100) {
      this.currentPercent = this.currentPercent += 10;
			this.circleProgressBar.style.animation = 'growProgressBar 3s 1 forwards';

    }
		this.circleProgressBar.style.animation = '';

  }
}
