import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from '../interface/response-coface.interface';
import { GenericRequestConfig } from '../model/request.config';
import { of } from 'rxjs';
import { IResponseTermsAnsConditionsUser } from '../../modules/homologacion/pending-request/commons/interface/pending-request.interface';

@Injectable({
  providedIn: 'root',
})
export class TermConditionEndpoint {
  constructor(private http: HttpClient) {}

  termConditionAccept$(req: GenericRequestConfig, formData) {
    const url = req.url;
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const params = req.params;
    return this.http.put<IResponseCoface<any>>(url, formData, {
      params: params,
      headers: headers,
    });
  }

  public getTermAndConditions$(url) {
    return this.http.get<IResponseCoface<IResponseTermsAnsConditionsUser>>(url);
  }
}
