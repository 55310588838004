import { Pipe, PipeTransform } from '@angular/core';
import { DropdawnService } from '../../core/services/dropdawn.service';
@Pipe({
  name: 'nameCountryById',
})
export class NameCountryPipe implements PipeTransform {
  constructor(private _select: DropdawnService) {}

  transform(value: string): string {
    const nameCountry = this._select.dataCountriesDashboard.find(
      (x) => x.Id === value
    )?.Name;

    return nameCountry || 'Empty';
  }
}
