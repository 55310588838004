<div class="single_upload">
  <div class="w-full">
    <form *ngIf="!form" [formGroup]="singleUploadPresenter.Form">
      <div class="mb-5">
        <div
          class="single_upload__title flex items-center justify-between relative pb-3 mb-5"
        >
          <p class="font-bold arrow-right">
            {{ "Editar Cliente" | translate }}
          </p>
        </div>
        <div>
          <input
            class="input mb-5"
            type="text"
            required
            autocomplete="off"
            placeholder="{{ 'Nombre de la Compañía' | translate }}"
            formControlName="denomination"
          />
        </div>

        <div class="flex justify-between">
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'National ID' | translate }}"
              formControlName="legalCode"
            />
          </div>

          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Nombre Comercial' | translate }}"
              formControlName="tradeName"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Dirección' | translate }}"
              formControlName="address"
            />
          </div>
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Teléfono' | translate }}"
              cofaceValidateSpace="true"
              cofaceOnlyNumber="true"
              formControlName="phoneNumber"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Correo electrónico' | translate }}"
              formControlName="email"
            />
          </div>
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Nombre de contacto' | translate }}"
              formControlName="firstname"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div style="width: 48%">
            <input
              class="input mb-5"
              type="text"
              required
              autocomplete="off"
              placeholder="{{ 'Apellido de contacto' | translate }}"
              formControlName="lastname"
            />
          </div>
          <div style="width: 48%">
            <!-- <div
              class="input flex justify-between relative cursor-pointer bg-gray-100 mb-5"
            >
              <input
                class="mb-5 text-start w-full"
                type="file"
                required
                id="fileUpload"
                autocomplete="off"
                #uploader
                formControlName="coverLetter"
                cofaceReactiveFile
                hidden
              />
        
              <button
                type="button"
                name="fileUpload"
                class="w-full"
                *ngIf="!showFileLoaded"
                (click)="uploader.click()"
              >
                <div class="flex justify-between items-center">
                  <p>{{ "Carta de presentación" | translate }}</p>
                  <img
                    class="block absolute w-6 right-6"
                    src="assets/img/upload/upload.svg"
                    alt="Logo Cliente"
                    rel="preload"
                    as="image"
                  />
                </div>
              </button>
              <div
                *ngIf="showFileLoaded"
                class="flex justify-between items-center w-full"
              >
                <div class="text-color">{{ fileLoaded }}</div>
        
              </div>
            </div> -->
            <coface-input-file-online-form
              placeholder="{{ 'Carta de presentación' | translate }}"
              formControlName="coverLetter"
              [shadow]="false"
            ></coface-input-file-online-form>
          </div>
        </div>

        <div class="flex justify-between">
          <div style="width: 48%">
            <div class="mb-5 relative z-30">
              <coface-select
                id-select="select-deliveryTerms"
                id-option="option-deliveryTerms"
                [items]="itemsDeliveryTerms"
                cofaceReactiveForm
                [valueTitle]="title3 | translate"
                formControlName="expirationTerm"
                [value]="singleUploadPresenter.expirationTerm.value"
              ></coface-select>
            </div>
          </div>
          <div style="width: 48%">
            <coface-input-file-online-form
              placeholder="{{ 'Cuestionario Específico' | translate }}"
              formControlName="quizLetter"
              [shadow]="false"
            ></coface-input-file-online-form>
          </div>
        </div>

        <div class="flex justify-between">
          <div style="width: 48%" class="mb-5 relative z-10">
            <coface-autocomplete-fix
              [suggestions]="filteredCountries"
              (searchText)="searchTextCountries($event)"
              placeholder="Ingresa el nombre del país"
              formControlName="countryCode"
              [value]="singleUploadPresenter.countryCode.value"
              cofaceReactiveForm
            ></coface-autocomplete-fix>
          </div>
          <!-- <div style="width: 48%"> -->
          <!-- <div class="mb-5 relative z-20">
              <coface-select
                id-select="select-deliveryTerms"
                id-option="option-deliveryTerms"
                [items]="itemsDeliveryTerms"
                cofaceReactiveForm
                [valueTitle]="title3"
                formControlName="expirationTerm"
                [value]="singleUploadPresenter.expirationTerm.value"
              ></coface-select>
            </div> -->
          <!-- </div> -->

          <div style="width: 48%">
            <div class="mb-5">
              <coface-select
                id-select="select-analyst"
                id-option="option-analyst"
                [items]="dataListAccountManager"
                cofaceReactiveForm
                [valueTitle]="title4 | translate"
                [value]="singleUploadPresenter.analystId.value"
                formControlName="analystId"
              ></coface-select>
            </div>
          </div>
        </div>
        <div class="flex justify-between">
          <div style="width: 48%">
            <coface-select
              id-select="select-language"
              id-option="option-language"
              cofaceReactiveForm
              [items]="itemsLanguages"
              formControlName="language"
              [value]="singleUploadPresenter.language.value"
              [valueTitle]="title5 | translate"
            ></coface-select>
          </div>
        </div>
      </div>

      <!-- {{singleUploadPresenter.Form.value | json}}
    {{singleUploadPresenter.range.valid}} -->
      <!-- {{ showModal }} -->
      <div class="range">
        <p class="font-bold border-t-2 border-b-2 py-2 bor-color">
          {{ "Definir Rangos de calificación" | translate }}
        </p>
        <div
          class="flex flex-wrap justify-between mb-5 py-7 border-b-2 bor-color"
        >
          <div
            formArrayName="range"
            *ngFor="
              let item of singleUploadPresenter.range.controls;
              let i = index
            "
            class="flex justify-between items-center"
            style="width: 47%"
          >
            <coface-range class="w-full" [formRangeA]="item" [index]="i">
            </coface-range>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <!-- <div style="width: 48%" *ngIf="session.user.country != 'CU'"></div> -->
          <!--  *ngIf="session.user.country == 'CU'" -->
          <div style="width: 48%" class="flex items-center justify-between">
            <span class="text-coface-primary font-bold">{{
              "Precio de venta del Reporte" | translate
            }}</span>
            <div class="flex items-center justify-between">
              <label class="mr-3" for="">$</label>
              <p class="input_range shadow-white-900 shadow-lg">
                <input
                  class="text-center"
                  type="text"
                  maxlength="8"
                  placeholder="XX.XX"
                  required
                  cofaceNumber
                  autocomplete="off"
                  title="{{
                    'Debe ser un número con hasta 2 decimales' | translate
                  }}."
                  formControlName="reportSalePrice"
                />
              </p>
            </div>
          </div>
          <div
            style="width: 48%"
            class="input bg-coface text-center relative cursor-pointer"
            (click)="onUpdateCustomer()"
            [class.spinner]="loaderBtnEvnt"
          >
            <!-- [disabled]="singleUploadPresenter.range.invalid" -->
            <!-- [ngClass]="{ gray: singleUploadPresenter.range.invalid }" -->
            <p class="text-white">{{ "Guardar" | translate }}</p>
            <button
              type="button"
              class="absolute bg-white rounded-3xl btn__create"
            >
              <img
                src="assets/img/upload/arrow-right.svg"
                alt="Limpiar formulario"
                rel="preload"
                as="image"
                class="w-4"
              />
            </button>
          </div>
        </div>
        <div
          *ngIf="messageError"
          class="w-3/5 my-0 mx-auto my-8 py-2 text-center messageerror"
        >
          Error al actualizar cliente
        </div>
      </div>
    </form>
  </div>
</div>

<!-- {{ singleUploadPresenter.Form.value | json}} -->
