import { Component, OnInit } from '@angular/core';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  fullName: string;
  today = new Date();
  constructor(public session: SessionService) {}

  ngOnInit(): void {
    this.filterName();
  }

  filterName() {
    this.fullName = this.session.user.fullName!;
  }
}
