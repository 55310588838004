import { SingleUploadPresenter } from './../../../../modules/customer/page/single-upload/single-upload.presenter';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coface-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  showPOPUP:boolean = true;
  constructor(
    public singleUploadPresent:SingleUploadPresenter,
  ) { 
    
  }

  ngOnInit(): void {
  }


  closeModal(){
    this.singleUploadPresent.resetForm();
    this.showPOPUP = false;
  }


}
