import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cofaceOnlyNumber]',
})
export class OnlyNumberDirective {
  // Allow decimal numbers. The \. is only allowed once to occur
  // private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
  private regex: RegExp = new RegExp(/^[0-9]*$/);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    let current: string = this.el.nativeElement.value;
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  /**Directiva que permite pegar valores numericos en el inputBox
   * Caso que se quiera pegar un valor numerico con texto, ignora los textos y solo copia
   * los valores numericos
   */
  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    /**Obtenemos el valor copiado */

    // const initialValue = e.clipboardData.getData('text');
    // this.el.nativeElement.value = initialValue.replace(this.regex, ' ');
    // if (initialValue !== this.el.nativeElement.value) {
    //   event.stopPropagation();
    // }
    e.preventDefault();
  }
}
