export enum RoleType {
  SUPERUSER = 'SUPERUSER',
  MANAGER = 'MANAGER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
  PRODUCTION_LEADER = 'PRODUCTION_LEADER',
  ANALYST = 'ANALYST',
}
// [
//   {
//       "id": 1,
//       "keyword": "SUPERUSER",
//       "name": "Superuser",
//       "description": "The root or superuser, the master piece, the one who can do everything."
//   },
//   {
//       "id": 2,
//       "keyword": "ADMINISTRATOR",
//       "name": "Administrator",
//       "description": "Admin system, as simple as that"
//   },
//   {
//       "id": 3,
//       "keyword": "COMMERCIAL",
//       "name": "Comercial",
//       "description": "Did somebody call him to do some business?"
//   },
//   {
//       "id": 4,
//       "keyword": "ANALYST",
//       "name": "Analista",
//       "description": null
//   },
//   {
//       "id": 5,
//       "keyword": "CUSTOMER",
//       "name": "Cliente",
//       "description": "Customer user role"
//   },
//   {
//       "id": 6,
//       "keyword": "SUPPLIER",
//       "name": "Proveedor",
//       "description": "Supplier user role"
//   },
//   {
//       "id": 7,
//       "keyword": "MANAGER",
//       "name": "Account Manager",
//       "description": "Account Manager user role"
//   },
//   {
//       "id": 8,
//       "keyword": "PRODUCTION_LEADER",
//       "name": "Lider Produccion",
//       "description": "Leader with permission to manage analysts across multiple countries."
//   }
// ],
export const ROLE_BY_ID = {
  '1': 'SUPERUSER',
  '2': 'ADMINISTRATOR',
  '3': 'COMERCIAL',
  '4': 'ANALYST',
  '5': 'CUSTOMER',
  '6': 'SUPPLIER',
  '7': 'MANAGER',
  '8': 'PRODUCTION_LEADER',
};
