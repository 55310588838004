<div class="terms-conditions-ecuador text-justify">
  <div class="cgray">
    <h1 class="text-2xl text-center text-coface-primary font-bold mt-4 mb-5">
      Términos y Condiciones PROVEEDOR PERSONA JURÍDICA
    </h1>
    <p class="text-sm mb-2 mt-4">PROVEEDOR PERSONA JURÍDICA</p>

    <div class="border-y-line text-sm cl-text-modal py-4">
      <form
        class="container-form-document-person"
        [formGroup]="formPersonLegal"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="inputName"
          [ngClass]="{
            'text-error':
              formPersonLegal.get('namePerson')?.invalid &&
              formPersonLegal.get('namePerson')?.touched
          }"
        >
          <label class="ml-1" for="namePerson"
            >Ingrese el nombre del representante Legal:</label
          >
          <br />
          <input
            class="inputDocumentPersonText"
            type="text"
            placeholder="Representante legal"
            id="namePerson"
            formControlName="namePerson"
          />
          <br />
          <span
            *ngIf="
              formPersonLegal.get('namePerson')?.invalid &&
              formPersonLegal.get('namePerson')?.touched
            "
            class="text-error"
          >
            Nombre no Valido
          </span>
        </div>

        <div
          class="inputFile"
          [ngClass]="{
            'text-error':
              formPersonLegal.get('fileDocumentIdentity')?.invalid &&
              formPersonLegal.get('fileDocumentIdentity')?.touched
          }"
        >
          <label class="ml-1" for="fileDocumentPerson"
            >Documento que valida la Representación legal</label
          >
          <br />
          <input
            (change)="onFileChange($event)"
            class="inputDocumentPerson"
            type="file"
            hidden
            id="fileDocumentPerson"
            formControlName="fileDocumentIdentity"
            #fileDocumentPerson
          />
          <div
            (click)="onclickUploadFile()"
            class="bg-coface-secondary text-start relative rounded-2xl text-white py-3 cursor-pointer text-white px-2 w-[198px] relative"
          >
            Cargar Documento
            <img
              class="cursor-pointer"
              src="../../../../../../assets/img/upload/uploadIcon.svg "
              alt="iconIdentity"
              width="15"
              height="15"
              class="absolute top-3 right-3"
            />
          </div>
          <br />
          <span
            *ngIf="
              formPersonLegal.get('fileDocumentIdentity')?.invalid &&
              formPersonLegal.get('fileDocumentIdentity')?.touched
            "
            class="text-error"
          >
            Inserte un documento de identidad.
          </span>
        </div>

        <div class="labelNameDocument">
          <ng-container
            *ngIf="formPersonLegal.get('fileDocumentIdentity')?.valid"
          >
            <img
              class="block w-4 mr-1 display-inline"
              src="assets/img/upload/{{ buildImage() }}.png"
              alt="Logo archivo"
              rel="preload"
              as="image"
            />
            <span>
              {{ nameFile }}
            </span>
            <button (click)="deleteFile()">
              <img
                class="block w-5"
                src="assets/img/cerrar-modal.svg"
                alt="Eliminar archivo"
                rel="preload"
                as="image"
              />
            </button>
          </ng-container>
        </div>
      </form>
    </div>

    <!-- content text -->
    <div>
      <ng-container *ngFor="let paragraph of dataTerms.data">
        <p [innerHTML]="paragraph" class="text-sm my-2"></p>
      </ng-container>
    </div>

    <button
      [disabled]="formPersonLegal.invalid"
      (click)="onAcceptTermCond()"
      class="btn__passTerm text-white bg-coface-primary mt-4 block mx-auto"
    >
      Aceptar
    </button>
  </div>
</div>
