import { CofaceBuilder } from "./coface.builder";


export class Paginator<T> extends CofaceBuilder {

	private content: Array<T>;
	private last: boolean;
	private totalPages: number;
	private pageable: any;
	private sort: any;
	private empty: any;
	private totalElements: number;
	private size: number;
	private page: number;
	private first: boolean;
	private numberOfElements: number;

	public set Content(x: Array<T>) {
		this.content = new Array<T>();
		this.content.push(...x);
	}
	public get Content(): Array<T> {
		return this.content;
	}
	public set Last(x: boolean) {
		this.last = x;
	}
	public get Last(): boolean {
		return this.last;
	}

	public set TotalPages(x: number) {
		this.totalPages = x;
	}
	public get TotalPages(): number {
		return this.totalPages;
	}

	public set Pageable(x: any) {
		this.pageable = x;
	}
	public get Pageable(): any {
		return this.pageable;
	}

	public set Sort(x: any) {
		this.sort = x;
	}
	public get Sort(): any {
		return this.sort;
	}


	public set Empty(x: any) {
		this.empty = x;
	}
	public get Empty(): any {
		return this.empty;
	}


	public set TotalElements(x: number) {
		this.totalElements = x;
	}
	public get TotalElements(): number {
		return this.totalElements;
	}


	public set Size(x: number) {
		this.size = x;
	}
	public get Size(): number {
		return this.size;
	}


	public set Page(x: number) {
		this.page = x;
	}
	public get Page(): number {
		return this.page;
	}


	public set First(x: boolean) {
		this.first = x;
	}
	public get First(): boolean {
		return this.first;
	}


	public set NumberOfElements(x: number) {
		this.numberOfElements = x;
	}
	public get NumberOfElements(): number {
		return this.numberOfElements;
	}


	static override backBuild<T>(x: any): Paginator<T> {

		const paginatorModel = new Paginator<T>();
		paginatorModel.Last = x.last;
		paginatorModel.TotalPages = x.totalPages;
		paginatorModel.TotalElements = x.totalElements;
		paginatorModel.Size = x.size;
		paginatorModel.Page = x.number;
		paginatorModel.First = x.first;
		paginatorModel.NumberOfElements = x.numberOfElements;
		paginatorModel.Pageable = x.pageable;

		return paginatorModel;
	}

	constructor() {
		super();
		this.Content = new Array<T>();
		this.TotalElements = 0;
	}

}
