import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportType } from 'projects/ui-coface/src/app/commons/enums/type-report.enum';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';

@Component({
  selector: 'coface-confirm-type-report',
  templateUrl: './confirm-type-report.component.html',
  styleUrl: './confirm-type-report.component.scss',
  providers: [HomologacionOperation],
})
export class ConfirmTypeReportComponent {
  public isLoadingSendAnalyst: boolean;
  public homologationIdParam: number;
  public reportType: FormControl<string>;

  public items = [
    {
      id: ReportType.SUPPLIER_REPORT_LATAM,
      name: 'Support report Latam',
    },
    {
      id: ReportType.SUPPLIER_REPORT_A,
      name: 'Support report A',
    },
    {
      id: ReportType.SUPPLIER_REPORT_B,
      name: 'Support report B',
    },
  ];

  ngOnInit(): void {
    this.homologationIdParam = this.config.data.homologationId;
  }

  public constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private homologacionOperation: HomologacionOperation,
    private _route: Router
  ) {
    this.reportType = new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    });
  }

  closeModal() {
    this.ref.close();
  }

  toSendAnalystWithReport() {
    this.isLoadingSendAnalyst = true;
    this.homologacionOperation
      .toSendAnalystFromManager(this.homologationIdParam, this.reportType.value)
      .subscribe((_) => {
        this.isLoadingSendAnalyst = false;
        this.ref.close();
        this._route.navigate(['./coface/home']);
      })
      .add(() => {
        this.isLoadingSendAnalyst = false;
      });
  }
}
