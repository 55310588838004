<div class="modal_success">
  <div class="text-coface-primary text-center font-semibold">
    {{ message | translate }}
  </div>
  <!-- <button
    class="btn__passWayPay w-full text-white bg-coface-primary mt-5 block mx-auto text-sm"
    (click)="closePOPUP()"
  >
    {{ "Aceptar" | translate }}
  </button> -->
  <div class="mt-5 w-[80%] mx-auto flex justify-center items-center">
    <coface-button
      text="{{ 'Aceptar' | translate }}"
      color="primary"
      (clicked)="closePOPUP()"
      [loading]="false"
    ></coface-button>
  </div>
</div>
