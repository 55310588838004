import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateUserStaffOperation } from 'projects/ui-coface/src/app/modules/user-management/commons/operations/create-user.operations';

@Component({
  selector: 'coface-confirm-user',

  templateUrl: './confirm-user.component.html',
  styleUrl: './confirm-user.component.scss',
})
export class ConfirmUserComponent implements OnInit {
  public loading: boolean;
  public fullName: string;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private userOperation: CreateUserStaffOperation
  ) {}
  ngOnInit(): void {
    this.fullName = this.config.data.fullName;
  }

  public onDelete() {
    this.loading = true;
    this.userOperation.deleteUser(this.config.data.id, this.fullName).subscribe(
      () => {
        this.loading = false;
        this.ref.close('SUCCESS');
      },
      () => {
        this.loading = false;
        this.ref.close('CANCEL');
      }
    );
  }

  public onCancel() {
    this.ref.close('CANCEL');
  }
}
