import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { FilterModel } from 'projects/ui-coface/src/app/commons/model/filter.model';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HomologacionOperation } from '../../../homologacion/commons/homologacion-operation';
import { WalletProviderEndpoint } from '../endpoints/provider.endpoint';
import { IProviderFilter } from '../model/provider-list';
import { WalletProvider } from '../model/provider.paginator';
import { ISupplierFilterInput } from '../interface/supplier-filter.interface';
import { WalletProviderNew } from '../model/provider.paginator-new';
import { SuccessfulDynamicComponent } from 'projects/ui-coface/src/app/shared/components/dialogs/successful-dynamic/successful-dynamic.component';
import { ErrorComponent } from 'projects/ui-coface/src/app/shared/components/dialogs/error/error.component';

@Injectable({
  providedIn: 'root',
})
export class WalletProviderOperation {
  constructor(
    private providerEndpoint: WalletProviderEndpoint,
    private dialog: ModalService,
    private session: SessionService,
    private homologationOpe: HomologacionOperation
  ) {}

  private getInfoBySessionUser() {
    return this.homologationOpe.getInfoByUserDetail(this.session.user.id!);
  }

  // @deprecated
  public getWalletProvider$(filters: FilterModel<IProviderFilter>) {
    if (this.session.user.role?.keyword === 'ADMIN') {
      return this.getInfoBySessionUser().pipe(
        switchMap((data) => {
          const body = this.builderRequest(filters, data.data.office.id);
          return this.providerEndpoint.getWalletProvider$(body).pipe(
            map((res) => {
              const content = res.data['content'];
              let paginator = Paginator.backBuild<WalletProvider>(res.data);
              if (content?.length) {
                const row = content.map((val) => WalletProvider.backBuild(val));
                paginator.Content = row;
              }
              return paginator;
            })
          );
        })
      );
    } else {
      const body = this.builderRequest(filters, null);
      return this.providerEndpoint.getWalletProvider$(body).pipe(
        map((res) => {
          const content = res.data['content'];
          let paginator = Paginator.backBuild<WalletProvider>(res.data);
          if (content?.length) {
            const row = content.map((val) => WalletProvider.backBuild(val));
            paginator.Content = row;
          }
          return paginator;
        })
      );
    }
  }

  public deleteWalletProvider$(homologationId: number) {
    return this.providerEndpoint.deleteWalletProvider$(homologationId).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          this.dialog.open<ErrorComponent>(error, ErrorComponent, {
            size: 'SMALL_MEDIUM',
            disableClose: false,
          });
        } else {
          this.dialog.open(
            {
              title: 'No es posible eliminar al proveedor',
              description: error.error.error.message ?? ''
            },
            SuccessfulDynamicComponent,
            {
              size: 'SMALL',
              disableClose: true,
              closable: 'MODULE_BY_OUTSIDE',
            }
          );
        }

        return throwError(error);
      })
    );
  }

  public getWalletProviderNew$(filters: FilterModel<IProviderFilter>) {
    const body = this.builderRequestNew(filters);
    return this.providerEndpoint.getWalletProviderNew$(body).pipe(
      map((res) => {
        const content = res.data['content'];
        let paginator = Paginator.backBuild<WalletProviderNew>(res.data);
        if (content?.length) {
          const row = content.map((val) => WalletProviderNew.backBuild(val));
          paginator.Content = row;
        }
        return paginator;
      })
    );
  }

  private builderRequest(filter: FilterModel<IProviderFilter>, officeId) {
    const buildParam: Partial<IProviderFilter> = {
      supplierLegalId: filter.FilterValue?.supplierLegalId,
      supplierName: filter.FilterValue?.supplierName,
      approvalStatus: filter.FilterValue?.approvalStatus,
      countryId: filter.FilterValue?.countryId,
      customerLegalId: filter.FilterValue?.customerLegalId,
      customerName: filter.FilterValue?.customerName,
      customerSelectedId: filter.FilterValue?.customerSelectedId,
      since: filter.FilterValue?.since,
      until: filter.FilterValue?.until,
      activityEconomy: filter.FilterValue?.activityEconomy,
      expirySince: filter.FilterValue?.expirySince,
      expiryUntil: filter.FilterValue?.expiryUntil,
      validityPeriod: filter.FilterValue?.validityPeriod,

      // since: filter.FilterValue?.since ? moment(filter.FilterValue?.since).format('DD/MM/YYYY') : '',
      // until: filter.FilterValue?.until ? moment(filter.FilterValue?.until).format('DD/MM/YYYY') : '',
    };

    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/customers/backoffice/suppliers`;
    req.params = new HttpParams();
    if (officeId) {
      req.params = req.params.set('officeId', officeId);
    } else if (this.session.user.role?.keyword === 'MANAGER') {
      req.params = req.params.set('managerId', this.session.user.id!);
    } else {
      req.params = req.params.set('userId', this.session.user.id!);
    }
    req.params = req.params
      .set('page', filter.Page - 1)
      .set('size', filter.Size);
    for (const [key, value] of Object.entries(buildParam)) {
      if (value) {
        req.params = req.params.set(key, value);
      }
    }
    return req;
  }

  private builderRequestNew(filter: FilterModel<IProviderFilter>) {
    const buildParam: Partial<ISupplierFilterInput> = {
      supplierId: filter.FilterValue?.supplierLegalId,
      supplierLegalName: filter.FilterValue?.supplierName,
      conditionId: filter.FilterValue?.approvalStatus,
      customerCommercialName: filter.FilterValue?.customerName,
      customerLegalName: filter.FilterValue?.legalCustomerName,
      economicActivityId: filter.FilterValue?.activityEconomy,
      validity: filter.FilterValue?.validityPeriod?.toString(),
      customerId: filter.FilterValue?.customerLegalId,
      dateSince: filter.FilterValue?.since,
      dateTo: filter.FilterValue?.until,
      expireDateSince: filter.FilterValue?.expirySince,
      expireDateTo: filter.FilterValue?.expiryUntil,
      classification: filter.FilterValue?.classification,
      nationalId: null!,
      countryId: filter.FilterValue?.countryId,
      userId: filter.FilterValue.customerSelectedId,
      isPaymentVerified: filter.FilterValue?.isPaymentVerified,
    };

    const req = new GenericRequestConfig();
    req.url = `${environment.urlBaseEndpointV2}/customers/search-my-supplier`;
    req.params = new HttpParams();
    req.params = req.params
      .set('page', filter.Page - 1)
      .set('size', filter.Size);

    for (const [key, value] of Object.entries(buildParam)) {
      if (value !== null && value !== undefined && value !== '') {
        req.params = req.params.set(key, value);
      }
    }
    return req;
  }
}
