import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent implements OnInit {
  constructor(private refDialog: DynamicDialogRef) {}

  ngOnInit(): void {
    // console.log('Timeout modal');
  }

  public onAccept(): void {
    this.refDialog.close('LOGOUT');
  }
}
