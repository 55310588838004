<div class="terms-conditions-brazil text-justify">
  <div class="cgray">
    <h1 class="text-2xl text-center cgray font-bold">
      FORNECEDOR PESSOA JURÍDICA
    </h1>
    <p class="text-sm my-2">
      O representante do Fornecedor declara sob protesto que as informações
      fornecidas são verdadeiras e que tem os poderes necessários para aceitar
      os termos e condições aplicáveis ​​à utilização da plataforma web para a
      emissão do relatório de qualificação do fornecedor (Relatório do
      Fornecedor), declarando que compreende o escopo do mesmo.
    </p>

    <p class="text-sm my-2">
      Coface, refere-se às empresas que fazem parte do grupo empresarial Coface,
      podendo ser sucursais, subsidiárias, filiais e/ou filiadas; As referidas
      empresas terão acesso à informação que o Fornecedor faculte, autorizando
      expressamente neste ato a utilização, tratamento, armazenamento e
      transmissão, nacional e internacional, da referida informação a qualquer
      uma das empresas (doravante “Coface”).
    </p>
    <p class="text-sm my-2">
      O representante do Fornecedor autoriza expressa e irrevogavelmente a
      Coface a consultar, validar, rever, solicitar, processar e divulgar toda a
      informação referente ao comportamento creditício, financeiro, comercial e
      de serviços; bem como a consulta nos centros de informação e risco
      autorizados da empresa que representa, de forma a avaliar a experiência e
      situação de crédito, através da emisão do relatório de qualificação do
      fornecedor (Relatório do Fornecedor).
    </p>
    <p class="text-sm my-2">
      Adicionalmente, autoriza ​​expressamente a Coface a proceder com o
      tratamento das informações, documentos e dados pessoais fornecidos para o
      serviço solicitado e, se necessário, a sua transferência nacional e
      internacional, para todos os produtos e serviços oferecidos pela Coface.
      Para tal, o Fornecedor entregará à Coface a informação e documentação que
      lhe for solicitada, através da utilização da plataforma web.
    </p>
    <p class="text-sm my-2">
      Os dados fornecidos serão tratados pela Coface na qualidade de responsável
      ou pelos responsáveis ​​por esta, designados e de acordo com a política de
      privacidade da Coface e regulamentação em vigor.
    </p>
    <p class="text-sm my-2">
      Em virtude do parágrafo anterior, caso seja exigido, o Fornecedor
      obriga-se a pagar à Coface o valor correspondente à emissão do relatório
      de qualificação do fornecedor (Relatório do Fornecedor), de acordo com a
      modalidade de reporte selecionada pelo Fornecedor.
    </p>
    <p class="text-sm my-2">
      Ao assinar estes termos e condições, fica estabelecido que o Fornecedor,
      através do seu representante, aceita as Políticas de Tratamento de Dados
      Pessoais da Coface. O Fornecedor entende que pode exercer seus direitos a
      qualquer momento de atualização, retificação, complemento, especificação
      ou eliminação dos seus dados pessoais, através do envio do seu pedido para
      o seguinte email:
      <a
        href=" https://www.coface.com.pe/Acerca-de-Coface/Grupo-Coface/Coface-Lider-Mundial"
        target="_blank"
      >
        atencioncliente.lar&#64;coface.com .
      </a>
    </p>
    <p class="text-sm my-2">
      Neste acto, declaro expressamente que autorizo ​​a Coface para que os
      dados fornecidos sejam utilizados com a finalidade de me oferecer
      produtos, enviar informações ou boletins de notícias, enviar-me
      informações adicionais sobre o site e outras finalidades estreitamente
      associadas e necessárias ao cumprimento do marketing, publicitárias,
      estatísticas e administrativas, de acordo com a sua política de tratamento
      de dados disponível para consulta no site da Coface.
    </p>
    <p class="text-sm my-2">As Partes declaram e garantem que:</p>
    <p class="text-sm my-2">
      i. não fazem nem aceitam, nem farão ou aceitarão pagamentos, promessas de
      pagamento; nem fornecem (ou prometem fornecer) quaisquer vantagens
      financeiras ou presentes; nem se envolvem em qualquer atividade que
      represente suborno público ou comercial;
    </p>
    <p class="text-sm my-2">
      i. não fazem nem aceitam, nem farão ou aceitarão pagamentos, promessas de
      pagamento; nem fornecem (ou prometem fornecer) quaisquer vantagens
      financeiras ou presentes; nem se envolvem em qualquer atividade que
      represente suborno público ou comercial;
    </p>
    <p class="text-sm my-2">
      ii. zelarão para que seus diretores, executivos e colaboradores cumpram as
      obrigações acima mencionadas;
    </p>
    <p class="text-sm my-2">
      Em caso de descumprimento das disposições acima por uma Parte ou seus
      diretores, executivos ou colaboradores, a Parte afetada terá o direito,
      mediante notificação escrita imediata e sem qualquer responsabilidade ou
      pagamento de qualquer natureza:
    </p>
    <p class="text-sm my-2">
      a) suspender a execução do Contrato e qualquer obrigação decorrente ou
      relacionada a tais contratos, em caso de investigação instaurada por
      autoridade judicial, administrativa ou reguladora,
    </p>
    <p class="text-sm my-2">
      b) rescindir o Contrato em caso de condenação judicial ou sanção de
      autoridade administrativa ou reguladora.
    </p>
    <p class="text-sm my-2">
      As obrigações da Coface em termos de cumprimento, de acordo com as
      disposições da presente relação jurídica, serão suspensas caso possam
      causar ou expor a Coface ou qualquer membro do seu Grupo a qualquer tipo
      de sanção ou penalidade (incluindo sanções extraterritoriais) que possam
      ser tenham origem ou estejam vinculados a acordos ou sanções econômicas
      e/ou administrativas das Nações Unidas, leis ou regulamentos da União
      Européia ou de qualquer Estado da União Européia, do Reino Unido ou de
      qualquer país ou jurisdição da América Latina; independentemente de tais
      sanções existirem no início desta relação juridica ou se foram
      implementadas durante a vigência desta relação jurídica.
    </p>
    <button
      (click)="onAcceptTermCond()"
      class="btn__passTerm w-full text-white bg-coface-primary mt-4 block mx-auto"
    >
      Aceptar
    </button>
  </div>
</div>
