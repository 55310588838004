import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { RouteAnalyze } from '../enums/path.enum';

@Injectable()
export class RedirectService {
  public routeNavigate: string;
  public queryParams = {};
  constructor(private session: SessionService) {}

  public navigateToModuleByQueryParams(queryParams: any) {
    const search = this.getAllUrlParams(queryParams);
    // console.log(search, search['homologationid']);

    const path = RouteAnalyze.get(search['redirect']) || null;
    if (search['homologationid']) {
      this.routeNavigate =
        path?.find((x) => x.role === this.session.user.role?.keyword)?.path ||
        'coface/home';

      this.queryParams = { homologationId: search['homologationid'] };
    } else {
      this.routeNavigate =
        path?.find((x) => x.role === this.session.user.role?.keyword)?.path ||
        'coface/home';
    }
  }
  private getAllUrlParams(url) {
    const obj = {};
    const queryString = url
      ? url.split('?')[1]
      : window.location.search.slice(1);
    const parameters = queryString ? queryString.split('&') : [];
    parameters.forEach((parameter: string) => {
      const items = parameter.split('=');
      obj[items[0].toLowerCase()] = decodeURIComponent(items[1]);
    });
    return obj;
  }
}
