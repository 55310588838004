<section class="flex flex-col items-center">
  <h1
    class="text-coface-primary font-bold text-center w-full left-0 p-3 pt-7 text-2xl mb-2"
  >
    ¡El Cliente se encuentra Bloqueado!
  </h1>
  <h2 class="text-2xl font-semibold text-gray-600 mb-2">
    {{ dataModal.nameToUnlock }}
  </h2>
  <p class="text-gray-500 mb-2">¿Deseas desbloquearlo?</p>
  <button
    class="w-1/2 text-white bg-coface-primary hover:opacity-75 max-w-sm font-semibold"
    (click)="onUnlock()"
  >
    Si, quiero desbloquearlo
  </button>
  <button
    class="w-1/2 text-white bg-coface-tertiary-bold hover:opacity-75 max-w-sm font-semibold"
    (click)="onCancel()"
  >
    Cancelar
  </button>
</section>
