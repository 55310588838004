import { Component, Input,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'coface-title-online-form',
  templateUrl: './title-online-form.component.html',
  styleUrl: './title-online-form.component.scss'
})
export class TitleOnlineFormComponent {

  @Input() title: string = '';
}
