import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
@NgModule({
  imports: [
    CommonModule, 
    ProgressBarModule, 
    BadgeModule, 
    DropdownModule
  ],
  exports: [
    ProgressBarModule, 
    BadgeModule, 
    DropdownModule
  ],
})
export class PrimeNgModule {}
