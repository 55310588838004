import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-document-confirm-dialog',
  templateUrl: './confirm-document-dialog.component.html',
  styleUrls: ['./confirm-document-dialog.component.scss'],
})
export class ConfirmDocumentDialogComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}
  public onAccept() {
    this.ref.close('ACCEPT');
  }
  public onCancel() {
    this.ref.close('CANCEL');
  }
}
