<button
  class="rounded-3xl h-full w-full tracking-normal relative px-3 shadow-lg disabled:opacity-50 flex justify-center items-center py-3 hover:opacity-90 outline-none box-border"
  [ngClass]="getButtonClass()"
  [type]="type"
  [disabled]="loading || disabled"
  (click)="onClick()"
>
  <div
    class="flex w-full items-center justify-center gap-5 relative whitespace-nowrap overflow-hidden overflow-ellipsis"
    [ngClass]="{ 'text-sm': size === 'small' }"
  >
    <p>{{ text ?? "DEFAULT" }}</p>

    <ng-container [ngSwitch]="iconName" *ngIf="showIcon">
      <div class="absolute mr-2 right-0 bg-inherit" *ngSwitchCase="'SEARCH'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.67924 15.0515V15.8645L4.53345 21L3 19.4666L8.13551 14.3208H8.94854L9.23671 14.0429C8.22813 12.8696 7.62093 11.3465 7.62093 9.68954C7.62093 5.99485 10.6158 3 14.3105 3C18.0051 3 21 5.99485 21 9.68954C21 13.3842 18.0051 16.3791 14.3105 16.3791C12.6535 16.3791 11.1304 15.7719 9.95712 14.7633L9.67924 15.0515ZM14.3105 5.05832C11.7479 5.05832 9.67924 7.12693 9.67924 9.68954C9.67924 12.2521 11.7479 14.3208 14.3105 14.3208C16.8731 14.3208 18.9417 12.2521 18.9417 9.68954C18.9417 7.12693 16.8731 5.05832 14.3105 5.05832Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div class="absolute mr-2 right-0 bg-inherit" *ngSwitchCase="'DEFAULT'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <div *ngSwitchDefault>qwe</div>
    </ng-container>
  </div>
  <div
    *ngIf="loading"
    class="animate-spin h-5 w-5 text-white ml-2 absolute top-0 left-0 right-0 bottom-0 m-auto"
    style="margin: auto"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
</button>
