import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomologationGuard } from './commons/guard/homologation.guard';
import { AuthGuard } from './commons/guard/session.guard';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { ResetPasswordGuard } from './commons/guard/reset-password.guard';
import { onlineFormGuard } from './commons/guard/online-form.guard';

const routes: Routes = [
  {
    path: 'acceso',
    loadChildren: () =>
      import('./auth/auth.module').then((ms) => ms.AuthModule),
  },
  {
    path: 'coface',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((ms) => ms.HomeModule),
      },
      {
        path: 'clientes',
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (ms) => ms.CustomerModule
          ),
      },
      {
        path: 'proveedores',
        loadChildren: () =>
          import('./modules/provider/provider.module').then(
            (ms) => ms.ProviderModule
          ),
      },
      {
        path: 'proveedores/clientes',
        loadChildren: () =>
          import('./modules/provider-customer/provider-customer.module').then(
            (m) => m.ProviderByCustomerModule
          ),
      },
      {
        path: 'administracion-usuarios',
        loadChildren: () =>
          import('./modules/user-management/users-management.module').then(
            (ms) => ms.UsersManagementModule
          ),
      },

      {
        path: 'cuestionario-base',
        loadChildren: () =>
          import('./modules/basic-services/basic-services.module').then(
            (ms) => ms.BasicServicesModule
          ),
      },
      {
        path: 'homologacion',
        loadChildren: () =>
          import('./modules/homologacion/homologacion.module').then(
            (ms) => ms.HomologacionModule
          ),
      },

      {
        path: 'formularios-enlinea',
        canActivate: [onlineFormGuard],
        loadChildren: () =>
          import('./modules/online-forms/online-forms.module').then(
            (ms) => ms.OnlineFormsModule
          ),
      },

      {
        path: 'consulta-formulario',
        canActivate: [HomologationGuard],
        loadChildren: () =>
          import(
            './modules/consulta-formulario/consulta-formulario.module'
          ).then((ms) => ms.ConsultaFormularioModule),
      },
      {
        path: 'mensajes',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (ms) => ms.NotificationModule
          ),
      },
      {
        path: 'configuracion',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (ms) => ms.SettingsModule
          ),
      },
      {
        path: 'contrasenas',
        canActivate: [ResetPasswordGuard],
        loadChildren: () =>
          import('./modules/passwords/passwords.module').then(
            (ms) => ms.PasswordsModule
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: 'acceso',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
