<div class="content-error p-4">
  <img
    class="text-center m-auto"
    src="assets/img/error.svg"
    alt="error-image"
  />

  <h1 class="font-bold text-xl cl-primary my-5 text-center">
    {{ " ¡Lo sentimos! Ocurrio un error con el sistema." | uppercase }}
  </h1>
  <div class="content-error__detail py-5 px-4">
    <ng-container *ngIf="dataError?.error; else notData">
      <div class="flex justify-between my-2">
        <span class="font-bold cl-primary">Status:</span>
        &nbsp; &nbsp; &nbsp;
        <p class="font-semibold">
          {{ dataError?.status }} -
          {{ dataError?.error?.error?.debug ?? dataError?.error?.error }}
        </p>
      </div>
      <div class="flex justify-between my-2">
        <span class="font-bold cl-primary">URL:</span>
        &nbsp; &nbsp; &nbsp;
        <p class="text-sm">{{ dataError?.url }}</p>
      </div>
      <div class="flex justify-between">
        <span class="font-bold cl-primary">Mensaje:</span>
        &nbsp; &nbsp; &nbsp;
        <p class="text-sm">
          {{
            (dataError?.error?.error?.message ?? dataError?.error?.message) ||
              dataError?.error.detail
          }}
        </p>
      </div>
    </ng-container>
    <ng-template #notData>
      <div class="flex justify-between my-2">
        <span class="font-bold cl-primary">Status:</span>
        &nbsp; &nbsp; &nbsp;
        <p class="font-semibold">
          {{ dataError?.status }} -
          {{ dataError?.error?.error?.debug ?? dataError?.error?.error }}
        </p>
      </div>
      <div class="flex justify-between my-2">
        <span class="font-bold cl-primary">URL:</span>
        &nbsp; &nbsp; &nbsp;
        <p class="text-sm">{{ dataError?.url }}</p>
      </div>
      <div class="font-bold cl-primary">No hay mas detalles del error</div>
    </ng-template>
  </div>
  <button class="close-btn" (click)="closeModal()">CERRAR</button>
</div>
