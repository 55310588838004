<div class="relative z-10">
  <div
    class="bg-coface-primary text-white py-3 px-5 rounded-3xl w-auto inline-block"
  >
    <p class="text-lg font-bold text-center whitespace-nowrap">{{ title }}</p>
  </div>
  <div
    class="absolute top-6 w-full border-t border-gray-400 border-1 -z-10 opacity-70"
  ></div>
</div>
