import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { IResponseCoface } from '../interface/response-coface.interface';
import { IAnalyst } from '../interface/analyst.interface';

@Injectable({
  providedIn: 'root',
})
export class AssignAnalystHttp {
  constructor(private http: HttpClient) {}

  public getAnalyst$() {
    return this.http.get<IResponseCoface<Array<IAnalyst>>>(
      `${environment.urlBaseEndpointV2}/participant/active-analysts`
    );
  }

  public assignAnalyst$(homologationId: number, analystId: number) {
    const params = new HttpParams()
      .set('operationId', homologationId)
      .set('analystId', analystId);
    return this.http.post<IResponseCoface<any>>(
      `${environment.urlBaseEndpointV2}/participant/assign-analyst`,
      {},
      { params: params }
    );
  }
}
