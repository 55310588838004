import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

export class CofacePresenter<T> {
  protected form: FormGroup;
  protected unsubscribe: Subject<void>;

  constructor() {}

  public get Form() {
    return this.form;
  }

  public get Value() {
    return this.form.value as T;
  }

  public get Valid(): boolean {
    return this.form.valid;
  }

  public get Disabled(): boolean {
    return this.form.disabled;
  }
  public get Invalid(): boolean {
    return this.form.invalid;
  }

  public patchValue(
    object: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    this.form.patchValue(object as { [key: string]: any }, options);
  }
}
