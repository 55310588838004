<div class="modal_tableBulkUpload ">
  <div class="text-coface-primary text-xl font-bold text-center mb-3">
    {{'Proceso de carga exitoso' | translate}}
  </div>

  <coface-table
    [dataHead]="dataHeaderCmp"
    [dataBody]="dataBody"
    [loadingContent]="false"
  >
  </coface-table>
  <button
    class="btn__passWayPay w-full text-white bg-coface-primary mt-5 block mx-auto text-sm"
    (click)="closePOPUP()"
  >
    {{'Aceptar' | translate}}
  </button>
</div>
