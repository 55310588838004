import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericRequestConfig } from '../model/request.config';
import { IResponseCoface } from '../interface/response-coface.interface';

@Injectable({
  providedIn: 'root',
})
export class UnlockEndpoint {
  constructor(private http: HttpClient) {}

  termConditionAccept$(req: GenericRequestConfig) {
    return this.http.get<IResponseCoface<any>>(req.url, req);
  }

  //   /backoffice/customers/:customerId/unlock

  unlockCustomerByManager$(req: GenericRequestConfig) {
    return this.http.patch<IResponseCoface<any>>(req.url, req);
  }

  unlockSupplierByManager$(req: GenericRequestConfig) {
    return this.http.patch<IResponseCoface<any>>(req.url, req);
  }

  unlockCoworkersByAdministrator$(req: GenericRequestConfig) {
    return this.http.patch<IResponseCoface<any>>(req.url, req);
  }
}
