<div class="terms-conditions-colombia text-justify">
  <div class="cgray">
    <h1 class="text-2xl text-center cgray font-bold">
      Declaraciones y Autorizaciones
    </h1>
    <div class="text-sm my-2 flex justify-between">
      <div class="w-48">
        <h3 class="font-bold text-center mb-2">PROVEEDOR PERSONA NATURAL</h3>
        <p>
          El Proveedor, por su propio derecho, acepta los términos y condiciones
          aplicables para el uso de la plataforma web para la generación del
          reporte de calificación de proveedor (Supplier Report), y manifiesta
          que entiende el alcance de los mismos
        </p>
      </div>
      <div class="w-48">
        <h3 class="font-bold text-center mb-2">PROVEEDOR PERSONA JURÍDICA</h3>
        <p>
          El representante del Proveedor declara bajo protesta de decir verdad
          que cuenta con las facultades necesarias para aceptar los términos y
          condiciones aplicables para el uso de la plataforma web para la
          generación del reporte de calificación de proveedor (Supplier Report),
          manifestando que entiende el alcance de los mismos.
        </p>
      </div>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        Coface, se refiere a las empresas que forman parte del grupo empresarial
        Coface, las cuales pueden ser sucursales, subsidiarias, afiliadas y/o
        filiales; dichas empresas tendrán acceso a la información que el
        Proveedor proporcione, autorizando expresamente en este acto la
        utilización, procesamiento, almacenamiento y transmisión, nacional e
        internacional, de dicha información a cualquiera de las empresas (en lo
        sucesivo “Coface”).
      </p>
      <p class="w-48">
        Coface, se refiere a las empresas que forman parte del grupo empresarial
        Coface, las cuales pueden ser sucursales, subsidiarias, afiliadas y/o
        filiales; dichas empresas tendrán acceso a la información que el
        Proveedor proporcione, autorizando expresamente en este acto la
        utilización, procesamiento, almacenamiento y transmisión, nacional e
        internacional, de dicha información a cualquiera de las empresas (en lo
        sucesivo “Coface”).
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        El Proveedor en calidad de titular de información, actuando libre y
        voluntariamente, autoriza de manera expresa e irrevocable a Coface para
        consultar, validar, revisar, solicitar, procesar y divulgar toda la
        información que se refiera al comportamiento crediticio, financiero,
        comercial, y de servicios; así como la consulta en las centrales de
        información y de riesgo autorizadas, con la finalidad de evaluar la
        experiencia y situación crediticia, a través de la generación del
        reporte de calificación de proveedor (Supplier Report). Igualmente
        autoriza que se haga entrega de la información documental soporte de la
        generación del reporte de calificación de proveedor (Supplier Report) al
        Cliente quien ha solicitado su calificación, cuando así lo requiera.
      </p>
      <p class="w-48">
        El representante del Proveedor, autoriza de manera expresa e irrevocable
        a Coface para consultar, validar, revisar, solicitar, procesar y
        divulgar toda la información que se refiera al comportamiento
        crediticio, financiero, comercial, y de servicios; así como la consulta
        en las centrales de información y de riesgo autorizadas, de la compañía
        que representa, con la finalidad de evaluar la experiencia y situación
        crediticia, a través de la generación del reporte de calificación de
        proveedor (Supplier Report). Igualmente autoriza que se haga entrega de
        la información documental soporte de la generación del reporte de
        calificación de proveedor (Supplier Report) al Cliente quien ha
        solicitado su calificación, cuando así lo requiera.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        Adicionalmente, autoriza expresamente a Coface para que trate la
        información, documentos y datos personales que se suministren con motivo
        del servicio solicitado, y de ser necesario, la transferencia, nacional
        e internacional, de los mismos, para todos los productos y servicios que
        ofrece Coface. Para lo cual, el Proveedor entregará a Coface la
        información y documentación que le sea requerida, a través de la
        utilización de la plataforma web.
      </p>
      <p class="w-48">
        Adicionalmente, autorizo expresamente a Coface para que trate la
        información, documentos y datos personales que se suministren con motivo
        del servicio solicitado, y de ser necesario, la transferencia, nacional
        e internacional, de los mismos, para todos los productos y servicios que
        ofrece Coface. Para lo cual, el Proveedor entregará a Coface la
        información y documentación que le sea requerida, a través de la
        utilización de la plataforma web.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        Los datos suministrados serán tratados por Coface como responsable o por
        los encargados que éste designe y conforme a la política de privacidad
        de Coface y la normatividad vigente.
      </p>
      <p class="w-48">
        Los datos suministrados serán tratados por Coface como responsable o por
        los encargados que éste designe y conforme a la política de privacidad
        de Coface y la normatividad vigente.
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        En virtud de lo anterior, en caso de que se le requiera, el Proveedor se
        obliga a pagar a Coface el importe correspondiente por la generación del
        reporte de calificación de proveedor (Supplier Report), de conformidad
        con la modalidad de reporte seleccionada por el Proveedor.
      </p>
      <p class="w-48">
        En virtud de lo anterior, en caso de que se le requiera, el Proveedor se
        obliga a pagar a Coface el importe correspondiente por la generación del
        reporte de calificación de proveedor (Supplier Report), de conformidad
        con la modalidad de reporte seleccionada por el Proveedor.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        Con la aceptación de los presentes términos y condiciones, el Proveedor
        acepta las Políticas de Tratamiento de Datos Personales de Coface.
      </p>
      <p class="w-48">
        Con la firma de los presentes términos y condiciones, se establece que
        el Proveedor, por conducto de su representante, acepta las Políticas de
        Tratamiento de Datos Personales de Coface.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        El Proveedor entiende que podrá ejercer en cualquier momento sus
        derechos para actualizar, corregir, complementar, precisar o suprimir
        sus datos personales, enviando su solicitud al siguiente correo
        electrónico: atencioncliente.lar&#64;coface.com.
      </p>
      <p class="w-48">
        El Proveedor entiende que podrá ejercer en cualquier momento sus
        derechos para actualizar, corregir, complementar, precisar o suprimir
        sus datos personales, enviando su solicitud al siguiente correo
        electrónico: atencioncliente.lar&#64;coface.com.
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        En este acto, declaro de manera expresa que autorizo a Coface para que
        los datos suministrados sean utilizados con la finalidad de ofrecerme
        productos, enviarle boletines de información o noticias, enviarme
        información adicional acerca del sitio web y demás finalidades
        estrechamente asociadas y necesarias para cumplir los fines de mercadeo,
        publicidad, estadísticos y administrativos, de conformidad con su
        política de tratamiento de datos disponible para su consulta en la
        página web de Coface.
      </p>
      <p class="w-48">
        En este acto, declaro de manera expresa que autorizo a Coface para que
        los datos suministrados sean utilizados con la finalidad de ofrecerme
        productos, enviarle boletines de información o noticias, enviarme
        información adicional acerca del sitio web y demás finalidades
        estrechamente asociadas y necesarias para cumplir los fines de mercadeo,
        publicidad, estadísticos y administrativos, de conformidad con su
        política de tratamiento de datos disponible para su consulta en la
        página web de Coface.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48 w">
        Las Partes declaran y garantizan que: <br />i. no realizan ni aceptan,
        como así tampoco, realizarán ni aceptarán pagos, promesas de pago; ni
        brindan (ni prometen brindar) cualquier ventaja financiera u obsequios;
        ni participan en ninguna actividad que equivalga a un soborno público o
        comercial; .
      </p>
      <p class="w-48">
        Las Partes declaran y garantizan que: <br />i. no realizan ni aceptan,
        como así tampoco, realizarán ni aceptarán pagos, promesas de pago; ni
        brindan (ni prometen brindar) cualquier ventaja financiera u obsequios;
        ni participan en ninguna actividad que equivalga a un soborno público o
        comercial;
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        ii. se asegurarán de que sus directores, ejecutivos y colaboradores
        cumplan con las obligaciones antes mencionadas;
      </p>
      <p class="w-48">
        ii. se asegurarán de que sus directores, ejecutivos y colaboradores
        cumplan con las obligaciones antes mencionadas;
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        iii. no participarán en negocios con subcontratistas ni asesores
        independientes que no acepten cumplir con dichos compromisos.
      </p>
      <p class="w-48">
        iii. no participarán en negocios con subcontratistas ni asesores
        independientes que no acepten cumplir con dichos compromisos.
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        En caso de incumplimiento de las disposiciones antes mencionadas por una
        Parte o por sus directores, ejecutivos o colaboradores, la Parte
        afectada tendrá derecho, bajo notificación inmediata por escrito y sin
        responsabilidad alguna o pago de cualquier naturaleza:
      </p>
      <p class="w-48">
        En caso de incumplimiento de las disposiciones antes mencionadas por una
        Parte o por sus directores, ejecutivos o colaboradores, la Parte
        afectada tendrá derecho, bajo notificación inmediata por escrito y sin
        responsabilidad alguna o pago de cualquier naturaleza:
      </p>
    </div>

    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        a) a suspender el cumplimiento del Contrato y de cualquier obligación
        que surja de dichos contratos o que estén relacionados con los mismos,
        en caso de una investigación iniciada por una autoridad judicial,
        administrativa o reguladora.
      </p>
      <p class="w-48">
        a) a suspender el cumplimiento del Contrato y de cualquier obligación
        que surja de dichos contratos o que estén relacionados con los mismos,
        en caso de una investigación iniciada por una autoridad judicial,
        administrativa o reguladora.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        b) a rescindir el Contrato en caso de condena por parte de un tribunal,
        o de sanción por parte de una autoridad administrativa o reguladora.
      </p>
      <p class="w-48">
        b) a rescindir el Contrato en caso de condena por parte de un tribunal,
        o de sanción por parte de una autoridad administrativa o reguladora.
      </p>
    </div>
    <div class="text-sm my-2 flex justify-between">
      <p class="w-48">
        Las obligaciones de Coface en cuanto al cumplimiento, de acuerdo a lo
        establecido en el presente Contrato, quedarán suspendidas en caso que
        pudieran causar o exponer a Coface o a cualquier miembro de su Grupo a
        algún tipo de sanción o pena (incluyendo sanciones extraterritoriales)
        que se originen o vinculen con los acuerdos o las sanciones económicas
        y/o administrativas de las Naciones Unidas, leyes o regulaciones de la
        Unión Europea o de cualquier Estado de la Unión Europea, del Reino Unido
        o de cualquier país o jurisdicción en América Latina; con prescindencia
        de si tales sanciones existían al inicio de este Contrato o si fueron
        implementadas durante la vigencia del mismo.
      </p>
      <p class="w-48">
        Las obligaciones de Coface en cuanto al cumplimiento, de acuerdo a lo
        establecido en el presente Contrato, quedarán suspendidas en caso que
        pudieran causar o exponer a Coface o a cualquier miembro de su Grupo a
        algún tipo de sanción o pena (incluyendo sanciones extraterritoriales)
        que se originen o vinculen con los acuerdos o las sanciones económicas
        y/o administrativas de las Naciones Unidas, leyes o regulaciones de la
        Unión Europea o de cualquier Estado de la Unión Europea, del Reino Unido
        o de cualquier país o jurisdicción en América Latina; con prescindencia
        de si tales sanciones existían al inicio de este Contrato o si fueron
        implementadas durante la vigencia del mismo.
      </p>
    </div>

    <button
      class="btn__passTerm w-full text-white bg-coface-primary mt-4 block mx-auto"
      (click)="onAcceptTermCond()"
    >
      Aceptar
    </button>
  </div>
</div>
