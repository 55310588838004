import { IfStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HomologacionOperation } from '../../modules/homologacion/commons/homologacion-operation';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class HomologationGuard {
  constructor(
    private homologationOperation: HomologacionOperation,
    private route: Router,
    private session: SessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // && this.session.user.roles![0].keyword === 'CUSTOMER'
    if (this.homologationOperation.idCondition) {
      return true;
    } else {
      if (
        this.homologationOperation.idCondition === undefined &&
        this.session.user.role?.keyword === 'CUSTOMER'
      ) {
        this.route.navigate([
          '/coface/proveedores/clientes/cartera-proveedores',
        ]);
        return false;
      } else {
        this.route.navigate(['/coface/home']);
        return false;
      }
    }
  }
}
