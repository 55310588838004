import { HtmlParser } from '@angular/compiler';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  TABLE_EVENTS,
  TABLE_EVENT_CHECK,
} from '../../../commons/enums/general.enum';
import { ITableHeader } from '../../../commons/interface/paginator-table.interface';
import { BlockStateComponent } from '../dialogs/block-state/block-state/block-state.component';
import { SessionService } from '../../../commons/services/session.service';
import { EUnlockModule } from '../../../commons/enums/unlock-module.enum';

@Component({
  selector: 'coface-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DialogService],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() dataHead = new Array<Partial<ITableHeader>>();
  @Input() dataBody = new Array<any>();
  @Input() actions = new Array<any>();
  @Input() loadingContent = false;
  @Input() totalElements: number;
  @Input() currentPage;
  @Input() itemsXPage;
  @Input() multiCheck: boolean = false;
  @Input() evtPaginator: boolean;
  @Output() public eventTable = new EventEmitter<{
    row: Array<any>[];
    event: TABLE_EVENTS;
  }>();
  @Output() public changeTableSize = new EventEmitter();
  @Output() public changeTablePage = new EventEmitter();
  @Output() public checkEventTable = new EventEmitter<{
    rows;
    event;
    checked;
  }>();
  @Output() public eventPaginator = new EventEmitter();
  @Output() public unlockEvent = new EventEmitter();

  public tableEvents = TABLE_EVENTS;
  public unlockOpenModalRef: DynamicDialogRef;
  // multiCheck: boolean = true;
  cities: City[];

  constructor(
    private dialogService: DialogService,
    public session: SessionService
  ) {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' },
    ];
  }

  ngOnInit(): void {
    // console.log(this.dataBody);
  }
  ngAfterViewInit(): void {}

  eventAction(row, event: TABLE_EVENTS) {
    this.eventTable.emit({ row: row, event: event });
  }
  emitAllCheck(e: HTMLInputElement) {
    this.checkEventTable.emit({
      rows: this.dataBody,
      event: TABLE_EVENT_CHECK.ALL,
      checked: e.checked,
    });
  }
  emitItemCheck(e: HTMLInputElement, row) {
    this.checkEventTable.emit({
      rows: [row],
      event: TABLE_EVENT_CHECK.ITEM,
      checked: e.checked,
    });
  }

  onExpand(index) {
    // console.log();
    let elementDetail = document.querySelector(`#detail-${index}`);
    // console.log(elementDetail);
    if (elementDetail?.classList.contains('none')) {
      elementDetail?.classList.remove('none');
      elementDetail?.classList.add('contents');
    } else {
      elementDetail?.classList.remove('contents');
      elementDetail?.classList.add('none');
    }
  }
  public onChangeTableSize(event) {
    this.changeTableSize.emit(event);
  }

  public onChangeTablePage(event) {
    this.changeTablePage.emit(event);
  }
  public onEventPaginator() {
    this.eventPaginator.emit();
  }

  public onOpenBlockedStatus(data) {
    console.log(this.session.user, data);
    if (grantRoleForUnlock.includes(this.session.user.role?.keyword!)) {
      if (data.blockedState !== 'Activo') {
        this.unlockOpenModalRef = this.dialogService.open(BlockStateComponent, {
          data: {
            unlockModule: data.unlockModule,
            idUnlock:
              data.unlockModule === EUnlockModule.MANAGER_SUPPLIER_WALLET
                ? data.supplierId
                : data.id,
            nameToUnlock: data.nameToUnlock,
          },
          showHeader: false,
          width: '35%',
          dismissableMask: true,
          contentStyle: { 'border-radius': '15px' },
          baseZIndex: 10000,
          closeOnEscape: false,
          closable: false,
        });
      }
      this.unlockOpenModalRef?.onClose.subscribe((x) => {
        this.unlockEvent.emit();
      });
    }
  }
}

interface City {
  name: string;
  code: string;
}

const grantRoleForUnlock = ['SUPERUSER', 'MANAGER', 'ADMIN', 'ADMINISTRATOR'];
