import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EUnlockModule } from 'projects/ui-coface/src/app/commons/enums/unlock-module.enum';
import { UnlockOperation } from 'projects/ui-coface/src/app/commons/operations/unlock.operation';

@Component({
  selector: 'coface-block-state',
  templateUrl: './block-state.component.html',
  styleUrls: ['./block-state.component.scss'],
})
export class BlockStateComponent implements OnInit {
  public dataModal:  {
    unlockModule: EUnlockModule;
    idUnlock: string;
    nameToUnlock: string;
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public unlockOperation: UnlockOperation
  ) {}

  ngOnInit(): void {
    this.dataModal = this.config.data;
  }

  onUnlock() {
    const { unlockModule, idUnlock } = this.dataModal;

    console.log(unlockModule, idUnlock);

    switch (unlockModule) {
      case EUnlockModule.ADMINISTRATOR_COWORKERS:
        this.unlockOperation
          .unlockCoworkersByAdministrator$(idUnlock)
          .subscribe((x) => {
            this.ref.close();
          });
        break;
      case EUnlockModule.MANAGER_CUSTOMER_WALLET:
        this.unlockOperation
          .unlockCustomerByManager$(idUnlock)
          .subscribe((x) => {
            this.ref.close();
          });
        break;
      default:
        this.unlockOperation
          .unlockSupplierByManager$(idUnlock)
          .subscribe((x) => {
            this.ref.close();
          });
        break;
    }
  }
  onCancel() {
    this.ref.close();
  }
}
