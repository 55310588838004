import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Parameter } from '../../../commons/model/parameter.model';

@Component({
  selector: 'coface-autocomplete-fix',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() public suggestions: Array<Parameter> = [];
  @Input() public loading: boolean;
  @Input() public value: string;
  @Input() public placeholder: string = 'Ingresa nombre del cliente';
  @Input() public lenghtRequired: number = 2;
  @Input() public showIcon: boolean = false;
  @Input() public shadow: boolean = false;
  @ViewChild('autoCompleteTemplate', { static: false })
  public autoCompleteTemplate: ElementRef;
  @ViewChild('refInput') refInput: ElementRef;

  @Output()
  public searchText = new EventEmitter<string>();

  @Output()
  public valueSelected = new EventEmitter<number | string>();

  public selectCustomer: { id: number; name: string };
  public showSuggestions: boolean = false;
  public valuev2: string = '';

  constructor() {}

  public onFocusInput() {
    this.showSuggestions = true;
  }
  public onChangeInput(event) {
    this.searchText.emit(event.target.value);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      // console.log(changes['value'].currentValue, this.suggestions);

      if (changes['value'].currentValue) {
        this.valuev2 =
          this.suggestions.find(
            (x) => x.Id.toString().trim() === this.value.trim()
          )?.Name ?? '';
        // console.log(this.valuev2);
      } else {
        this.valuev2 = '';
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.autoCompleteTemplate.nativeElement.contains(event.target)) {
      this.showSuggestions = false;
    }
  }

  public selectSuggestion(selectSuggestion: { id: number; name: string }) {
    this.showSuggestions = false;
    this.selectCustomer = selectSuggestion;
    const input = this.refInput.nativeElement as HTMLInputElement;
    input.value = selectSuggestion.name ?? '';

    this.valueSelected.emit(this.selectCustomer.id.toString());
  }
}
