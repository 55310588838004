import { ICountries } from './../../../../commons/interface/menu.interface';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  ILanguages,
  IRoles,
  IStatus,
} from 'projects/ui-coface/src/app/auth/commons/interface/auth.interface';
import { DropdawnService } from 'projects/ui-coface/src/app/core/services/dropdawn.service';
import { CreateUserStaffPresenter } from './create-user.presenter';
import { CreateUserStaffOperation } from 'projects/ui-coface/src/app/modules/user-management/commons/operations/create-user.operations';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SuccessfulComponent } from '../successful/successful.component';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';
import {
  IParameter,
  Parameter,
} from 'projects/ui-coface/src/app/commons/model/parameter.model';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { RoleType } from 'projects/ui-coface/src/app/commons/enums/role.enum';

@Component({
  selector: 'coface-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [CreateUserStaffPresenter],
})
export class CreateUserComponent implements OnInit, AfterViewInit {
  title1: string = 'Entidad Coface';
  title2: string = 'Idioma';
  title3: string = 'Estado';
  title4: string = 'Perfil';
  title5: string = 'Idioma de Soporte';
  loaderBtnEvnt: boolean = false;
  textSendSucces: string;
  textSendError: string = 'Ocurrió un error al registrar usuario';

  itemsLanguages: ILanguages[] = [];
  itemsRoles: IRoles[] = [];
  itemsOffice: ICountries[] = [];
  itemsStatus: IStatus[] = [
    { id: 0, name: 'Activo' },
    { id: 1, name: 'Inactivo' },
    { id: 2, name: 'Bloqueado' },
  ];

  userLanguage: string;
  userCorrect: string;

  public officesByLeaderProduction: Array<Parameter> = [];

  disableSupportLanguageId: boolean = true;

  disableLanguagePlatform: boolean;
  constructor(
    private _select: DropdawnService,
    public createUserPresenter: CreateUserStaffPresenter,
    private createUserOperation: CreateUserStaffOperation,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private translate: TranslateService,
    private session: SessionService
  ) {}

  ngOnInit(): void {
    this.itemsLanguages = this._select.dataListLanguages;
    this.itemsRoles = this._select.dataListRoles.filter(
      (x) =>
        x.name !== 'Comercial' && x.name !== 'Cliente' && x.name !== 'Proveedor'
    );

    this.defineRolesList();
    this.itemsOffice = this._select.dataListCountries;
    this.officesByLeaderProduction = this._select.dataListCountries.map((x) => {
      return new Parameter(x.id.toString(), x.name);
    });
  }

  ngAfterViewInit(): void {
    this.createUserPresenter.Form.get('roleId')?.valueChanges.subscribe(
      (data) => {
        this.itemsRoles.find((ele) => ele.id === data)?.name === 'Analista'
          ? (this.disableLanguagePlatform = true)
          : (this.disableLanguagePlatform = false);

        if (this.disableLanguagePlatform) {
          this.createUserPresenter.Form.controls[
            'supportLanguageId'
          ].setValidators(Validators.required);
        } else {
          this.createUserPresenter.Form.controls['supportLanguageId'].setValue(
            null
          );
          this.createUserPresenter.Form.controls[
            'supportLanguageId'
          ].clearValidators();
          this.createUserPresenter.Form.controls[
            'supportLanguageId'
          ].updateValueAndValidity();
        }
      }
    );
  }

  createUserStaff() {
    this.translate.get('Usuario').subscribe((res: string) => {
      this.userLanguage = res;
    });
    this.translate.get('registrado correctamente').subscribe((res: string) => {
      this.userCorrect = res;
    });
    if (!this.createUserPresenter.invalid()) {
      this.loaderBtnEvnt = true;
      this.createUserOperation
        .createUserStaff(this.createUserPresenter.Form.value)
        .subscribe(
          (_) => {
            this.textSendSucces = `${this.userLanguage}  ${this.createUserPresenter.Form.value.firstname}  ${this.userCorrect}`;
            this.createUserPresenter.resetForm();
            this.ref.close();
            this.openDialog(this.textSendSucces);

            this.loaderBtnEvnt = false;
          },
          (error) => {
            // this.openDialog(this.textSendError);
            this.loaderBtnEvnt = false;
          }
        );
    }
  }

  openDialog(text) {
    this.dialogService.open(SuccessfulComponent, {
      data: text,
      showHeader: false,
      width: '35%',
      dismissableMask: true,
      contentStyle: { 'border-radius': '15px' },
      baseZIndex: 10000,
    });
  }

  validInput(campo: string) {
    return (
      this.createUserPresenter.Form.controls[campo].errors &&
      this.createUserPresenter.Form.controls[campo].touched
    );
  }

  get forms() {
    return this.createUserPresenter.Form.controls;
  }

  btnClean() {
    this.createUserPresenter.resetForm();
  }

  private defineRolesList() {
    if (this.session.user.role?.keyword === RoleType.ADMINISTRATOR) {
      this.itemsRoles = this.itemsRoles.filter(
        (x) => x.keyword === RoleType.MANAGER
      );
    }

    if (this.session.user.role?.keyword === RoleType.PRODUCTION_LEADER) {
      this.itemsRoles = this.itemsRoles.filter(
        (x) => x.keyword === RoleType.ANALYST
      );
    }
  }
}
