import { AbstractControl, UntypedFormArray, ValidationErrors } from '@angular/forms';
import { Observable, range } from 'rxjs';

export const repeatedDocAsyncValidator = (partners?: UntypedFormArray) => {
  return (
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return new Promise((resolve) => {
      const values = control.value as Array<any>;

      let ranges: Array<number> = new Array();
      let res: any = null;

      if (values.some((val) => !val.from || !val.until)) {
        res = { noValidRange: true } as ValidationErrors;
        resolve(res);
      }
      if (values.some((val) => val.from && val.until)) {
        values.forEach((obj) => {
          if (obj.from && obj.until) {
            if (+obj.from >= obj.until || +obj.from < 0 || +obj.until < 0) {
              res = { noValidRange: true } as ValidationErrors;
              resolve(res);
            }

            if (
              ranges.length &&
              (ranges.includes(+obj.from) || ranges.includes(+obj.until))
            ) {
              res = { noValidRange: true } as ValidationErrors;
              resolve(res);
            }
            ranges.push(...getRange(+obj.from, +obj.until));
            ranges = [...new Set(ranges)];
          }
        });

        resolve(res);
      }

      resolve(res);
    });
  };
};

export const getRange = (min: number, max: number) => {
  return Array.from({ length: max - min + 1 }, (_, i) => min + i);
};
