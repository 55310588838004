import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { HomologacionOperation } from '../../../modules/homologacion/commons/homologacion-operation';
import { CreateUserStaffOperation } from '../../../modules/user-management/commons/operations/create-user.operations';
import { SessionService } from '../../../commons/services/session.service';
import { ModalService } from '../../../commons/services/dialog.service';
import { AssignAnalystComponent } from '../dialogs/assign-analyst/assign-analyst.component';
import { SuccessfulComponent } from '../dialogs/successful/successful.component';
import { RoleType } from '../../../commons/enums/role.enum';

@Component({
  selector: 'coface-collapse-detail-table',
  templateUrl: './collapse-detail-table.component.html',
  styleUrls: ['./collapse-detail-table.component.scss'],
})
export class CollapseDetailTableComponent implements OnInit {
  @Input() detail;
  @Output() public detachData = new EventEmitter();

  public forbiddenRoles = [RoleType.ADMINISTRATOR, RoleType.MANAGER];
  public showAction: boolean;

  constructor(
    private homologationOperation: HomologacionOperation,
    private createUserStaffOperation: CreateUserStaffOperation,
    private session: SessionService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
   // debugger;
    this.showAction = !this.forbiddenRoles.includes(
      this.session.user.role?.keyword as RoleType
    );
  }

  downloadCustomLetters(attachmentId: any) {
    if (attachmentId != null) {
      window.open(
        `${environment.urlBaseEndpoint}/attachments/download/${attachmentId}`
      );
    }
  }

  downloadCustomForms(attachments: any) {
    if (attachments.length) {
      for (const element of attachments) {
        window.open(
          `${environment.urlBaseEndpoint}/attachments/download/${element.id}`
        );
      }
    }
  }

  openAssignModal() {
    const ref = this.modalService.open(
      {
        supplierName: this.detail.dataDetail.legalNameSupplier,
        customerName: this.detail.dataDetail.customerName,
        homologationId: this.detail.dataDetail.homologationId,
      },
      AssignAnalystComponent,
      {
        size: 'SMALL_MEDIUM',
        closable: 'MODAL',
        disableClose: true,
      }
    );

    ref.onClose.subscribe((status) => {
      if (status === 'SUCCESS') {
        this.modalService.open(
          'Analista asignado con éxito',
          SuccessfulComponent,
          {
            size: 'SMALL',
            disableClose: false,
            closable: 'NO_CLOSE_BY_OUTSIDE',
          }
        );
        this.detachData.emit();
      }
    });
  }
}
