import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { filter, fromEvent, map, tap } from 'rxjs';

export const USER_EVENT = 'click';

@Component({
  selector: 'coface-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() items = new Array<any>();
  @Input() value: string;
  @Input() valueTitle: string = 'Seleccione';
  @Input() shadow: boolean = false;
  @Input() green:boolean = false;
  @Output() valueSelected: EventEmitter<string>;
  @Input('id-select') idselect: string;
  @Input('id-option') idoption: string;

  public itemSelected: string = this.valueTitle;
  // public itemSelected: string;
  showIcon: boolean = true;
  isOpenOptions: boolean = false;
  // public valueSelected: string = 'Seleccione';
  private options: HTMLDivElement;
  private selected: HTMLDivElement;

  @ViewChild('selectTemplate', { static: false }) selectTemplate: ElementRef;

  constructor(private ref: ElementRef) {
    this.valueSelected = new EventEmitter<string>();
  }

  ngOnInit(): void {
    // console.log(this.idselect);
    this.idselect = `${this.idselect}-${this.randomId()}`;
    this.idoption = `${this.idoption}-${this.randomId()}`;
  }
  ngAfterViewInit(): void {
    this.options = document.querySelector(
      `#${this.idoption}`
    ) as HTMLDivElement;
    this.selected = document.querySelector(
      `#${this.idselect}`
    ) as HTMLDivElement;
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.selectTemplate.nativeElement.contains(event.target)) {
      this.hideOptions(this.options);
      this.isOpenOptions = false;
    }
  }

  get ItemSelected() {
    return (this.itemSelected =
      this.items?.find((elm) => elm.id === this.value)?.name ||
      this.valueTitle);
  }

  public openSelect(event) {
    this.isOpenOptions = !this.isOpenOptions;

    if (this.isOpenOptions) {
      this.showOptions(this.options);
    } else {
      this.hideOptions(this.options);
    }
  }

  public onSelectValue(item: any) {
    this.value = item.id;
    this.isOpenOptions = false;
    this.valueSelected.emit(item.id);
    this.hideOptions(this.options);
  }

  private showOptions(options: HTMLElement) {
    (options.childNodes[0] as HTMLElement).classList.remove('hide');
    (options.childNodes[0] as HTMLElement).classList.add('show');
    this.showIcon = false;
  }
  private hideOptions(options: HTMLElement) {
    (options.childNodes[0] as HTMLElement).classList.add('hide');
    (options.childNodes[0] as HTMLElement).classList.remove('show');
    this.showIcon = true;
  }
  ngOnDestroy(): void {
    this.selected?.remove();
    this.options?.remove();
  }

  randomId() {
    return Math.floor(Math.random() * Math.pow(2, 60)) + 1;
  }
}
