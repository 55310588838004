import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'coface-terms-and-conditions-brazil',
  templateUrl: './terms-and-conditions-brazil.component.html',
  styleUrls: ['./terms-and-conditions-brazil.component.scss'],
})
export class TermsAndConditionsBrazilComponent implements OnInit {
  authorize1: boolean = false;
  authorize2: boolean = false;
  authorize3: boolean = false;
  constructor(private dialogRef: DynamicDialogRef) {}


  ngOnInit(): void {}
  // changeFirstRadio(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorize1 = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorize1 = false;
  //   }
  // }
  // changeSecondRadio(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorize2 = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorize2 = false;
  //   }
  // }
  // changeThirtyRadio(event) {
  //   if (event.target.value === 'YES') {
  //     this.authorize3 = true;
  //   }
  //   if (event.target.value === 'NO') {
  //     this.authorize3 = false;
  //   }
  // }
  onAcceptTermCond() {
    this.dialogRef.close('COMPLETE');
  }
}
