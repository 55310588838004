<div [formGroup]="formEditInformationSupplier" class="edit-information-supplier" >
  <h1
    class="absolute text-coface-primary font-bold text-center w-full left-0 p-3 pt-7 text-2xl mb-6"
  >
    {{ "Actualizar información" | translate }}
  </h1>
  <div class="pt-20 px-3">
    <div class="mb-5">
      <input
        class="input"
        type="text"
        required
        autocomplete="off"
        formControlName="supplierEmail"
        placeholder="{{ 'Correo' | translate }}"
      />
    </div>
    <div class="mb-5">
      <input
        class="input"
        type="text"
        required
        cofaceOnlyNumber
        formControlName="supplierPhoneNumber"
        autocomplete="off"
        placeholder="{{ 'Celular' | translate }}"
      />
    </div>
    <div class="w-72 mx-auto">
      <button
        class="btn__passForm text-white bg-coface-primary block text-sm width_custom"
        (click)="editInfoSupplier()"
      >
        {{ "Guardar" | translate }}
      </button>
      <button class="text-sm btn__passForm" (click)="closeModal()">
        Cancelar
      </button>
    </div>
  </div>
</div>
