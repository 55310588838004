<div [formGroup]="formRejectedHomologation" class="end-process">
  <div>
    <h1
      class="text-center text-2xl mb-3 clight font-bold"
      [innerHTML]="
        'Lamentamos que no puedas <br /> participar del proceso' | translate
      "
    ></h1>
    <p class="text-center text-sm my-4">
      {{
        "Lamentamos que no puedas participar del proceso. Para mejorar nuestro servicio, por favor indícanos, cuál es el motivo por el cual no deseas continuar con el proceso"
          | translate
      }}
    </p>
    <div class="text-sm">
      <p class="flex items-center">
        <input
          (change)="changeSecondRadio($event)"
          formControlName="radio"
          value="option1"
          type="radio"
          class="mr-2"
        />{{
          "No he recibido suficiente información y no me queda claro el proceso"
            | translate
        }}
      </p>
      <p class="flex items-center">
        <input
          (change)="changeSecondRadio($event)"
          formControlName="radio"
          value="option2"
          type="radio"
          class="mr-2"
        />{{
          "Por el momento no me encuentro interesado en participar del proceso de calificación"
            | translate
        }}
      </p>
      <p class="flex items-center">
        <input
          (change)="changeSecondRadio($event)"
          formControlName="radio"
          value="option3"
          type="radio"
          class="mr-2"
        />{{ "No estoy de acuerdo con el precio del servicio" | translate }}
      </p>
      <p class="flex items-center">
        <input
          (change)="changeFirstRadio($event)"
          formControlName="radio"
          value="option4"
          type="radio"
          class="mr-2"
        />{{ "Otros" | translate }}
      </p>
    </div>
    <div
      *ngIf="showTextArea"
      class="flex justify-between items-center relative my-4"
    >
      <textarea
        formControlName="comment"
        class="text-sm"
        name=""
        id=""
        cols="30"
        rows="10"
      ></textarea>
    </div>

    <p class="text-sm text-center">
      {{ "Gracias por tus comentarios" | translate }}
    </p>
    <button
      [disabled]="formRejectedHomologation.invalid"
      (click)="homologationRejected()"
      class="btn__passEndProcess w-full text-white bg-coface-primary mt-5 block mx-auto text-sm"
      [ngClass]="{ gray: formRejectedHomologation.invalid }"
    >
      {{ "Finalizar con el proceso" | translate }}
    </button>
    <!-- dialogRef.close() -->
  </div>
</div>

<!-- {{formRejectedHomologation.value | json}} -->
