import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IParameterCheck,
  Parameter,
} from '../../../commons/model/parameter.model';

@Component({
  selector: 'coface-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit {
  @Input() public suggestionsData: Array<Parameter> = [];
  @Input() public loading: boolean;
  @Input() public placeholder: string = 'Chile, Ecuador, Perú... ';
  @Input() public showIcon: boolean = false;
  @Input() public shadow: boolean = false;

  @ViewChild('autoCompleteTemplate', { static: false })
  public autoCompleteTemplate: ElementRef;
  @ViewChild('refInput', { static: false }) public refInput: ElementRef;

  public showSuggestions: boolean = false;
  public value: any = '';
  public suggestions: Array<IParameterCheck> = [];
  public isSelectedSuggestion: boolean = false;
  public id: string;

  public dataSelectedTemp = new Array<IParameterCheck>();

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor() {}

  ngOnInit(): void {
    this.suggestions = this.suggestionsData;
  }

  public onFocusInput() {
    this.showSuggestions = true;
    // if (this.id) {
    //   this.suggestions = this.suggestionsData.filter((x) => x.Id === this.id);
    // }
    // this.suggestions = this.suggestionsData;
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.autoCompleteTemplate.nativeElement.contains(event.target)) {
      if (this.showSuggestions && !this.isSelectedSuggestion) {
        if (this.dataSelectedTemp.length === 0) {
          this.refInput.nativeElement.value = '';
          this.onChange('');
          this.value = '';
          this.id = '';
        }
        this.suggestions = this.suggestionsData;
      }
      this.isSelectedSuggestion = false;
      this.showSuggestions = false;
    }
  }

  public onSearchData(event) {
    this.onChange('');
    this.id = '';
    this.isSelectedSuggestion = false;
    this.suggestions = this.suggestionsData.filter((x) =>
      x.Name.toLowerCase().includes(event.target.value.toLowerCase())
    );
  }

  public onKeyDown(event) {
    if (event.key === 'Backspace') {
      if (this.dataSelectedTemp.length > 0) {
        const lastElementRemoved = this.dataSelectedTemp.pop()!;

        this.suggestions.map((x) => {
          if (x.Id === lastElementRemoved.Id) {
            x.checked = false;
          }
        });
        this.value = this.dataSelectedTemp.map((x) => x.Name).join(',');
        this.onChange(this.dataSelectedTemp);
        this.onTouched();
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
    // event.preventDefault();
  }

  public onPaste(event) {
    event.preventDefault();
  }

  public onCheck(event, item, index) {
    this.isSelectedSuggestion = true;
    const isChecked = event.target.checked;
    this.dataSelectedTemp = [];

    this.suggestions.map((x) => {
      if (x.Id === item.Id) {
        x.checked = isChecked;
      }
    });

    this.suggestions.forEach((x) => {
      if (x.checked) {
        this.dataSelectedTemp.push(x);
      }
    });
    // convert array to string plane
    this.value = this.dataSelectedTemp.map((x) => x.Name).join(',');

    this.onChange(this.dataSelectedTemp);
    this.onTouched();
  }

  public writeValue(value: Array<Parameter>): void {
    if (value.length) {
      this.value = value;
    } else {
      this.value = [];
      this.dataSelectedTemp = [];
      this.suggestions = this.suggestions.map((x) => {
        x.checked = false;
        return x;
      });
    }
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {}
}
