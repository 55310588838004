<div class="cancel-rating">
  <h1
    class="text-coface-primary font-bold text-center w-full left-0 p-3 pt-7 text-2xl mb-2"
  >
    {{
      "¿Está seguro que ya no desea continuar con el proceso de calificación?"
        | translate
    }}
  </h1>

  <div class="px-3 border-gray-200 border-t">
    <div class="mt-6 mb-3 text-center">
      <button
        class="btn__passModal w-full text-white bg-coface-primary mb-1"
        (click)="cancelRating()"
      >
        {{ "Si, estoy seguro" | translate }}
      </button>
      <button
        class="btn__passModal w-full text-white bgNoAccept mt-1"
        (click)="closeModal()"
      >
        {{ "Regresar" | translate }}
      </button>
    </div>
  </div>
</div>
