import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthHttp } from './http/auth.http';
import { AuthOperation } from './operations/auth.operations';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	exports: [],
	providers: [AuthHttp, AuthOperation],
})
export class AuthCommonModule { }