import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON_LIST } from './icon-list';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'coface-button-circle',

  templateUrl: './button-circle.component.html',
  styleUrl: './button-circle.component.scss',
})
export class ButtonCircleComponent {
  @Input() public text: string = 'DEFAULT';
  @Input() public color: 'primary' | 'secondary' | 'tertiary' | 'light' =
    'primary';
  @Input() public disabled: boolean;
  @Input() public iconName: string = 'SEARCH';
  @Input() public loading: boolean;
  @Input() public circlePosition: 'left' | 'right' = 'right';

  @Output() public clicked = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  public getButtonColor(): string {
    switch (this.color) {
      case 'primary':
        return 'bg-coface-primary text-white';
      case 'secondary':
        return 'bg-coface-secondary text-white';
      case 'tertiary':
        return 'bg-coface-tertiary-bold text-white';
      case 'light':
        return 'bg-gray-100 text-coface-primary';
    }
  }

  public getButtonColorLight(): string {
    switch (this.color) {
      case 'primary':
        return 'bg-coface-primary-light';
      case 'secondary':
        return 'bg-coface-secondary-light';
      case 'tertiary':
        return 'bg-coface-tertiary-light';
      case 'light':
        return 'bg-gray-100';
    }
  }

  public getIconSVGByName(): SafeHtml | undefined {
    const findSvg = ICON_LIST.find((x) => x.name === this.iconName)?.svg;
    if (findSvg) {
      return this.sanitizer.bypassSecurityTrustHtml(findSvg);
    }
    return undefined;
    // return this.sanitizer.bypassSecurityTrustHtml(findSvg!);
  }
}
