import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IResponseCoface,
  IResponseCofaceInfo,
} from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { Paginator } from '../../../commons/model/paginator.model';
import { GenericRequestConfig } from '../../../commons/model/request.config';
import { SessionService } from '../../../commons/services/session.service';
import { IListUser } from '../../user-management/commons/interface/list-user.interface';
import { IAvailableDays, IDisableUpload } from './disableUpload.interface';
import {
  IBodyHomologationRejected,
  IDataHomologation,
  IDescriptionWelcomeModal,
  IEditInformationSupplier,
  IHomologation,
  IHomologationRejected,
  IHomologationRequest,
  IInfoDescription,
  IObservations,
  IPaymentInfo,
  IPriceRate,
  IQualificationInformation,
  IReport,
  IViewReport,
} from './homologacion-interface';

@Injectable({ providedIn: 'root' })
export class HomologacionEndpoint {
  public constructor(
    private http: HttpClient,
    private session: SessionService
  ) {}

  public requestRecalification$(body: IHomologationRequest, idHomologation) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-retry/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  // get homologation information
  public homologationInformation$(idHomologation: number) {
    const params = new HttpParams().set('id', idHomologation);
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-info/`;
    return this.http.get<IResponseCoface<IHomologation>>(url, {
      params: params,
    });
  }

  /**
   * Send comment because homologation rejected
   * @param body the reason why the homologation was rejected
   * @returns I believe this is a void
   */
  public commentHomologationRejected$(
    body: IBodyHomologationRejected,
    reviewId: number
  ) {
    const url = `${environment.urlBaseEndpoint}/reviews/${reviewId}/decline`; //new
    return this.http.post<IHomologationRejected>(url, body);
  }

  // homologation payed
  public homologationPayed$(
    body: IHomologationRequest,
    idHomologation: number
  ) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-payed/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  // homologation complete
  public homologationComplete$(
    body: IHomologationRequest,
    idHomologation: number
  ) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-complete/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  public downloadFile$(idFile) {
    const headers = new HttpHeaders({ Accept: 'application/octet-stream' });
    const url = `${environment.urlBaseEndpoint}/attachments/download/${idFile}`;

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

  // to send observations
  public toSendObservations$(body: IObservations, idHomologation: number) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-observation/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  // to send analyst from manager
  public toSendAnalystFromManager$(
    idHomologation: number,
    iconReportType: string
  ) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-manager-approved/${idHomologation}`;
    // dev.supplierportal.warender.me/api/v181'
    return this.http.post<IResponseCoface<IDataHomologation>>(url, {
      iconReportType
    });
  }

  // delete attachment
  public deleteAttachment$(idFile: string) {
    const url = `${environment.urlBaseEndpoint}/attachments/delete/${idFile}`;
    return this.http.delete<IResponseCoface<IQualificationInformation>>(url);
  }

  // change status to report
  public reportsInPreparation$(body: IReport, idHomologation: number) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-report/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  //homologation correction
  public homologationCorrections$(body, idHomologation: number) {
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-corrections/${idHomologation}`;
    return this.http.post<IResponseCoface<IDataHomologation>>(url, body);
  }

  // verify payment
  public homologationVerifiyPayment$(idHomologation: number) {
    const params = new HttpParams().set('homologation', idHomologation);
    const url = `${environment.urlBaseEndpoint}/account/payment-details`;
    return this.http.get<IResponseCoface<IPaymentInfo>>(url, {
      params: params,
    });
  }

  //list homologation supplier
  public homologationSupplierList$(request: GenericRequestConfig) {
    // const url = `${environment.urlBaseEndpoint}/supplier/homologation-list/`;
    return this.http.get<IResponseCoface<Paginator<IViewReport>>>(
      request.url,
      request
    );
  }

  // get name account manager of my customer
  public getNameManagerOfMyCustomer$(idHomologation) {
    const params = new HttpParams()
      .set('thisIsTheHomoId', idHomologation)
      .set('insertCustomerRoleIdHere', 5);
    const url = `${environment.urlBaseEndpoint}/homologation/endpoint/get-the-account-manager-of-my-custoer`;
    return this.http.get<IResponseCoface<IListUser>>(url, { params: params });
  }

  //get name and custom letter
  public getNamePresentationLetter$(idHomologation: number) {
    const url = `${environment.urlBaseEndpoint}/homologation/${idHomologation}/members/only-customer`;
    return this.http.get<IResponseCoface<IDescriptionWelcomeModal>>(url);
  }

  //disable attachments
  public disableUploadHomologation$(idHomologation: number) {
    const body = {};
    const url = `${environment.urlBaseEndpoint}/homologation/disable-homo-uploads/${idHomologation}`;
    return this.http.patch<IDisableUpload>(url, body);
  }

  //unable attachments
  public unlockFileHomologation$(idHomologation: number) {
    const body = {};
    const params = new HttpParams().set('homologationId', idHomologation);
    const url = `${environment.urlBaseEndpoint}/homologation/unlock-file-management`;
    return this.http.patch<IDisableUpload>(url, body, { params: params });
  }

  //verify disable
  public homologationEntity$(idHomologation: number) {
    const params = new HttpParams().set('id', idHomologation);
    const url = `${environment.urlBaseEndpoint}/homologation/homologation-entity/`;
    return this.http.get<IDisableUpload>(url, { params: params });
  }

  //disable payment
  public disableIsPayedHomologation$(idHomologation: number) {
    const body = {};
    const params = new HttpParams().set('homologationId', idHomologation);
    const url = `${environment.urlBaseEndpoint}/homologation/mark-payment-as-payed`;
    return this.http.patch<IResponseCoface<IDisableUpload>>(url, body, {
      params: params,
    });
  }

  //verified payment
  public paymentVerified$(idHomologation: number) {
    const body = {};
    const params = new HttpParams().set('homologationId', idHomologation);
    const url = `${environment.urlBaseEndpoint}/payments/make-payment-verified`;
    return this.http.patch<IDisableUpload>(url, body, { params: params });
  }

  // active recalification$
  public validRecalification$(idHomologation: number) {
    const body = {};
    const url = `${environment.urlBaseEndpoint}/commons/suppliers/approvals/${idHomologation}/attempts`;
    return this.http.patch<IDisableUpload>(url, body);
  }

  // sent documents$
  public sentDocumentsToProduction$(idHomologation: number) {
    const body = {};
    const url = `${environment.urlBaseEndpoint}/commons/approvals/${idHomologation}/stages`;
    return this.http.patch<IDisableUpload>(url, body);
  }

  // get available days
  public getAvailableDays$(idHomologation: number) {
    const params = new HttpParams().set('homologationId', idHomologation);
    const url = `${environment.urlBaseEndpoint}/homologation/get-restantes-days`;
    return this.http.get(url, { params: params });
  }

  // selectBasicFormId
  public selectBasicFormId$(basicFormId: number) {
    const params = new HttpParams().set('basicFormId', basicFormId);
    const url = `${environment.urlBaseEndpoint}/account/documents/basic-form`;
    return this.http.patch(url, null, { params: params });
  }

  // get price general
  public getPriceRateByCountry$(idCountry: number, customerId: number) {
    const params = new HttpParams()
      .set('countryId', idCountry)
      .set('customerId', customerId);

    const url = `${environment.urlBaseEndpoint}/platform/operations/price-rates`;
    return this.http.get<IResponseCoface<IPriceRate>>(url, { params: params });
  }

  // get information general by user
  public getInfoByUserDetail$(idUser: number) {
    const url = `${environment.urlBaseEndpoint}/users/${idUser}`;
    return this.http.get<IResponseCoface<IInfoDescription>>(url);
  }

  // get by participants
  public getInfoByParticipant$(idHomologation: number) {
    const params = new HttpParams().set('homologation', idHomologation);
    const url = `${environment.urlBaseEndpoint}/participant/participant-by-homologation`;
    return this.http.get<any>(url, { params: params });
  }

  public cancelHomologation$(operationId: number) {
    const params = new HttpParams().set('operationId', operationId);
    const url = `${environment.urlBaseEndpoint}/notifications/cancel-me-this`;
    return this.http.post<any>(url, null, { params: params });
  }

  // Imposible de contactar
  public impossibleToContact$(operationId: number, reachable: boolean) {
    const params = new HttpParams().set('reachable', reachable);
    const url = `${environment.urlBaseEndpoint}/operations/${operationId}/contacts/status`;
    return this.http.patch<any>(url, null, { params: params });
  }

  /**
   * Sometimes a supplier need to let other person in charge of their company.
   * In those cases backoffice could edit the user information to trigger a new invitation.
   * This invitation came with new credentials for the new person (email).
   *
   * @param companyId The company id of the supplier
   * @param body Supplier email and phone number
   * @returns response
   */
  public editInformationSupplier$(
    companyId: number,
    body: IEditInformationSupplier
  ) {
    const params = new HttpParams().set('companyId', companyId);
    const url = `${environment.urlBaseEndpoint}/backoffice/suppliers/address`;
    return this.http.patch<any>(url, body, { params: params });
  }

  public termsConditionsUploadDocuments$(
    homologationId: number,
    marketingConsent: boolean
  ) {
    const params = new HttpParams()
      .set('homologationId', homologationId)
      .set('isMarketingConsent', marketingConsent);
    const url = `${environment.urlBaseEndpoint}/homologation/set-processing-to-true`;
    return this.http.get<any>(url, { params: params });
  }
}
