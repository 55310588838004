<div
  id="dropdown-select"
  class="relative w-auto min-w-[220px]"
  [ngClass]="{ 'custom-scrollbar': green }"
  #selectTemplate
>
  <!-- <input class="hide absolute" type="text"> -->
  <div
    [attr.id]="idselect"
    class="wrap-selected outline-1 w-auto h-[44.5px]"
    [ngClass]="{ 'shadow-lg shadow-gray-50-500/40': shadow }"
    (click)="openSelect($event)"
  >
    <p class="whitespace-nowrap overflow-hidden overflow-ellipsi text-gray-400"
      [ngClass]="{ 'text-gray-600': value }"
    >
      {{ ItemSelected }}
    </p>
    <div
      class="text-xs text-coface-primary flex items-center"
      [ngClass]="{ 'cl-secondary-light': green }"
      *ngIf="!showIcon"
    >
      <p>&#9650;</p>
    </div>
    <div
      class="text-xs text-coface-primary flex items-center"
      *ngIf="showIcon"
      [ngClass]="{ 'cl-secondary-light': green }"
    >
      <p>▼</p>
    </div>
  </div>
  <div [attr.id]="idoption" class="options overflow-hidden z-10">
    <ul class="hide overflow-auto">
      <li
        class="p-1 text-sm tracking-wide hover:bg-gray-100 text-gray-500 font-normal"
        *ngFor="let item of items"
        (click)="onSelectValue(item)"
      >
        {{ item.name | translate }}
      </li>
    </ul>
  </div>
</div>
<!-- <input type="text" name="qweqwe" id="qweqwe" value="ayayayo" /> -->
