import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';

@Component({
  selector: 'coface-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent {
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: Router,
    private session: SessionService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.session.destroySession();
    this.ref.close();
    this.route.navigate(['/acceso/login']);
  }
}
