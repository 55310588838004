import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { switchMap } from 'rxjs';

@Component({
  selector: 'coface-requalification-request',
  templateUrl: './requalification-request.component.html',
  styleUrls: ['./requalification-request.component.scss'],
})
export class RequalificationRequestComponent implements OnInit {
  message: string;
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private router: Router,
    private homologationOperation: HomologacionOperation
  ) {}

  ngOnInit(): void {}

  requestRequalification() {
    let homologationId = this.config.data;
    this.homologationOperation
      .unlockFileHomologation(homologationId)
      .pipe()
      .subscribe((_) => {
        this.router
          .navigate(['../coface/homologacion/carga-documentos'], {
            queryParams: {
              homologationId: homologationId,
            },
          })
          .then((_) => {
            this.ref.close();
          });
      });
  }
}
