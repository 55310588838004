import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'systemPipeById',
})
export class SystemPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'REFERENCE_VERIFICATION':
        return 'Verificación de referencia';
      case 'INTEGRAL_EVALUATION':
        return 'Evaluación integral';
      case 'OTHERS':
        return 'Otros';
      case 'NONE':
        return 'Ninguno';
      default:
        return 'Ninguno';
    }
  }
}
