import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coface-important-notes',
  templateUrl: './important-notes.component.html',
  styleUrls: ['./important-notes.component.scss']
})
export class ImportantNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
