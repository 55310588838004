import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormGroup,
  FormGroupName,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'coface-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
})
export class RangeComponent implements OnInit, AfterViewInit {
  @Input() formRangeA: UntypedFormGroup;
  @Input() index: number;
  // @Input() inputFrom: number;
  // @Input() inputUntil: number;
  inputFrom: number;
  inputUntil: number;
  constructor() {}

  ngOnInit(): void {
    // this.formRange.controls
  }

  ngAfterViewInit(): void {
    // this.formRangeA.valueChanges.subscribe(val=>{
    // })
  }
}

export function biddenInputValidator(iuntil) {
  // console.log(iuntil);
}
