import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IInformation } from 'projects/ui-coface/src/app/modules/consulta-formulario/pages/informacion-basica/commons/interface/informacion-basica.interface';
import { InformacionBasicaOperation } from 'projects/ui-coface/src/app/modules/consulta-formulario/pages/informacion-basica/commons/operation/informacion-basica.operation';
import { IDisableUpload } from 'projects/ui-coface/src/app/modules/homologacion/commons/disableUpload.interface';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';

@Component({
  selector: 'coface-edit-information-supplier',
  templateUrl: './edit-information-supplier.component.html',
  styleUrls: ['./edit-information-supplier.component.scss'],
})
export class EditInformationSupplierComponent implements OnInit {
  formEditInformationSupplier: UntypedFormGroup;
  infoSupplier: IInformation;
  idCompany: IDisableUpload;
  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private infoBasica: InformacionBasicaOperation,
    private fb: UntypedFormBuilder,
    private homoOperation: HomologacionOperation,
    private _route: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.infoBasica
      .customerSupplierDetails$(this.dialogConfig.data)
      .subscribe((data) => {
        this.infoSupplier = data;
        // console.log(data);
        this.formEditInformationSupplier.patchValue({
          supplierEmail: data.email,
          supplierPhoneNumber: data.phone,
        });
        this.homoOperation
          .homologationEntity(this.infoSupplier.homologationId!)
          .subscribe((data) => {
            this.idCompany = data;
          });
      });
  }

  initForm() {
    this.formEditInformationSupplier = this.fb.group({
      supplierEmail: ['', Validators.required],
      supplierPhoneNumber: ['', Validators.required],
    });
  }

  editInfoSupplier() {
    const body = {
      supplierEmail:
        this.formEditInformationSupplier.get('supplierEmail')?.value,
      supplierPhoneNumber: this.formEditInformationSupplier.get(
        'supplierPhoneNumber'
      )?.value,
    };
    this.homoOperation
      .editInformationSupplier(this.idCompany?.company.id, body)
      .subscribe((data) => {
        this.dialogRef.close();
        this._route.navigate(['./coface/home']);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
