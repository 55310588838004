import {
  Directive,
  forwardRef,
  HostListener,
  HostBinding,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[cofaceReactiveForm]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CofaceFormDirective),
      multi: true,
    },
  ],
})
export class CofaceFormDirective implements ControlValueAccessor {
  private onChange: (value: any) => void;
  private onTouched: () => void;
  private touched = false;

  @HostBinding('value') public value: any;

  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    //	this.hostDisabled = isDisabled;
  }

  public writeValue(value: any) {
    this.value = value;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();

      this.touched = true;
    }
  }

  @HostListener('valueSelected', ['$event'])
  public changeSelected(val) {
    this.value = val;
    this.markAsTouched();
    this.onChange(this.value);
  }

  @HostListener('searchText', ['$event'])
  public onSearchText(val) {
    this.value = '';
    // this.markAsTouched();
    this.onChange(this.value);
  }

  public constructor() {
    // console.log('INIT DIRECTIVE', this.value);
  }
}
