import { HttpHeaders, HttpParams } from '@angular/common/http';

export class GenericRequestConfig {
  url: string;
  body?: any;
  params?: HttpParams | { [param: string]: string | string[] };
  headers?: HttpHeaders | { [header: string]: string | string[] };
  responseType?: 'json';

  constructor() {}
}
